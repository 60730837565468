import React from 'react';
import PropTypes from 'prop-types';
import DietSettingsConnectorApollo from '@dietlabs/components/src/Hpba/DietSettings/Index/Connector/Apollo/Fetch/DietSettingsConnectorApollo';
import DietSettingsGenerateDietConnectorApollo from '@dietlabs/components/src/Hpba/DietSettings/Index/Connector/Apollo/GenerateDiet/DietSettingsGenerateDietConnectorApollo';
import apiDateFormat from 'components/Helpers/apiDateFormat';
import { withRouter } from 'react-router';
import { createDateObject } from 'utils/dateFormatter';
import ActivitiesConnectorApollo from './Connector/Apollo/FetchActivities/ActivitiesConnectorApollo';
import ActivitiesIndexContainer from './ActivitiesIndexContainer';

class ActivitiesIndexPage extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            location: PropTypes.shape({
                state: PropTypes.shape({
                    fromDietSettings: PropTypes.bool,
                }),
            }).isRequired,
        }).isRequired,
    };

    getTwoWeeksPeriod = () => {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() + 1);
        startDate = apiDateFormat(startDate);

        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 14);
        endDate = apiDateFormat(endDate);

        return { start: startDate, end: endDate };
    };

    render() {
        let period;
        const twoWeeksPeriod = this.getTwoWeeksPeriod();
        if (global.localStorage.getItem('diet-settings')) {
            const localDietSettings = JSON.parse(
                global.localStorage.getItem('diet-settings')
            );

            let startDate = createDateObject(localDietSettings.startDate);
            startDate = apiDateFormat(startDate);

            let endDate = createDateObject(localDietSettings.startDate);
            endDate.setDate(endDate.getDate() - 1);
            endDate.setDate(endDate.getDate() + 14);
            endDate = apiDateFormat(endDate);

            period = {
                start: startDate,
                end: endDate,
            };
        } else {
            period = this.getTwoWeeksPeriod();
        }

        let fromDietSettings = false;

        if (this.props.history.location.state) {
            fromDietSettings = this.props.history.location.state
                .fromDietSettings;
        }

        return (
            <ActivitiesConnectorApollo period={period}>
                {({ ...activitiesData }) => {
                    return (
                        <DietSettingsConnectorApollo>
                            {({ ...dietSettingsData }) => (
                                <DietSettingsGenerateDietConnectorApollo
                                    period={twoWeeksPeriod}
                                >
                                    {({ generateDiet }) => (
                                        <ActivitiesIndexContainer
                                            period={period}
                                            {...dietSettingsData}
                                            {...activitiesData}
                                            {...{ generateDiet }}
                                            loading={
                                                activitiesData.loading ||
                                                dietSettingsData.loading
                                            }
                                            fromDietSettings={fromDietSettings}
                                        />
                                    )}
                                </DietSettingsGenerateDietConnectorApollo>
                            )}
                        </DietSettingsConnectorApollo>
                    );
                }}
            </ActivitiesConnectorApollo>
        );
    }
}

export default withRouter(ActivitiesIndexPage);
