import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Form, Button, Alert } from 'reactstrap';

import { withLocale } from '@dietlabs/components';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';
import { FLASH_MESSAGES } from 'consts';
import { FlashMessageSuccess } from '@dietlabs/components/src/FlashMessage/FlashMessage';

import Layout from 'Layout/Layout';
import InputText from 'components/FormElements/InputTextControlled';

import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

import Loader from 'Layout/Loader';

import { setLocalStorageItem } from './PromoCodeLocalStorage';

const PromoCodePage = ({ t, validatePromoCode, addMessage }) => {
    const [promoCode, setPromoCode] = useState('');
    const [preload, setPreload] = useState(false);
    const [errors, setErrors] = useState(new ValidationErrors());
    const [displayError, setDisplayError] = useState(false);

    const validationRules = {
        promoCode: [new RequiredRule({ translator: t })],
    };

    const validateInput = (field, value) => {
        if (validationRules[field]) {
            setErrors(prevState => ({
                ...prevState,
                details: validateField(field, value, validationRules[field], {
                    errors: prevState,
                }),
            }));
        }
    };

    const handleInputChange = event => {
        setPromoCode(event.target.value);
        validateInput(event.target.name, event.target.value);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            validationRules,
            { promoCode, errors },
            t
        );

        setErrors(prevState => ({
            ...prevState,
            details: frontEndErrors,
        }));

        if (frontEndErrors.length === 0) {
            setPreload(true);
            const isPromoCodeValid = await validatePromoCode(promoCode);

            if (isPromoCodeValid) {
                setLocalStorageItem(promoCode);
                addMessage(
                    new FlashMessageSuccess(
                        t('promo-code/has-been-activated'),
                        FLASH_MESSAGES.PROMO_CODE.SUCCESS
                    )
                );
                setErrors(new ValidationErrors());
                setDisplayError(false);
            } else {
                setDisplayError(true);
            }
            setPreload(false);
        }
    };

    return (
        <Layout page="promo-code">
            <header>
                <Container>
                    <h1 className="d-none d-md-block text-center">
                        {t('promo-code/page-title')}
                    </h1>
                </Container>
            </header>

            <section className="pt-0">
                <Container>
                    <Row>
                        <Col xs="12" sm={{ size: 6, offset: 3 }}>
                            {displayError && (
                                <Alert color="danger">
                                    {t('promo-code/not-exists')}
                                </Alert>
                            )}

                            <Form
                                onSubmit={event => handleSubmit(event)}
                                noValidate
                            >
                                <InputText
                                    label={t('promo-code/input-label')}
                                    name="promoCode"
                                    errors={errors}
                                    handleChange={handleInputChange}
                                    value={promoCode}
                                    autoComplete="off"
                                />
                                <div className="text-center">
                                    <Button type="submit" color="primary">
                                        {t('promo-code/submit')}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
            {preload ? <Loader /> : ''}
        </Layout>
    );
};

PromoCodePage.propTypes = {
    t: PropTypes.func.isRequired,
    validatePromoCode: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
};

export default withFlashMessage(withLocale(PromoCodePage));
