import React, { Component, Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';

import PropTypes from 'prop-types';

import editIcon from 'assets/icons/edit.svg';
import removeIcon from 'assets/icons/delete.svg';
import periodicIcon from 'assets/icons/icon-change-blue.svg';

import { PATHS } from 'config/paths';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';

class ActivitiesTimeOfDay extends Component {
    static propTypes = {
        activities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        periodicActivities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        date: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        refreshActivities: PropTypes.func.isRequired,
        period: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }).isRequired,
        timeOfDay: PropTypes.string.isRequired,
        displayActions: PropTypes.bool.isRequired,
        toggleEditActivity: PropTypes.func.isRequired,
        toggleRemoveActivity: PropTypes.func.isRequired,
    };

    removeActivity(id) {
        const newActivities = this.props.activities.filter(el => el.id !== id);
        global.localStorage.setItem(
            'activities',
            JSON.stringify(newActivities)
        );
        this.props.refreshActivities();
    }

    removeAllPeriodicActivities(id) {
        const newActivities = this.props.activities.filter(
            el => !el.periodic || (el.periodic && el.periodic.id !== id)
        );
        global.localStorage.setItem(
            'activities',
            JSON.stringify(newActivities)
        );

        const newPeriodicActivities = this.props.periodicActivities.filter(
            el => el.id !== id
        );

        global.localStorage.setItem(
            'periodicActivities',
            JSON.stringify(newPeriodicActivities)
        );

        this.props.refreshActivities();
    }

    calculateCaloriesAndTime() {
        let caloriesCount = 0;
        let timeCount = 0;
        this.props.activities.map(activity => {
            if (
                activity.date === this.props.date &&
                activity.timeOfDay === this.props.timeOfDay
            ) {
                caloriesCount += activity.burnedCalories;
                timeCount += activity.duration;
            }
            return '';
        });
        if (caloriesCount > 0) {
            return (
                <h3 className="mt-4">
                    {this.props.t(this.props.timeOfDay)}
                    &nbsp;|&nbsp;
                    {timeCount}
                    &nbsp;min. |&nbsp;
                    {caloriesCount}
                    &nbsp;kcal
                </h3>
            );
        }
        return '';
    }

    render() {
        return (
            <Fragment>
                {this.calculateCaloriesAndTime()}
                <ul className="activities-list">
                    {this.props.activities.map(activity => {
                        if (
                            activity.date === this.props.date &&
                            activity.timeOfDay === this.props.timeOfDay
                        ) {
                            return (
                                <li key={activity.id}>
                                    <Row>
                                        <Col xs="12" md="7">
                                            {activity.periodic ? (
                                                <img
                                                    className="periodic-icon mr-2"
                                                    src={periodicIcon}
                                                    alt="periodic"
                                                />
                                            ) : (
                                                ''
                                            )}
                                            {activity.type.fullName}
                                        </Col>

                                        <Col xs="6" md="3">
                                            {activity.duration}
                                            &nbsp;min. |&nbsp;
                                            {activity.burnedCalories}
                                            &nbsp;kcal
                                        </Col>

                                        {this.props.displayActions && (
                                            <Col
                                                xs="6"
                                                md="2"
                                                className="text-right"
                                            >
                                                {activity.periodic ? (
                                                    <Button
                                                        color="link"
                                                        className="activity-icon p-0"
                                                        onClick={() =>
                                                            this.props.toggleEditActivity(
                                                                activity.id
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={editIcon}
                                                            alt="edytuj"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Link
                                                        to={`${
                                                            PATHS.ACTIVITIES_EDIT.split(
                                                                ':'
                                                            )[0]
                                                        }single/${activity.id}`}
                                                        className="activity-icon"
                                                    >
                                                        <img
                                                            src={editIcon}
                                                            alt="edytuj"
                                                        />
                                                    </Link>
                                                )}

                                                {activity.periodic ? (
                                                    <Button
                                                        color="link"
                                                        className="activity-icon p-0"
                                                        onClick={() =>
                                                            this.props.toggleRemoveActivity(
                                                                activity.id,
                                                                activity
                                                                    .periodic.id
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={removeIcon}
                                                            alt="usuń"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <span
                                                        role="button"
                                                        tabIndex={0}
                                                        className="activity-icon"
                                                        onClick={() =>
                                                            this.removeActivity(
                                                                activity.id
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={removeIcon}
                                                            className="ml-2"
                                                            alt="usuń"
                                                        />
                                                    </span>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                </li>
                            );
                        }
                        return '';
                    })}
                </ul>
            </Fragment>
        );
    }
}

export default withLocale(ActivitiesTimeOfDay);
