import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import Workouts from './WorkoutsAccessQuery';
import { createDateObject } from '../../../utils/dateFormatter';

const WorkoutsConnectorApollo = ({ children }) => {
    const {
        loading,
        data: {
            me: { videoWorkout },
        },
    } = useQuery(Workouts, {
        fetchPolicy: 'cache-and-network',
    });

    const result = {
        loading,
        videoWorkoutAccessTo: createDateObject(videoWorkout.accessTo),
    };

    return children(result);
};

WorkoutsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default WorkoutsConnectorApollo;
