import gql from 'graphql-tag';

const mutation = gql`
    mutation SendQuiz(
        $email: String!
        $answers: [QuizQuestionAnswerInputType]!
    ) {
        quiz {
            send(email: $email, answers: $answers) {
                __typename
                ... on BasicMutationSuccess {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;

export default mutation;
