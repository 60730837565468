import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

const QuizResultAnswer = ({ label, checked, userChoose }) => {
    const questionAnswerClasses = ['question-answer'];

    if (checked) {
        questionAnswerClasses.push('right-answer');
    }

    if (userChoose && checked) {
        questionAnswerClasses.push('correct');
    }

    if (userChoose && !checked) {
        questionAnswerClasses.push('incorrect');
    }

    return <div className={questionAnswerClasses.join(' ')}>{label}</div>;
};

QuizResultAnswer.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    userChoose: PropTypes.bool.isRequired,
};

export default withLocale(QuizResultAnswer);
