import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import Layout from 'Layout/Layout';
import { withLocale } from '@dietlabs/components';
import activities from 'assets/images/activities.png';

class ActivitiesIntro extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="">
                <Container>
                    <Row>
                        <Col xs="12" md={{ size: 8, offset: 2 }}>
                            <header>
                                <h1 className="text-center">
                                    {this.props.t('activities/intro/title')}
                                </h1>
                            </header>
                            <section className="text-center pt-0">
                                <img
                                    srcSet={`${activities} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h3>
                                    {this.props.t('activities/intro/heading1')}
                                </h3>
                                <p>
                                    {this.props.t(
                                        'activities/intro/paragraph1'
                                    )}
                                </p>

                                <h3>
                                    {this.props.t('activities/intro/heading2')}
                                </h3>
                                <p>
                                    {this.props.t(
                                        'activities/intro/paragraph2'
                                    )}
                                </p>
                            </section>
                        </Col>
                    </Row>
                </Container>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Button
                            color="primary"
                            tag={Link}
                            to={PATHS.DIET_SETTINGS}
                        >
                            {this.props.t('activities/intro/button')}
                        </Button>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLocale(ActivitiesIntro);
