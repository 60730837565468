import React from 'react';
import Layout from 'Layout/Layout';
import PageContainer from 'Layout/Container/PageContainer';

import { Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router';

import appStore from 'assets/icons/workouts/apple_badge.svg';
import googlePlay from 'assets/icons/workouts/google_badge.svg';
import workouts from 'assets/images/balance/balance.jpg';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from 'consts';

class Balance extends React.PureComponent {
    render() {
        return (
            <Layout page="workouts-balance" data-test="workouts">
                <PageContainer>
                    <Alert color="danger" role="alert" className="mt-4 mb-0">
                        Funkcja Balans dostępna jest wyłącznie w aplikacji Diet
                        &amp; Training by Ann dla telefonów z systemem iOS oraz
                        Android
                    </Alert>
                    <Row>
                        <Col>
                            <h1 className="text-center my-4 mt-md-5 mb-md-3">
                                Złap swój balans
                            </h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md="6">
                            <p className="navy">
                                Ściągnij aplikację na swój telefon – jest
                                dostępna zarówno dla użytkowników iOS, jak i
                                Androida. Dzięki temu odkryjesz Balans, czyli
                                wyjątkowe miejsce w aplikacji Diet &amp;
                                Training by Ann wypełnione nagraniami prosto ze
                                świata przyrody oraz autorską, relaksacyjną
                                muzyką.
                            </p>
                            <p className="navy">
                                W dowolnym momencie dnia poczujesz się tak,
                                jakbyś właśnie spacerowała po lesie, siedziała
                                przy ognisku lub była nad brzegiem morza.
                                Przygotowaliśmy dla Ciebie aż{' '}
                                <strong>20+ wyjątkowych ścieżek!</strong>
                            </p>

                            <Row className="justify-content-center mt-4 d-md-none buttons-wrapper">
                                <Col
                                    xs="12"
                                    className="d-flex justify-content-center"
                                >
                                    <div>
                                        <a
                                            href={APP_STORE_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-inline-block my-3 mt-md-0 mr-3"
                                        >
                                            <img src={appStore} alt="" />
                                        </a>
                                        <a
                                            href={GOOGLE_PLAY_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-inline-block my-3 mt-md-0 ml-3"
                                        >
                                            <img src={googlePlay} alt="" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <img
                                src={workouts}
                                className="img-fluid mt-3 mt-md-4"
                                alt=""
                            />
                            <Row className="justify-content-center mt-4 d-none d-md-flex buttons-wrapper">
                                <Col
                                    xs="6"
                                    md="6"
                                    className="d-flex justify-content-end "
                                >
                                    <a
                                        href={APP_STORE_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="d-inline-block my-3 mt-md-0"
                                    >
                                        <img src={appStore} alt="" />
                                    </a>
                                </Col>
                                <Col xs="6" md="6">
                                    <a
                                        href={GOOGLE_PLAY_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="d-inline-block my-3 mt-md-0"
                                    >
                                        <img src={googlePlay} alt="" />
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PageContainer>
            </Layout>
        );
    }
}
export { Balance };
export default withRouter(Balance);
