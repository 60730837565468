import ShoppingCartDeliveryOptionsFactory from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOptionsFactory';
import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import ShoppingCartIndexSaveOrder from 'view/ShoppingCart/Index/Save/Order/ShoppingCartIndexSaveOrder';
import { getLocalStorageItem } from 'PromoCode/PromoCodeLocalStorage';

export default class ShoppingCartIndexSaveOrderFactory {
    static createFromState(state) {
        const deliveryOptions = ShoppingCartDeliveryOptionsFactory.createFromState(
            state
        );
        const products = ShoppingCartProductsFactory.createFromState(state)
            .products;

        const promoCode = getLocalStorageItem();

        return new ShoppingCartIndexSaveOrder(
            state.shoppingCart.token,
            products,
            state.firstSource,
            state.shoppingCart.saleShippingId,
            deliveryOptions.areShippingDataNeeded
                ? state.shoppingCart.delivery
                : null,
            localStorage.getItem('token') ? null : state.shoppingCart.userData,
            promoCode || ''
        );
    }
}
