import ExpiringLocalStorage from 'ExpiringLocalStorage';

const NAME = 'value';
const PromoCodeLocalStorage = new ExpiringLocalStorage('promoCode');

const setLocalStorageItem = value => {
    const TTS_MS = 30 * 24 * 60 * 60 * 1000; // 30 days
    const expire = Date.now() + TTS_MS;
    PromoCodeLocalStorage.setItem(NAME, value, expire);
};

const getLocalStorageItem = () => {
    const promoCode = PromoCodeLocalStorage.getItem(NAME);
    if (promoCode) {
        return promoCode.value;
    }
    return null;
};

export { setLocalStorageItem, getLocalStorageItem };
