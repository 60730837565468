import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col } from 'reactstrap';
import Layout from 'Layout/Layout';

export class ShoppingCartThankYouPlaceholderComponent extends React.Component {
    render() {
        return (
            <Layout page="thnk-you">
                <section className="hero">
                    <Container>
                        <Row>
                            <Col>
                                <h1>
                                    {this.props.t('thank-you/title1')}
                                    <br />
                                    <strong>
                                        {this.props.t('thank-you/title2')}
                                    </strong>
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <h2 className="placeholder">&nbsp;</h2>
                        <Row>
                            <Col md="6">
                                <ol className="pl-0">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ol>
                            </Col>

                            <Col md="6">
                                <ol className="pl-0">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ol>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

ShoppingCartThankYouPlaceholderComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartThankYouPlaceholderComponent);
