import React from 'react';
import PromoCodeIsValidConnectorApollo from '@dietlabs/components/src/ApolloConnectors/PromoCode/Validate/PromoCodeIsValidConnectorApollo';
import PromoCodeContainer from './PromoCodeContainer';

const PromoCodePage = () => {
    return (
        <PromoCodeIsValidConnectorApollo>
            {({ ...validatePromoCode }) => (
                <PromoCodeContainer {...validatePromoCode} />
            )}
        </PromoCodeIsValidConnectorApollo>
    );
};

export default PromoCodePage;
