import {
    CONTENT_SEND_REQUEST,
    CONTENT_RECEIVE_RESPONSE,
} from './ContentFetchActionType';

export default function(state, action) {
    let result = state;

    switch (action.type) {
        case CONTENT_SEND_REQUEST:
            result = {
                content: {},
            };
            break;
        case CONTENT_RECEIVE_RESPONSE:
            result = {
                content: action.content,
            };
            break;
    }

    return result;
}
