import { useMutation } from '@apollo/client';
import QuizSendMutation from './QuizSendMutation';

const SendQuizConnectorApollo = ({ children }) => {
    const [mutate, { loading }] = useMutation(QuizSendMutation);

    const createSendQuiz = async (email, answers) => {
        // adjust answers to GraphQL mutation
        const graphqlAnswers = [];
        Object.keys(answers).forEach(questionId =>
            graphqlAnswers.push({
                questionId: parseInt(questionId, 10),
                answerId: parseInt(answers[questionId], 10),
            })
        );

        const response = await mutate({
            variables: {
                email,
                answers: graphqlAnswers,
            },
        });

        return response;
    };

    const result = {
        sendQuiz: createSendQuiz,
        loading,
    };

    return children(result);
};

export default SendQuizConnectorApollo;
