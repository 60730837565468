import { combineReducers } from 'redux';

import ContentReducer from 'view/Content/Content/ContentReducer';
import { SALE_SOURCES_NAMESPACE } from './view/SaleSources/SaleSourcesNamespace';
import SaleSourcesReducer from './view/SaleSources/SaleSourcesReducer';
import TRACKING_NAMESPACE from './view/Tracking/TrackingNamespace';
import TrackingReducer from './view/Tracking/TrackingReducer';
import ShoppingCartReducer from './view/ShoppingCart/ShoppingCartReducer';
import SaleProductReducer from './view/Sale/Product/SaleProductReducer';

const rootReducer = combineReducers({
    contentModule: ContentReducer,
    [SALE_SOURCES_NAMESPACE]: SaleSourcesReducer,
    [TRACKING_NAMESPACE]: TrackingReducer,
    shoppingCart: ShoppingCartReducer,
    saleProducts: SaleProductReducer,
});

export default rootReducer;
