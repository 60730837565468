import React from 'react';
import withLoading from 'Layout/withLoading';

import { Container, Row, Col, Button, Progress, Alert } from 'reactstrap';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { createDateObject, formatIsoDate } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import apiDateFormat from 'components/Helpers/apiDateFormat';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';

import { PATH_DAY_PLAN_INDEX } from '@dietlabs/components/src/Hpba/DailyDietPlan/DayPlanPaths';
import Loader from 'Layout/Loader';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { CSSTransition } from 'react-transition-group';
import ActivitiesPlaceholder from './ActivitiesPlaceholder';
import ActivitiesDay from './Index/ActivitiesDay';
import ActivitiesIntro from './Preview/ActivitiesIntro';
import { PATH_ACTIVITIES_INDEX } from './paths';

class ActivitiesIndexContainer extends React.Component {
    state = {
        activities: this.props.activities,
        periodicActivities: [],
        progress: 90,
        redirect: false,
        generateDietError: false,

        showAddActivity: false,
        activeDate: undefined,

        showEditActivity: false,
        activityId: undefined,

        showRemoveActivity: false,
        activityPeriodicId: undefined,
    };

    static propTypes = {
        dietSettings: PropTypes.shape({
            sex: PropTypes.string,
            lastMeasurement: PropTypes.shape(),
            height: PropTypes.shape(),
            birthDate: PropTypes.string,
            diet: PropTypes.shape({
                mode: PropTypes.string,
                id: PropTypes.number,
                activityType: PropTypes.string,
                activityLevel: PropTypes.string,
                triangleOfPower: PropTypes.shape(),
            }),
            goalWeight: PropTypes.shape(),
            name: PropTypes.string,
        }).isRequired,
        generateDiet: PropTypes.func.isRequired,
        activities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        period: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        t: PropTypes.func.isRequired,
        fromDietSettings: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        const localActivities = JSON.parse(
            global.localStorage.getItem('activities')
        );

        if (!localActivities) {
            global.localStorage.setItem(
                'activities',
                JSON.stringify(this.props.activities)
            );
        }

        const localPeriodicActivities = JSON.parse(
            global.localStorage.getItem('periodicActivities')
        );
        if (!localPeriodicActivities) {
            global.localStorage.setItem(
                'periodicActivities',
                JSON.stringify([])
            );
        }

        this.refreshActivities();
    }

    toggleAddActivity(date) {
        if (date) {
            this.setState({
                showAddActivity: true,
                activeDate: date,
            });
        }
    }

    toggleEditActivity(activityId) {
        if (activityId) {
            this.setState({
                showEditActivity: true,
                activityId,
            });
        }
    }

    toggleRemoveActivity(activityId, activityPeriodicId) {
        if (activityId) {
            this.setState({
                showRemoveActivity: true,
                activityId,
                activityPeriodicId,
            });
        }
    }

    removeActivity(id) {
        const newActivities = this.state.activities.filter(el => el.id !== id);
        global.localStorage.setItem(
            'activities',
            JSON.stringify(newActivities)
        );
        this.refreshActivities();
        this.setState({ showRemoveActivity: false });
    }

    removeAllPeriodicActivities(id) {
        const newActivities = this.state.activities.filter(
            el => !el.periodic || (el.periodic && el.periodic.id !== id)
        );
        global.localStorage.setItem(
            'activities',
            JSON.stringify(newActivities)
        );

        const newPeriodicActivities = this.state.periodicActivities.filter(
            el => el.id !== id
        );
        global.localStorage.setItem(
            'periodicActivities',
            JSON.stringify(newPeriodicActivities)
        );

        this.refreshActivities();
        this.setState({ showRemoveActivity: false });
    }

    async handleDietGenerate() {
        this.setState({ preload: true });

        const activitiesArray = this.state.activities.map(activity => {
            const obj = {
                timeOfDay: activity.timeOfDay,
                activityId: activity.type.id,
                duration: activity.duration,
                burnedCalories: activity.burnedCalories,
                date: activity.date,
            };
            return obj;
        });

        const periodicActivitiesArray = this.state.periodicActivities.map(
            activity => {
                const obj = {
                    timeOfDay: activity.timeOfDay,
                    activityId: activity.activityId,
                    duration: activity.duration,
                    burnedCalories: activity.burnedCalories,
                    startingDate: activity.startingDate,
                    rules: activity.rules,
                };
                return obj;
            }
        );

        let request;
        let sex;

        // is set diet settings in local storage - it means that user make diet-settings before
        if (global.localStorage.getItem('diet-settings')) {
            const localDietSettings = JSON.parse(
                global.localStorage.getItem('diet-settings')
            );

            delete localDietSettings.activityLevel;

            request = {
                ...localDietSettings,
                activities: activitiesArray,
                periodicActivities: periodicActivitiesArray,
            };
            sex = localDietSettings.sex;
        } else {
            request = {
                sex: this.props.dietSettings.sex,
                weight: {
                    value: this.props.dietSettings.lastMeasurement.weight[0]
                        .value,
                    unit: this.props.dietSettings.lastMeasurement.weight[0]
                        .unit,
                },
                height: {
                    value: this.props.dietSettings.height.value,
                    unit: this.props.dietSettings.height.unit,
                },
                dateOfBirth: this.props.dietSettings.birthDate,
                dietMode: this.props.dietSettings.diet.mode,
                goalWeight: {
                    value: this.props.dietSettings.goalWeight.value,
                    unit: this.props.dietSettings.goalWeight.unit,
                },
                triangleOfPower: {
                    body: this.props.dietSettings.diet.triangleOfPower.body,
                    mind: this.props.dietSettings.diet.triangleOfPower.mind,
                    libido: this.props.dietSettings.diet.triangleOfPower.libido,
                },
                startDate: formatIsoDate(new Date()),
                dietId: this.props.dietSettings.diet.id,
                userName: this.props.dietSettings.name,
                activityType: this.props.dietSettings.diet.activityType,
                activities: activitiesArray,
                periodicActivities: periodicActivitiesArray,
            };

            sex = this.props.dietSettings.sex;
        }

        try {
            const response = await this.props.generateDiet(request);
            const { code } = response.data.me.dietGenerate;
            if (code === 200) {
                // set sex for trsanslator
                global.localStorage.setItem('sex', sex);
                global.localStorage.removeItem('activities');
                global.localStorage.removeItem('periodicActivities');
                global.localStorage.removeItem('diet-settings');
                this.setState({ redirect: true });
            } else {
                this.setState({ generateDietError: true });
                scrollToTop();
                throw new Error(
                    `Failed to generate diet, got status code ${code}`
                );
            }
        } catch (e) {
            this.setState({ generateDietError: true });
            scrollToTop();
            throw new Error(`Failed to generate diet, got error: ${e}`);
        }
    }

    refreshActivities = () => {
        const localActivities = JSON.parse(
            global.localStorage.getItem('activities')
        );

        const localPeriodicActivities = JSON.parse(
            global.localStorage.getItem('periodicActivities')
        );

        this.setState({
            activities: localActivities,
            periodicActivities: localPeriodicActivities,
        });
    };

    renderDays() {
        const days = [];

        for (let i = 0; i < 14; i += 1) {
            let date = createDateObject(this.props.period.start);
            date.setDate(date.getDate() + i);
            date = apiDateFormat(date);

            days.push(
                <ActivitiesDay
                    activities={this.state.activities}
                    periodicActivities={this.state.periodicActivities}
                    date={date}
                    key={i}
                    refreshActivities={() => this.refreshActivities()}
                    period={this.props.period}
                    toggleAddActivity={activeDate =>
                        this.toggleAddActivity(activeDate)
                    }
                    toggleEditActivity={activityId =>
                        this.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />
            );
        }

        return days;
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={`${PATH_DAY_PLAN_INDEX}/${formatIsoDate(new Date())}`}
                    targetTab="diet"
                />
            );
        }
        const isDietSettings = Boolean(localStorage.getItem('diet-settings'));
        if (
            (this.props.activities.length === 0 &&
                this.props.location.pathname !== PATH_ACTIVITIES_INDEX) ||
            (this.props.activities.length === 0 &&
                this.props.location.pathname === PATH_ACTIVITIES_INDEX &&
                !isDietSettings)
        ) {
            return <ActivitiesIntro />;
        }

        return (
            <Layout page="activities">
                <Container>
                    <header className="header-progress">
                        {this.props.fromDietSettings && (
                            <Progress value={this.state.progress} />
                        )}

                        <h1 className="text-center d-none d-md-block">
                            {this.props.fromDietSettings
                                ? this.props.t('activities/diet-settings')
                                : this.props.t('activities/my-activities')}
                        </h1>
                    </header>
                </Container>

                <section className="pt-0">
                    <Container>
                        {this.state.generateDietError ? (
                            <Alert color="danger">
                                {this.props.t('error/message/generic')}
                            </Alert>
                        ) : (
                            ''
                        )}

                        {this.renderDays()}

                        <Alert color="danger">
                            <p className="pink mb-0">
                                {this.props.t('activities/disclaimer')}
                            </p>
                        </Alert>
                    </Container>
                </section>

                <CSSTransition
                    in={
                        this.state.showAddActivity ||
                        this.state.showEditActivity ||
                        this.state.showRemoveActivity
                    }
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div
                        className="overlay"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                            this.setState({
                                showAddActivity: false,
                                showEditActivity: false,
                                showRemoveActivity: false,
                            })
                        }
                    />
                </CSSTransition>

                <CSSTransition
                    in={this.state.showAddActivity}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="select-insert-date">
                        <p className="mb-0">
                            {this.props.t('activities/new-activity')}
                        </p>
                        <hr className="my-2" />
                        <Button
                            tag={Link}
                            size="sm"
                            to={`${PATHS.ACTIVITIES_ADD.split(':')[0]}single/${
                                this.state.activeDate
                            }`}
                            color="white"
                            className="w-100"
                        >
                            {this.props.t('activities/add-activity-single')}
                        </Button>
                        <hr className="my-2" />
                        <Button
                            tag={Link}
                            size="sm"
                            color="white"
                            className="w-100"
                            to={{
                                pathname: `${
                                    PATHS.ACTIVITIES_ADD.split(':')[0]
                                }periodic/${this.state.activeDate}`,
                                state: {
                                    period: this.props.period,
                                },
                            }}
                        >
                            {this.props.t('activities/add-activity-periodic')}
                        </Button>
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.state.showEditActivity}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="select-insert-date">
                        <p className="mb-0">
                            {this.props.t('activities/edit-activity')}
                        </p>
                        <hr className="my-2" />
                        <Button
                            tag={Link}
                            size="sm"
                            to={`${PATHS.ACTIVITIES_EDIT.split(':')[0]}single/${
                                this.state.activityId
                            }`}
                            color="white"
                            className="w-100"
                        >
                            {this.props.t('activities/edit-single-activity')}
                        </Button>
                        <hr className="my-2" />
                        <Button
                            tag={Link}
                            size="sm"
                            color="white"
                            className="w-100"
                            to={{
                                pathname: `${
                                    PATHS.ACTIVITIES_EDIT.split(':')[0]
                                }periodic/${this.state.activityId}`,
                                state: {
                                    period: this.props.period,
                                },
                            }}
                        >
                            {this.props.t('activities/edit-periodic-activity')}
                        </Button>
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.state.showRemoveActivity}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="select-insert-date">
                        <p className="mb-0">
                            {this.props.t('activities/remove-activity')}
                        </p>
                        <hr className="my-2" />
                        <Button
                            size="sm"
                            onClick={() =>
                                this.removeActivity(this.state.activityId)
                            }
                            color="white"
                            className="w-100"
                        >
                            {this.props.t('activities/remove-single-activity')}
                        </Button>
                        <hr className="my-2" />
                        <Button
                            size="sm"
                            onClick={() =>
                                this.removeAllPeriodicActivities(
                                    this.state.activityPeriodicId
                                )
                            }
                            color="white"
                            className="w-100"
                        >
                            {this.props.t(
                                'activities/remove-periodic-activity'
                            )}
                        </Button>
                    </div>
                </CSSTransition>

                <footer className="sticky-footer">
                    <Container className="text-center">
                        {this.props.location.pathname !==
                        PATH_ACTIVITIES_INDEX ? (
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATH_ACTIVITIES_INDEX}
                            >
                                {this.props.t('activities/edit-activities')}
                            </Button>
                        ) : (
                            <Row>
                                <Col xs="6" className="pr-2 text-right">
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        outline
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    >
                                        {this.props.t('back')}
                                    </Button>
                                </Col>
                                <Col xs="6" className="pl-2 text-left">
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        onClick={() =>
                                            this.handleDietGenerate()
                                        }
                                    >
                                        {this.props.t('activities/start-diet')}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </footer>

                {this.state.preload ? <Loader /> : ''}
            </Layout>
        );
    }
}

export default withLoading(
    withRouter(withLocale(ActivitiesIndexContainer)),
    ActivitiesPlaceholder
);
