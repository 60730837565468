import React from 'react';
import { FormGroup } from 'reactstrap';
import Loader from 'Layout/Loader';

const QuizQuestionPlaceholder = () => {
    return (
        <FormGroup>
            <Loader />
        </FormGroup>
    );
};

export default QuizQuestionPlaceholder;
