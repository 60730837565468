import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import ActivitiesListQuery from './ActivitiesListQuery';

const ActivitiesListConnectorApollo = ({ children }) => {
    const { loading, data } = useQuery(ActivitiesListQuery, {
        variables: {
            systemOfMeasures: 'si',
        },
    });

    const result = {
        loading,
    };

    if (!loading) {
        result.activitiesList = data.activity;
    }

    return children(result);
};

ActivitiesListConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default ActivitiesListConnectorApollo;
