import * as actionTypes from './ContentActionType';
import ContentFetchReducer from './Fetch/ContentFetchReducer';

const initialState = {
    isLoading: false,
    content: {},
};

export default function ContentReducer(state = initialState, action) {
    let result = state;

    switch (action.type) {
        case actionTypes.CONTENT_SEND_REQUEST:
        case actionTypes.CONTENT_RECEIVE_RESPONSE: {
            result = ContentFetchReducer(state, action);
            break;
        }
    }

    return result;
}
