import {
    PATH_QUIZ_INTRO,
    PATH_QUIZ_INDEX,
    PATH_QUIZ_RECEIVE_PRIZE,
    PATH_QUIZ_RESULT,
} from './paths';

import QuizIntroPage from './Intro/QuizIntroPage';
import QuizIndexPage from './Index/QuizIndexPage';
import QuizReceivePrizePage from './ReceivePrize/QuizReceivePrizePage';
import QuizResultPage from './Result/QuizResultPage';

const routes = [
    {
        path: PATH_QUIZ_INTRO,
        component: QuizIntroPage,
        private: false,
        exact: true,
        title: 'route-title/quiz',
        exitPopup: false,
    },
    {
        path: PATH_QUIZ_INDEX,
        component: QuizIndexPage,
        private: false,
        exact: true,
        title: 'route-title/quiz',
        exitPopup: false,
    },
    {
        path: PATH_QUIZ_RECEIVE_PRIZE,
        component: QuizReceivePrizePage,
        private: false,
        exact: true,
        title: 'route-title/quiz',
        exitPopup: false,
    },
    {
        path: PATH_QUIZ_RESULT,
        component: QuizResultPage,
        private: false,
        exact: true,
        title: 'route-title/quiz',
        exitPopup: false,
    },
    {
        path: '/quiz',
        redirectTo: PATH_QUIZ_INTRO,
        exact: true,
    },
    {
        path: '/quiz/completed',
        redirectTo: PATH_QUIZ_INTRO,
        exact: true,
    },
];

export { routes };
