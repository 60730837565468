import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputEmail from 'components/FormElements/InputEmailControlled';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';
import ShoppingCartUserData from 'view/ShoppingCart/UserData/ShoppingCartUserData';
import ValidationErrors from 'view/Validation/ValidationErrors';

import { validateField } from 'view/Validation/validateField';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';
import ExternalProviderContainer from '@dietlabs/components/src/Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_REGISTER } from '@dietlabs/components/src/Auth/Login/ByExternalProvider/LoginReturnUrls';

export class ShoppingCartIndexDeliveryUserDataComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.userData.email,
            acceptConditions: this.props.userData.acceptConditions,
            errors: new ValidationErrors(),
        };

        this.validationRules = {
            email: [
                new RequiredRule({ translator: props.t }),
                new EmailFormatRule({ translator: this.props.t }),
            ],
            acceptConditions: [
                new RequiredRule({
                    translator: props.t,
                    messages: {
                        empty: props.t(
                            'cart/accept-condition/validation/required'
                        ),
                    },
                }),
            ],
        };
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.validationError) !==
            JSON.stringify(this.props.validationError)
        ) {
            this.setState({ errors: this.props.validationError });
        }
    }

    handleAcceptConditions = event => {
        this.setState({ [event.target.name]: event.target.checked }, () => {
            const newUserData = new ShoppingCartUserData(
                this.state.email,
                this.state.acceptConditions
            );
            this.props.setUserData(newUserData);
        });

        this.validateInput(event.target.name, event.target.checked);
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            const newUserData = new ShoppingCartUserData(
                this.state.email,
                this.state.acceptConditions
            );
            this.props.setUserData(newUserData);
        });

        this.validateInput(event.target.name, event.target.value);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    checkAcceptContitions = () => {
        this.validateInput('acceptConditions', this.state.acceptConditions);
        if (this.state.acceptConditions) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <Container>
                <div className="white-box p-md-4">
                    <Row className="justify-content-center">
                        <Col md={{ size: 5 }}>
                            <h2 className="text-center">
                                {this.props.t('cart/register/login')}
                            </h2>
                            <p className="text-center">
                                Podany adres e-mail posłuży Ci również do
                                zalogowania się w&nbsp;aplikacji mobilnej
                            </p>
                            {this.renderEmailField()}
                            <div className="line">
                                <span>{this.props.t('cart/login-or')}</span>
                            </div>
                            <ExternalProviderContainer
                                returnUrl={RETURN_URL_REGISTER}
                                state="cart_register"
                                id="external-provider-2"
                                checkAcceptContitions={
                                    this.checkAcceptContitions
                                }
                            />
                            {this.renderAcceptConditionField()}
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }

    renderEmailField() {
        return (
            <InputEmail
                label={this.props.t('email')}
                name="email"
                value={this.state.email}
                errors={this.state.errors}
                handleChange={this.handleInputChange}
                validationRules={this.validationRules.email}
                data-test="email-input"
            />
        );
    }

    renderAcceptConditionField() {
        return (
            <InputCheckbox
                label={[
                    <span key="agreement1">
                        {this.props.t('agreements/terms-and-conditions/text')}
                    </span>,
                    <a key="agreement2" href="/terms-of-service">
                        {this.props.t('agreements/terms-and-conditions/text2')}
                    </a>,
                    <span key="agreement3">
                        {this.props.t('agreements/terms-and-conditions/text3')}
                    </span>,
                    <a key="agreement4" href="/privacy-policy">
                        {this.props.t('agreements/terms-and-conditions/text4')}
                    </a>,
                    <span key="agreement5">
                        {this.props.t('agreements/terms-and-conditions/text5')}
                    </span>,
                    <sup key="agreement6">*</sup>,
                ]}
                id="acceptConditions2"
                name="acceptConditions"
                value={this.state.acceptConditions}
                handleChange={this.handleAcceptConditions}
                checked={this.state.acceptConditions}
                errors={this.state.errors}
                validationRules={this.validationRules.email}
                data-test="accept-conditions-input"
                className="left-side"
            />
        );
    }
}

ShoppingCartIndexDeliveryUserDataComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userData: PropTypes.instanceOf(ShoppingCartUserData).isRequired,
    setUserData: PropTypes.func.isRequired,
    validationError: PropTypes.instanceOf(ValidationErrors).isRequired,
};

export default withLocale(ShoppingCartIndexDeliveryUserDataComponent);
