import React from 'react';
import SendQuizConnectorApollo from './Connector/SendQuizConnectorApollo';
import QuizReceivePrizeContainer from './QuizReceivePrizeContainer';

const QuizReceivePrizePage = () => {
    return (
        <SendQuizConnectorApollo>
            {data => {
                return <QuizReceivePrizeContainer {...data} />;
            }}
        </SendQuizConnectorApollo>
    );
};

export default QuizReceivePrizePage;
