import PromoCodePage from './PromoCodePage';

const PROMO_CODE = '/kupon';

const routes = [
    {
        path: PROMO_CODE,
        component: PromoCodePage,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
];

export { routes };
