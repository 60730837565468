import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';

import { ReactComponent as IconClose } from 'assets/icons/icon_remove.svg';
import { ReactComponent as IconPlus } from 'assets/icons/icon_plus.svg';
import { ReactComponent as IconMinus } from 'assets/icons/icon_minus.svg';

export class ShoppingCartIndexProductComponent extends React.Component {
    render() {
        return (
            <section className="cart-product">
                <Container>
                    <div className="white-box p-md-4">
                        <h2>{this.props.t('cart/products')}</h2>
                        {this.props.products.map(product => (
                            <Row
                                key={product.id}
                                data-test="product-row"
                                className="product-row"
                            >
                                <Col xs="10" md="11">
                                    <Row className="align-items-center">
                                        <Col sm="6" md="5">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="product-name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.name,
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            xs={{ size: 12, order: 3 }}
                                            sm={{ size: 3, order: 2 }}
                                            className="text-md-cemter"
                                        >
                                            {product.isItPossibleToBuyMoreThanOne ? (
                                                <React.Fragment>
                                                    <Button
                                                        color="link"
                                                        className="p-0 pr-2"
                                                        data-test="button-decrease"
                                                        onClick={() =>
                                                            this.props.decrease(
                                                                product.id
                                                            )
                                                        }
                                                    >
                                                        <IconMinus />
                                                    </Button>

                                                    {product.amount}

                                                    <Button
                                                        color="link"
                                                        className="p-0 pl-2"
                                                        data-test="button-increase"
                                                        onClick={() =>
                                                            this.props.increase(
                                                                product.id
                                                            )
                                                        }
                                                    >
                                                        <IconPlus />
                                                    </Button>
                                                </React.Fragment>
                                            ) : (
                                                ''
                                            )}
                                        </Col>
                                        <Col
                                            xs={{ size: 12, order: 2 }}
                                            sm={{ size: 3, order: 3 }}
                                            className="text-md-center"
                                        >
                                            <span className="d-sm-none">
                                                Cena:{' '}
                                            </span>
                                            {`${product.sumPrice}`}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="2" md="1" className="text-right">
                                    <Button
                                        color="link"
                                        className="p-0"
                                        data-test="button-remove"
                                        onClick={() =>
                                            this.props.remove(product.id)
                                        }
                                    >
                                        <IconClose />
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Container>
            </section>
        );
    }
}

ShoppingCartIndexProductComponent.propTypes = {
    increase: PropTypes.func.isRequired,
    decrease: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
};

export default withLocale(ShoppingCartIndexProductComponent);
