import React, { useContext } from 'react';
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import withLoading from 'Layout/withLoading';
import { withLocale } from '../../../TranslatorContext';
import QuizQuestionComponent from './Components/QuizQuestionComponent';
import QuizQuestionPlaceholder from './Placeholders/QuizQuestionPlaceholder';
import { QuizContext } from './QuestionsProvider';
import { PATH_QUIZ_RECEIVE_PRIZE } from '../paths';

const QuizIndexContainer = props => {
    const { questions } = props;
    const { state, actions } = useContext(QuizContext);

    const renderActionButtons = () => {
        return (
            <Row>
                <Col xs="6" className="text-right pr-2">
                    {state.currentQuestionIndex > 0 ? (
                        <Button
                            color="primary"
                            outline
                            onClick={() => actions.prevQuestion()}
                            data-test="quiz-previous-question-button"
                            className="w-100"
                        >
                            {props.t('back')}
                        </Button>
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="6" className="pl-2">
                    {state.currentQuestionIndex + 1 ===
                    state.numberOfQuestions ? (
                        <Button
                            color="primary"
                            tag={Link}
                            disabled={!state.isNextBtnActive}
                            to={PATH_QUIZ_RECEIVE_PRIZE}
                            data-test="quiz-receivePrize-button"
                            className="w-100"
                        >
                            <span className="d-none d-md-inline">
                                {props.t('quiz/receive-prize')}
                            </span>
                            <span className="d-md-none">
                                {props.t('quiz/receive-prize/short')}
                            </span>
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={!state.isNextBtnActive}
                            onClick={() => actions.nextQuestion()}
                            className="w-100"
                        >
                            {props.t('next')}
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };
    const renderForm = () => {
        return (
            <React.Fragment>
                <QuizQuestionComponent
                    id={questions[state.currentQuestionIndex].id}
                    text={questions[state.currentQuestionIndex].text}
                    answers={questions[state.currentQuestionIndex].answers}
                />

                {renderActionButtons()}
            </React.Fragment>
        );
    };

    return (
        <Layout page="quiz">
            <header className="text-center">
                <h1 className="d-none d-md-block">{props.t('quiz/title')}</h1>
                <Container>
                    <Col xs={12} sm={{ size: 6, offset: 3 }}>
                        <Progress
                            value={
                                (state.currentQuestionIndex + 1) *
                                state.numberOfQuestions
                            }
                        />
                    </Col>
                </Container>
            </header>
            <section className="pt-0">
                <Container>
                    <Row>
                        <Col xs={12} sm={{ size: 6, offset: 3 }}>
                            {renderForm()}
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
};

QuizIndexContainer.propTypes = {
    t: PropTypes.func.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withLoading(
    withLocale(QuizIndexContainer),
    QuizQuestionPlaceholder
);
