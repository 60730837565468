import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import PromoCodeIsValidQuery from './PromoCodeIsValidQuery';

const PromoCodeValidateConnectorApollo = ({ children }) => {
    const [validate] = useLazyQuery(PromoCodeIsValidQuery);

    const fetch = async request => {
        const result = await validate({
            variables: {
                promoCode: request,
            },
            fetchPolicy: 'no-cache',
        });

        const isPromoCodeValid = result.data.sale.isPromoCodeValid;

        return isPromoCodeValid;
    };

    return children({
        validatePromoCode: request => fetch(request),
    });
};

PromoCodeValidateConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default PromoCodeValidateConnectorApollo;
