import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import InputText from 'components/FormElements/InputTextControlled';
import Select from 'components/FormElements/InputSelectControlled';
import ShoppingCartDeliveryOption from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOption';
import ShoppingCartDelivery from 'view/ShoppingCart/Delivery/ShoppingCartDelivery';
import ValidationErrors from 'view/Validation/ValidationErrors';

import { validateField } from 'view/Validation/validateField';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';

export class ShoppingCartIndexDeliveryDataComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.delivery.name,
            address: this.props.delivery.address,
            postalCode: this.props.delivery.postalCode,
            city: this.props.delivery.city,
            phone: this.props.delivery.phone,
            country: this.props.delivery.country,

            errors: new ValidationErrors(),
        };

        this.validationRules = {
            name: [new RequiredRule({ translator: props.t })],
            address: [new RequiredRule({ translator: props.t })],
            postalCode: [new RequiredRule({ translator: props.t })],
            city: [new RequiredRule({ translator: props.t })],
            phone: [new RequiredRule({ translator: props.t })],
            country: [new RequiredRule({ translator: props.t })],
        };
    }

    componentDidMount() {
        this.hanleCountries();
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.validationError) !==
            JSON.stringify(this.props.validationError)
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ errors: this.props.validationError });
        }
    }

    handleInputChange = event => {
        if (event.target.name === 'country') {
            this.changeCountry(event.target.value);
        } else {
            this.setState({ [event.target.name]: event.target.value }, () => {
                const newDelivery = new ShoppingCartDelivery(
                    this.state.name,
                    this.state.address,
                    this.state.postalCode,
                    this.state.city,
                    this.state.country,
                    this.state.phone
                );

                this.props.setDeliveryData(newDelivery);
            });
        }

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    hanleCountries() {
        if (
            !this.state.country.code ||
            !this.props.chosenDeliveryOption.countries.filter(
                country => country.code === this.state.country.code
            ).length
        ) {
            this.selectDefaultCountry();
        }
    }

    selectDefaultCountry() {
        const firstAvailableCountry = this.props.chosenDeliveryOption
            .countries[0];
        this.change({ country: firstAvailableCountry });
    }

    change(deliveryFragment) {
        this.setState(deliveryFragment, () => {
            const newDelivery = new ShoppingCartDelivery(
                this.state.name,
                this.state.address,
                this.state.postalCode,
                this.state.city,
                this.state.country,
                this.state.phone
            );

            this.props.setDeliveryData(newDelivery);
        });
    }

    changeCountry(countryCode) {
        const country = this.props.chosenDeliveryOption.countries.filter(
            c => c.code === countryCode
        )[0];
        this.change({ country });
    }

    render() {
        return (
            <React.Fragment>
                <h2>{this.props.t('cart/delivery/heading')}</h2>
                {this.renderName()}
                {this.renderStreet()}
                <Row>
                    <Col md="6">{this.renderPostalCode()}</Col>
                    <Col md="6">{this.renderCity()}</Col>
                </Row>
                <Row>
                    <Col md="6">{this.renderPhone()}</Col>
                    <Col md="6">{this.renderCountry()}</Col>
                </Row>
            </React.Fragment>
        );
    }

    renderName() {
        return (
            <InputText
                label={this.props.t('cart/delivery/name')}
                name="name"
                value={this.state.name}
                errors={this.state.errors}
                handleChange={this.handleInputChange}
                validationRules={this.validationRules.name}
                data-test="name-input"
            />
        );
    }

    renderStreet() {
        return (
            <InputText
                label={this.props.t('cart/delivery/street')}
                name="address"
                value={this.state.address}
                errors={this.state.errors}
                handleChange={this.handleInputChange}
                validationRules={this.validationRules.address}
                data-test="address-input"
            />
        );
    }

    renderPostalCode() {
        return (
            <InputText
                label={this.props.t('cart/delivery/postal-code')}
                name="postalCode"
                value={this.state.postalCode}
                errors={this.state.errors}
                handleChange={this.handleInputChange}
                validationRules={this.validationRules.postalCode}
                data-test="postal-code-input"
            />
        );
    }

    renderCity() {
        return (
            <InputText
                label={this.props.t('cart/delivery/city')}
                name="city"
                value={this.state.city}
                errors={this.state.errors}
                handleChange={this.handleInputChange}
                validationRules={this.validationRules.city}
                data-test="city-input"
            />
        );
    }

    renderPhone() {
        return (
            <InputText
                label={this.props.t('cart/delivery/phone')}
                name="phone"
                value={this.state.phone}
                errors={this.state.errors}
                handleChange={this.handleInputChange}
                validationRules={this.validationRules.phone}
                data-test="phone-input"
            />
        );
    }

    renderCountry() {
        return (
            <Select
                label={this.props.t('cart/delivery/country')}
                name="country"
                handleChange={this.handleInputChange}
                value={this.state.country.code}
                data-test="country-input"
                validationRules={this.validationRules.country}
            >
                {this.props.chosenDeliveryOption
                    ? this.props.chosenDeliveryOption.countries.map(country => (
                          <option key={country.code} value={country.code}>
                              {country.name}
                          </option>
                      ))
                    : ''}
            </Select>
        );
    }
}

ShoppingCartIndexDeliveryDataComponent.propTypes = {
    t: PropTypes.func.isRequired,
    setDeliveryData: PropTypes.func.isRequired,
    delivery: PropTypes.instanceOf(ShoppingCartDelivery).isRequired,
    validationError: PropTypes.instanceOf(ValidationErrors).isRequired,
    chosenDeliveryOption: PropTypes.instanceOf(ShoppingCartDeliveryOption)
        .isRequired,
};

export default withLocale(ShoppingCartIndexDeliveryDataComponent);
