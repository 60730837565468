import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

class Header extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        renderAside: PropTypes.func,
        mobileHide: PropTypes.bool,
    };

    static defaultProps = {
        renderAside: null,
        mobileHide: true,
    };

    render() {
        const { children, renderAside, mobileHide = true } = this.props;
        return (
            <header>
                <Container>
                    <Row className="text-center">
                        <Col
                            className={
                                mobileHide ? 'd-none d-md-block' : 'd-block'
                            }
                            xs={12}
                            sm={{ size: 6, offset: 3 }}
                        >
                            <h1>{children}</h1>
                        </Col>
                        {!renderAside ? null : (
                            <Col xs={12} sm={3}>
                                {renderAside()}
                            </Col>
                        )}
                    </Row>
                </Container>
            </header>
        );
    }
}

export default Header;
