import { useQuery } from '@apollo/client';
import FetchQuestionsQuery from './FetchQuestionsQuery';

const FetchQuestionsConnectorApolo = ({ children }) => {
    const { loading, data } = useQuery(FetchQuestionsQuery);

    const result = {
        loading,
    };

    if (!loading && data.quiz.__typename === 'Quiz') {
        result.questions = data.quiz.questions;
        result.questionsCount = data.quiz.questions.length;
    }

    return children(result);
};

export default FetchQuestionsConnectorApolo;
