import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Label,
    FormGroup,
    Button,
    UncontrolledCollapse,
} from 'reactstrap';

import withLoading from 'Layout/withLoading';
import { createDateObject } from 'utils/dateFormatter';

import InputNumber from 'components/FormElements/InputNumberControlled';
import InputRadio from 'components/FormElements/InputRadioControlled';
import InputSwitch from 'components/FormElements/InputSwitch';
import Select from 'components/FormElements/InputSelectControlled';

import { PATHS } from 'config/paths';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

import calculateEnergyEffort from 'utils/calculateEnergyEffort';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

import Layout from 'Layout/Layout';
import apiDateFormat from 'components/Helpers/apiDateFormat';
import ActivitiesPlaceholder from './ActivitiesPlaceholder';

class ActivityAddEditContainer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activitiesList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                duration: PropTypes.number,
                timeOfDay: PropTypes.oneOf([
                    'before_breakfast',
                    'after_breakfast',
                    'before_dinner',
                    'after_dinner',
                    'before_supper',
                    'after_supper',
                ]),
                type: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    fullName: PropTypes.string,
                }),
                date: PropTypes.string,
                periodic: PropTypes.shape(),
            })
        ).isRequired,
        userWeightInKg: PropTypes.number.isRequired,
        date: PropTypes.string,
        activity: PropTypes.shape({
            id: PropTypes.number.isRequired,
            duration: PropTypes.number,
            burnedCalories: PropTypes.number,
            timeOfDay: PropTypes.string,
            type: PropTypes.shape({
                id: PropTypes.number,
                fullName: PropTypes.string,
            }),
            date: PropTypes.string,
            periodic: PropTypes.shape({
                id: PropTypes.number,
                startingFrom: PropTypes.string,
                rules: PropTypes.shape({
                    weekly: PropTypes.shape({
                        every_monday: PropTypes.bool,
                        every_tuesday: PropTypes.bool,
                        every_wednesday: PropTypes.bool,
                        every_thursday: PropTypes.bool,
                        every_friday: PropTypes.bool,
                        every_saturday: PropTypes.bool,
                        every_sunday: PropTypes.bool,
                    }),
                }),
            }),
        }),
        edit: PropTypes.bool,
        isPeriodic: PropTypes.bool.isRequired,
        period: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        activity: null,
        edit: false,
        date: undefined,
    };

    selectActivityBranch = () => {
        let activityBranch = '';

        if (this.props.activity) {
            activityBranch = this.props.activitiesList.filter(el => {
                if (el.__typename === 'ActivityBranch') {
                    let childrenArray = el.children;
                    childrenArray = childrenArray.filter(
                        childEl =>
                            childEl.id ===
                            parseInt(this.props.activity.type.id, 10)
                    );
                    if (childrenArray.length > 0) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (activityBranch.length > 0) {
                activityBranch = activityBranch[0].id;
            } else {
                activityBranch = '';
            }
        }

        return activityBranch;
    };

    calculateKcal = () => {
        let kcal = '';
        if (this.props.activity) {
            let result = this.props.activitiesList.filter(
                el => el.id === parseInt(this.props.activity.type.id, 10)
            );
            if (Object.keys(result).length === 0) {
                result = this.props.activitiesList.filter(
                    el => el.id === parseInt(this.selectActivityBranch(), 10)
                );
                result = result[0].children.filter(
                    el => el.id === parseInt(this.props.activity.type.id, 10)
                );
            }

            kcal = result[0].caloriesBurnedPerHour;
        }

        return kcal;
    };

    everyDay = day => {
        if (this.props.edit) {
            if (this.props.activity && this.props.activity.periodic) {
                switch (day) {
                    case 1:
                        return this.props.activity.periodic.rules.weekly
                            .every_monday;
                    case 2:
                        return this.props.activity.periodic.rules.weekly
                            .every_tuesday;
                    case 3:
                        return this.props.activity.periodic.rules.weekly
                            .every_wednesday;
                    case 4:
                        return this.props.activity.periodic.rules.weekly
                            .every_thursday;
                    case 5:
                        return this.props.activity.periodic.rules.weekly
                            .every_friday;
                    case 6:
                        return this.props.activity.periodic.rules.weekly
                            .every_saturday;
                    case 0:
                        return this.props.activity.periodic.rules.weekly
                            .every_sunday;
                    default:
                        return this.props.activity.periodic.rules.weekly
                            .every_monday;
                }
            }
            return false;
        }

        switch (day) {
            case 1:
                return createDateObject(this.props.date).getDay() === 1;
            case 2:
                return createDateObject(this.props.date).getDay() === 2;
            case 3:
                return createDateObject(this.props.date).getDay() === 3;
            case 4:
                return createDateObject(this.props.date).getDay() === 4;
            case 5:
                return createDateObject(this.props.date).getDay() === 5;
            case 6:
                return createDateObject(this.props.date).getDay() === 6;
            case 0:
                return createDateObject(this.props.date).getDay() === 0;
            default:
                return createDateObject(this.props.date).getDay() === 1;
        }
    };

    state = {
        duration: this.props.activity ? this.props.activity.duration : '',
        kcal: this.calculateKcal(),
        burnedCalories: this.props.activity
            ? this.props.activity.burnedCalories
            : '',
        timeOfDay: this.props.activity
            ? this.props.activity.timeOfDay
            : 'before_breakfast',
        activity: this.props.activity ? this.props.activity.type.id : '',
        activityBranch: this.selectActivityBranch(),
        activityName: this.props.activity
            ? this.props.activity.type.fullName
            : '',
        everyMonday: this.everyDay(1),
        everyTuesday: this.everyDay(2),
        everyWednesday: this.everyDay(3),
        everyThursday: this.everyDay(4),
        everyFriday: this.everyDay(5),
        everySaturday: this.everyDay(6),
        everySunday: this.everyDay(0),
        errors: new ValidationErrors(),
        redirect: false,
    };

    validationRules = {
        timeOfDay: [new RequiredRule({ translator: this.props.t })],
        activity: [new RequiredRule({ translator: this.props.t })],
        duration: [new RequiredRule({ translator: this.props.t })],
        burnedCalories: [new RequiredRule({ translator: this.props.t })],
    };

    processSimpleActivity = () => {
        const request = {
            id: new Date().valueOf(),
            duration: parseInt(this.state.duration, 10),
            timeOfDay: this.state.timeOfDay,
            burnedCalories: parseInt(this.state.burnedCalories, 10),
            type: {
                id: parseInt(this.state.activity, 10),
                fullName: this.state.activityName,
            },
            date: this.props.activity
                ? this.props.activity.date
                : this.props.date,
            periodic: null,
        };

        let activities;
        const localActivities = JSON.parse(
            global.localStorage.getItem('activities')
        );

        if (this.props.edit) {
            // edit activity
            // first remove edited activity, then add new activity
            const tempActivities = localActivities.filter(
                el => el.id !== this.props.activity.id
            );
            activities = [...tempActivities, request];
        } else {
            // add new activity
            activities = [...localActivities, request];
        }

        global.localStorage.setItem('activities', JSON.stringify(activities));
    };

    createPeriodicActivitiesArray(periodic) {
        const obj = {
            id: periodic.id,
            timeOfDay: periodic.timeOfDay,
            activityId: periodic.type.id,
            duration: periodic.duration,
            burnedCalories: periodic.burnedCalories,
            startingDate: periodic.startingFrom,
            rules: {
                weekly: {
                    every_monday: periodic.rules.weekly.every_monday,
                    every_tuesday: periodic.rules.weekly.every_tuesday,
                    every_wednesday: periodic.rules.weekly.every_wednesday,
                    every_thursday: periodic.rules.weekly.every_thursday,
                    every_friday: periodic.rules.weekly.every_friday,
                    every_saturday: periodic.rules.weekly.every_saturday,
                    every_sunday: periodic.rules.weekly.every_sunday,
                },
            },
        };

        let periodicActivities;
        const localPeriodicActivities = JSON.parse(
            global.localStorage.getItem('periodicActivities')
        );

        if (this.props.edit) {
            // edit activity
            // first remove edited activity, then add new activity
            const tempPeriodicActivities = localPeriodicActivities.filter(
                el => el.id !== this.props.activity.id
            );

            periodicActivities = [...tempPeriodicActivities, obj];
        } else {
            // add new activity
            periodicActivities = [...localPeriodicActivities, obj];
        }

        global.localStorage.setItem(
            'periodicActivities',
            JSON.stringify(periodicActivities)
        );
    }

    processPeriodicActivity = () => {
        const periodic = {
            id: new Date().valueOf(),
            duration: parseInt(this.state.duration, 10),
            timeOfDay: this.state.timeOfDay,
            burnedCalories: parseInt(this.state.burnedCalories, 10),
            type: {
                id: parseInt(this.state.activity, 10),
                fullName: this.state.activityName,
            },
            startingFrom: this.props.activity
                ? this.props.activity.periodic.startingFrom
                : this.props.date,
            rules: {
                weekly: {
                    every_monday: this.state.everyMonday,
                    every_tuesday: this.state.everyTuesday,
                    every_wednesday: this.state.everyWednesday,
                    every_thursday: this.state.everyThursday,
                    every_friday: this.state.everyFriday,
                    every_saturday: this.state.everySaturday,
                    every_sunday: this.state.everySunday,
                },
            },
        };

        this.createPeriodicActivitiesArray(periodic);

        let id = new Date().valueOf();

        for (let i = 0; i < 14; i += 1) {
            const date = createDateObject(this.props.period.start);
            date.setDate(date.getDate() + i);

            id += i;

            if (
                (date.getDay() === 1 && this.state.everyMonday) ||
                (date.getDay() === 2 && this.state.everyTuesday) ||
                (date.getDay() === 3 && this.state.everyWednesday) ||
                (date.getDay() === 4 && this.state.everyThursday) ||
                (date.getDay() === 5 && this.state.everyFriday) ||
                (date.getDay() === 6 && this.state.everySaturday) ||
                (date.getDay() === 0 && this.state.everySunday)
            ) {
                const request = {
                    id,
                    duration: parseInt(this.state.duration, 10),
                    timeOfDay: this.state.timeOfDay,
                    burnedCalories: parseInt(this.state.burnedCalories, 10),
                    type: {
                        id: parseInt(this.state.activity, 10),
                        fullName: this.state.activityName,
                    },
                    date: apiDateFormat(date),
                    periodic,
                };

                let activities;
                const localActivities = JSON.parse(
                    global.localStorage.getItem('activities')
                );

                if (this.props.edit) {
                    // edit activity
                    // first remove edited activity, then add new activity
                    const tempActivities = localActivities.filter(
                        el =>
                            !el.periodic ||
                            (el.periodic &&
                                el.periodic.id !==
                                    this.props.activity.periodic.id)
                    );
                    activities = [...tempActivities, request];
                } else {
                    // add new activity
                    activities = [...localActivities, request];
                }

                global.localStorage.setItem(
                    'activities',
                    JSON.stringify(activities)
                );
            }
        }
    };

    handleSubmit() {
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            if (this.props.isPeriodic) {
                this.processPeriodicActivity();
            } else {
                this.processSimpleActivity();
            }

            this.setState({
                errors: new ValidationErrors(),
                redirect: true,
            });
        }
    }

    changeValue(value, name) {
        if (name === 'activity' || name === 'activity-without-branch') {
            let result = this.props.activitiesList.filter(
                el => el.id === parseInt(value, 10)
            );

            if (Object.keys(result).length === 0) {
                result = this.props.activitiesList.filter(
                    el => el.id === parseInt(this.state.activityBranch, 10)
                );
                result = result[0].children.filter(
                    el => el.id === parseInt(value, 10)
                );
            }

            const activityName = result[0].fullName;
            const kcal = result[0].caloriesBurnedPerHour;
            let burnedCalories = calculateEnergyEffort(
                kcal,
                this.props.userWeightInKg,
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.state.duration / 60
            );

            if (burnedCalories === 0) {
                burnedCalories = '';
            }

            this.setState({
                [name]: value,
                activityName,
                kcal,
                burnedCalories,
            });

            if (name === 'activity-without-branch') {
                this.setState({
                    activity: value,
                    activityBranch: '',
                });
            }
        } else if (name === 'duration') {
            let burnedCalories = calculateEnergyEffort(
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.state.kcal,
                this.props.userWeightInKg,
                value / 60
            );

            if (burnedCalories === 0) {
                burnedCalories = '';
            }

            let duration = '';
            if (value) {
                duration = parseInt(value, 10);
            }

            this.setState({
                duration,
                burnedCalories,
            });
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    changeValueActivityBranch(value, name) {
        this.setState({
            [name]: value,
            activity: '',
            activityName: '',
            kcal: '',
            burnedCalories: '',
        });
    }

    handleInputChange = event => {
        if (event.target.name === 'activityBranch') {
            this.changeValueActivityBranch(
                event.target.value,
                event.target.name
            );
        } else {
            this.changeValue(event.target.value, event.target.name);
        }

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    renderActivityList(limit1, limit2) {
        return this.props.activitiesList.map((activity, k) => {
            if (k >= limit1 && k < limit2) {
                if (activity.__typename === 'ActivityBranch') {
                    return (
                        <>
                            <InputRadio
                                label={activity.name}
                                name="activityBranch"
                                value={activity.id}
                                handleChange={this.handleInputChange}
                                checked={
                                    parseInt(this.state.activityBranch, 10) ===
                                    parseInt(activity.id, 10)
                                }
                            />

                            {parseInt(this.state.activityBranch, 10) ===
                            parseInt(activity.id, 10) ? (
                                <>
                                    {activity.children.map(activityChild => (
                                        <InputRadio
                                            key={activityChild.name}
                                            label={activityChild.name}
                                            name="activity"
                                            value={activityChild.id}
                                            handleChange={
                                                this.handleInputChange
                                            }
                                            className="pl-4"
                                            checked={
                                                parseInt(
                                                    this.state.activity,
                                                    10
                                                ) ===
                                                parseInt(activityChild.id, 10)
                                            }
                                        />
                                    ))}
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    );
                }

                return (
                    <InputRadio
                        key={activity.name}
                        label={activity.name}
                        name="activity-without-branch"
                        value={activity.id}
                        handleChange={this.handleInputChange}
                        checked={
                            parseInt(this.state.activity, 10) ===
                            parseInt(activity.id, 10)
                        }
                    />
                );
            }

            return '';
        });
    }

    render() {
        let activityErrorMgs;

        if (this.state.errors) {
            this.state.errors.details.forEach(error => {
                if (error.fieldName === 'activity') {
                    activityErrorMgs = error.messages;
                }
            });
        }

        const optionalDurationProps = {};
        if (this.state.duration) {
            optionalDurationProps.value = Number(this.state.duration);
        }

        const optionalBurnedCaloriesProps = {};
        if (this.state.burnedCalories) {
            optionalBurnedCaloriesProps.value = Number(
                this.state.burnedCalories
            );
        }

        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: PATHS.ACTIVITIES_INDEX,
                    }}
                />
            );
        }

        let pageTitle = this.props.t('activities/add-activity-single');
        if (this.props.isPeriodic) {
            pageTitle = this.props.t('activities/add-activity-periodic');
        } else if (this.props.edit) {
            pageTitle = this.props.t('activities/edit-activity');
        }

        return (
            <Layout page="activities">
                <header>
                    <Container>
                        <Row className="text-center">
                            <Col>
                                <h1 className="d-none d-md-block">
                                    {pageTitle}
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </header>

                <section className="pt-0" ref={this.form}>
                    <Container>
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                <Select
                                    label={this.props.t(
                                        'activities/time-of-day'
                                    )}
                                    name="timeOfDay"
                                    handleChange={this.handleInputChange}
                                    value={this.state.timeOfDay}
                                >
                                    <option value="before_breakfast">
                                        {this.props.t('before_breakfast')}
                                    </option>
                                    <option value="after_breakfast">
                                        {this.props.t('after_breakfast')}
                                    </option>
                                    <option value="before_dinner">
                                        {this.props.t('before_dinner')}
                                    </option>
                                    <option value="after_dinner">
                                        {this.props.t('after_dinner')}
                                    </option>
                                    <option value="before_supper">
                                        {this.props.t('before_supper')}
                                    </option>
                                    <option value="after_supper">
                                        {this.props.t('after_supper')}
                                    </option>
                                </Select>

                                <FormGroup tag="fieldset">
                                    <Label>
                                        {this.props.t(
                                            'activities/type-of-activity'
                                        )}
                                        <sup>*</sup>
                                    </Label>

                                    {this.renderActivityList(0, 4)}

                                    <UncontrolledCollapse toggler="#toggler">
                                        {this.renderActivityList(4, 1000)}
                                    </UncontrolledCollapse>

                                    {activityErrorMgs ? (
                                        <div className="is-invalid invalid-feedback d-block">
                                            <li>{activityErrorMgs}</li>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <br />
                                    <div className="text-center">
                                        <Button
                                            color="primary"
                                            outline
                                            className="w-100"
                                            id="toggler"
                                        >
                                            {this.props.t('activities/more')}
                                        </Button>
                                    </div>
                                </FormGroup>

                                <InputNumber
                                    label={this.props.t(
                                        'activities/activity-time'
                                    )}
                                    name="duration"
                                    unit="min."
                                    errors={this.state.errors}
                                    handleChange={this.handleInputChange}
                                    validationRules={
                                        this.validationRules.duration
                                    }
                                    {...optionalDurationProps}
                                />

                                <InputNumber
                                    label={this.props.t(
                                        'activities/burned-calories'
                                    )}
                                    name="burnedCalories"
                                    unit="kcal"
                                    errors={this.state.errors}
                                    handleChange={this.handleInputChange}
                                    validationRules={
                                        this.validationRules.duration
                                    }
                                    {...optionalBurnedCaloriesProps}
                                />

                                {this.props.isPeriodic && (
                                    <>
                                        <Label>
                                            {this.props.t(
                                                'activities/repeat-every'
                                            )}
                                        </Label>
                                        <InputSwitch
                                            label={this.props.t('monday')}
                                            name="everyMonday"
                                            value={this.state.everyMonday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everyMonday}
                                        />
                                        <InputSwitch
                                            label={this.props.t('tuesday')}
                                            name="everyTuesday"
                                            value={this.state.everyTuesday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everyTuesday}
                                        />
                                        <InputSwitch
                                            label={this.props.t('wednesday')}
                                            name="everyWednesday"
                                            value={this.state.everyWednesday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everyWednesday}
                                        />
                                        <InputSwitch
                                            label={this.props.t('thursday')}
                                            name="everyThursday"
                                            value={this.state.everyThursday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everyThursday}
                                        />
                                        <InputSwitch
                                            label={this.props.t('friday')}
                                            name="everyFriday"
                                            value={this.state.everyFriday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everyFriday}
                                        />
                                        <InputSwitch
                                            label={this.props.t('saturday')}
                                            name="everySaturday"
                                            value={this.state.everySaturday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everySaturday}
                                        />
                                        <InputSwitch
                                            label={this.props.t('sunday')}
                                            name="everySunday"
                                            value={this.state.everySunday}
                                            setValue={(value, name) =>
                                                this.changeValue(value, name)
                                            }
                                            checked={this.state.everySunday}
                                        />
                                        <br />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>

                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Row>
                                    <Col xs="6" className="pr-2">
                                        <Button
                                            color="primary"
                                            outline
                                            className="w-100"
                                            tag={Link}
                                            to={PATHS.ACTIVITIES_INDEX}
                                        >
                                            {this.props.t('cancel')}
                                        </Button>
                                    </Col>
                                    <Col xs="6" className="pl-2">
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            // type="submit"
                                            onClick={() => this.handleSubmit()}
                                        >
                                            {this.props.t('save')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLoading(
    withLocale(ActivityAddEditContainer),
    ActivitiesPlaceholder
);
