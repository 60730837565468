import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, FormGroup } from 'reactstrap';
import Layout from 'Layout/Layout';
import Loader from 'Layout/Loader';
import { withLocale } from '../../../../TranslatorContext';

const QuizResultPlaceholder = ({ t }) => {
    return (
        <Layout page="quiz">
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <header className="text-center">
                            <h1 className="d-none d-md-block">
                                {t('quiz/result/title')}
                            </h1>
                        </header>
                        <section className="pt-0">
                            <div className="text-center mb-5">
                                <h2>{t('quiz/result/your-result')}</h2>
                                <Button color="placeholder">
                                    {t('quiz/result/button')}
                                </Button>
                            </div>

                            <blockquote className="blockquote">
                                <p className="navy">{t('quiz/result/quote')}</p>
                            </blockquote>
                            <p className="blue text-right mb-0">
                                {t('quiz/result/sign')}
                            </p>
                            <hr />
                            <h2 className="text-center mb-3">
                                {t('quiz/result/your-answer')}
                            </h2>
                            <FormGroup>
                                <Loader />
                            </FormGroup>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

QuizResultPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(QuizResultPlaceholder);
