import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import ActivitiesQuery from './ActivitiesQuery';

const ActivitiesConnectorApollo = ({ children, period }) => {
    const { loading, data } = useQuery(ActivitiesQuery, {
        variables: { period },
    });

    let result = {
        loading,
    };

    if (!loading) {
        result = {
            activities: data.me.activities,
        };

        return children(result);
    }
    return children(result);
};

ActivitiesConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    period: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
    }).isRequired,
};

export default ActivitiesConnectorApollo;
