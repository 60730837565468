import ActivitiesIndexPage from './ActivitiesIndexPage';
import ActivityAddPage from './ActivityAddPage';
import ActivityEditPage from './ActivityEditPage';
import * as PATHS from './paths';

const routes = [
    {
        path: PATHS.PATH_ACTIVITIES,
        component: ActivitiesIndexPage,
        private: true,
        exact: true,
        title: 'route-title/activities',
        mobileAppTabIdentifier: 'activities',
    },
    {
        path: PATHS.PATH_ACTIVITIES_INDEX,
        component: ActivitiesIndexPage,
        private: true,
        exact: true,
        title: 'route-title/activities',
        mobileAppTabIdentifier: 'activities',
    },
    {
        path: PATHS.PATH_ACTIVITY_ADD,
        component: ActivityAddPage,
        private: true,
        exact: true,
        title: 'route-title/activities',
        mobileAppTabIdentifier: 'activities',
    },
    {
        path: PATHS.PATH_ACTIVITY_EDIT,
        component: ActivityEditPage,
        private: true,
        exact: true,
        title: 'route-title/activities',
        mobileAppTabIdentifier: 'activities',
    },
];

export { routes };
