import WorkoutsPage from 'Workouts/WorkoutsPage';

const PATH_WORKOUTS = '/workouts';

const routes = [
    {
        path: PATH_WORKOUTS,
        component: WorkoutsPage,
        private: true,
        exact: true,
        title: 'workouts/title',
    },
];

export { PATH_WORKOUTS, routes };
