import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Price from 'view/Price/Price';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';

const SHOW_PRODUCTS_MIN_HEIGHT_EM = 50;

export class ShoppingCartOrderProductsSummaryComponent extends React.Component {
    // eslint-disable-next-line class-methods-use-this
    get defaultShowProducts() {
        if (typeof window.matchMedia !== 'function') {
            return false;
        }

        return window.matchMedia(
            `(min-height: ${SHOW_PRODUCTS_MIN_HEIGHT_EM}em)`
        ).matches;
    }

    renderProductList() {
        return this.props.products.map(product => (
            <Row
                key={product.id}
                data-test="product-row"
                className="summary-product"
            >
                <Col sm="8">
                    <div className="d-flex align-items-center">
                        <div
                            className="product-name"
                            dangerouslySetInnerHTML={{
                                __html: product.name,
                            }}
                        />
                    </div>
                </Col>
                <Col
                    sm="4"
                    className="text-sm-right"
                    data-test="products-price"
                >
                    <span className="d-sm-none">Cena: </span>
                    {`${product.sumPrice}`}
                </Col>
            </Row>
        ));
    }

    render() {
        return (
            <section className="cart-summary h-100">
                <div className="white-box p-md-4 h-100">
                    <h2>{this.props.t('cart/order-summary')}</h2>
                    {this.renderProductList()}
                    <Row
                        data-test="delivery-option-price"
                        className="delivery-option-price"
                    >
                        <Col>{this.props.t('cart/summary/delivery')}</Col>
                        <Col className="price">
                            {this.props.deliveryOptionPrice.toString()}
                        </Col>
                    </Row>
                    <Row data-test="total-price">
                        <Col>
                            <strong>
                                {this.props.t('cart/summary/total')}
                            </strong>
                        </Col>
                        <Col className="price">
                            <strong>{this.props.totalPrice.toString()}</strong>
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
}

ShoppingCartOrderProductsSummaryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
    deliveryOptionPrice: PropTypes.instanceOf(Price).isRequired,
    totalPrice: PropTypes.instanceOf(Price).isRequired,
};

export default withLocale(ShoppingCartOrderProductsSummaryComponent);
