import React from 'react';
// import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';
import appStoreIcon from 'assets/icons/workouts/apple_badge.svg';
import googlePlayIcon from 'assets/icons/workouts/google_badge.svg';
import loginImage from 'assets/images/thankyou/login_img.png';
import loginImage2x from 'assets/images/thankyou/login_img@2x.png';

const ShoppingCartThankYouContentComponent = ({ extraOffset }) => (
    <section className="">
        <Container>
            <Row>
                <Col md={{ size: 8, offset: extraOffset && 2 }}>
                    <h3 className="text-center">Co dalej?</h3>
                    <p className="text-center navy">
                        Jeśli nie masz jeszcze konta w aplikacji, wykonaj
                        następujące kroki:
                    </p>

                    <ol>
                        <li>
                            Dokończ rejestrację swojego konta
                            <p>
                                Wysłaliśmy do Ciebie mail z potwierdzeniem
                                zamówienia oraz{' '}
                                <strong>
                                    linkiem, który pozwala na utworzenie hasła
                                    do Twojego konta w aplikacji.
                                </strong>{' '}
                                Odczytaj wiadomość i dokończ rejestrację. <br />
                                Jeśli nie otrzymałaś wiadomości, sprawdź folder
                                spam.
                            </p>
                        </li>
                        <li>
                            Pobierz aplikację na swój telefon
                            <p>
                                Aplikacja dostępna na urządzenia z systemem iOS
                                oraz Android.
                            </p>
                            <a
                                href={APP_STORE_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mr-3"
                            >
                                <img src={appStoreIcon} alt="App Store" />
                            </a>
                            <a
                                href={GOOGLE_PLAY_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={googlePlayIcon} alt="Google Play" />
                            </a>
                        </li>
                        <li>
                            Zaloguj się w aplikacji
                            <p>
                                Użyj opcji <strong>Zaloguj się</strong> na
                                widoku startowym aplikacji.
                            </p>
                            <img
                                srcSet={`${loginImage} 1x, ${loginImage2x} 2x`}
                                src={loginImage}
                                alt="login"
                            />
                        </li>
                        <li>
                            Korzystaj z aplikacji
                            <p>
                                Pyszna i zdrowa dieta, ponad 100 programów
                                treningowych, podcasty i funkcje balansowe
                                czekają na Ciebie w aplikacji. Do dzieła!
                            </p>
                        </li>
                    </ol>
                </Col>
            </Row>
        </Container>
    </section>
);

ShoppingCartThankYouContentComponent.propTypes = {
    // t: PropTypes.func.isRequired,
    // isDietInPayment: PropTypes.bool.isRequired,
    // isTrainingInPayment: PropTypes.bool.isRequired,
    // isCod: PropTypes.bool.isRequired,
    // containsPhysicalProduct: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouContentComponent);
