export default {
    menu: 'Menu',
    'menu/home': 'Home',
    'menu/diet': 'Dieta',
    'menu/diet-plan': 'Plan diety',
    'menu/trainings': 'Treningi',
    'menu/balance': 'Balans',
    'menu/shop': 'Sklep',
    'menu/knowledge': 'Wiedza',
    'menu/blog': 'Blog',
    'menu/contact': 'Kontakt',

    'menu/activities': 'Aktywności',
    'menu/shopping-list': 'Lista zakupów',
    'menu/measurements': 'Pomiary',
    'menu/diet/settings': 'Ustawienia diety',
    'menu/fav-meals': 'Ulubione posiłki',

    'menu/logout': 'Wyloguj się',
    'menu/login': 'Zaloguj się',
    'menu/settings': 'Ustawienia',
    'menu/account': 'Konto',

    logout: 'Wyloguj',
    save: 'Zapisz',
    cancel: 'Anuluj',
    insert: 'Wstaw',
    delete: 'Usuń',
    next: 'Dalej',
    back: 'Wróć',
    print: 'Drukuj',
    new: 'Nowość',
    'print-header': 'Diet & Training by Ann',
    'print-copyright1':
        'Plan diety przygotowany dla {{email}}. Plan diety chroniony prawami autorskimi.',
    'print-copyright2':
        'Rozpowszechnianie utworu bez zgody tworcy lub osoby posiadajacej prawa autorskie do utworu jest karane na podstawie art. 116 ust. o prawie autorskim i prawach pokrewnych.',
    close: 'Zamknij',
    buy: 'Kup',
    add: 'Dodaj',
    ok: 'OK',
    'data-saved': 'Zapisano',
    'error/message/generic': 'Coś poszło nie tak',
    'error/message/network': 'Wystąpił błąd sieci',
    'error/message/offline': 'Brak połączenia z siecią',
    'error/message/no-account': 'Nie masz jeszcze konta?',
    'error/additional-message/generic':
        'Incydent został zgłoszony, będziemy pracować nad jego rozwiązaniem.',
    'error/additional-message/not-found':
        'Strona o podanym adresie nie istnieje.',
    'error/additional-message/user-not-found':
        'Nie znaleźliśmy twojego konta wśród zarejestrowanych użytkowników.',
    'button/refresh': 'Odśwież',
    'button/try-again': 'Spróbuj ponownie',
    'button/go-back': 'Wróć',
    'button/get-access': 'Uzyskaj dostęp',
    'button/return': 'Powrót',
    'button/send-email': 'Wyślij e-mail',

    week: 'tydzień',
    week_0: 'tydzień',
    week_1: '{{count}} tygodnie',
    week_2: '{{count}} tygodni',
    week_3: '{{count}} tygodnia',

    month: 'miesiąc',
    month_0: 'miesiąc',
    month_1: '{{count}} miesiące',
    month_2: '{{count}} miesięcy',
    month_3: '{{count}} miesiąca',

    year: 'rok',
    year_0: 'rok',
    year_1: '{{count}} lata',
    year_2: '{{count}} lat',
    year_3: '{{count}} roku',

    'diet-intro/title': 'Przeczytaj zanim zaczniesz',
    'diet-intro/start-diet': 'Rozpocznij dietę',
    'diet-intro/quote1':
        'Gratulacje! Świadomie decydując się na zmianę [podjęłaś|podjąłeś] działania, które przybliżą Cię do osiągnięcia Twojego wyznaczonego celu.',
    'diet-intro/quote2':
        'Z naszą pomocą uzyskasz nie tylko wymarzoną sylwetkę, ale i dużo więcej!',
    'diet-intro/quote3':
        'Zdrowa, odpowiednio dobrana dieta pozwoli Ci poczuć się dobrze w swoim ciele  i zachować zdrowie na całe życie. Do dzieła!',
    'diet-intro/sign': 'Anna Lewandowska i jej zespół',

    'diet-type/classic/lvl1': 'Dieta Classic - Poziom 1',
    'diet-type/classic/lvl2': 'Dieta Classic - Poziom 2',
    'diet-type/classic/lvl3': 'Dieta Classic - Poziom 3',

    'diet-type/classic/lvl1/sport': 'Dieta Classic - Poziom 1, SPORT',
    'diet-type/classic/lvl2/sport': 'Dieta Classic - Poziom 2, SPORT',
    'diet-type/classic/lvl3/sport': 'Dieta Classic - Poziom 3, SPORT',

    'diet-type/vege': 'Dieta Vege',
    'diet-type/low-ig': 'Dieta Low IG',
    'diet-type/smart': 'Dieta Smart',

    'meal/preparation-time': 'Czas przygotowania',
    'diet/meal/seasoning/header': 'Przyprawy',
    'diet/meal/optionalIngredients/header': 'Do potrawy możesz dodać również',
    'meal/min': 'min',
    'meal/how-to-prepare': 'Sposób przygotowania',
    'meal/proteins-short': 'B',
    'meal/fat-short': 'T',
    'meal/carbohydrates-short': 'W',
    'meal/proteins': 'Białka',
    'meal/fat': 'Tłuszcze',
    'meal/carbohydrates': 'Węglowodany',
    'meal/weekday0': 'Niedziela',
    'meal/weekday1': 'Poniedziałek',
    'meal/weekday2': 'Wtorek',
    'meal/weekday3': 'Środa',
    'meal/weekday4': 'Czwartek',
    'meal/weekday5': 'Piątek',
    'meal/weekday6': 'Sobota',
    'meal/weekday0-short': 'Nd.',
    'meal/weekday1-short': 'Pn.',
    'meal/weekday2-short': 'Wt.',
    'meal/weekday3-short': 'Śr.',
    'meal/weekday4-short': 'Cz.',
    'meal/weekday5-short': 'Pt.',
    'meal/weekday6-short': 'Sb.',
    'meal/ingredients': 'Składniki',
    'meal/exchange-meal-button': 'Wymień posiłek',
    'meal/exchange-dish-button': 'Wymień',
    'meal/eaten-button': 'Zjedzone',
    'meal/exchange-collapse': 'Zwiń',
    'meal/exchange-day-button': 'Wymień dzień',
    'meal/exchange-ingredient': 'Wymień składnik',
    'meal/exchange-for': 'Możesz wymienić na',
    'meal/original-product': 'Oryginalny produkt',
    'meal/original-meal': 'Oryginalny posiłek',
    'meal/original-day': 'Oryginalny jadłospis',
    'meal/summary-title': 'Podsumowanie',
    'meal/print-button': 'Drukuj',
    'meal/print-period-alert':
        'Wydruk dostępny dla okresu krótszego ni 2 tygodnie.',
    'meal/show-menus-contain': 'Pokaż jadłospisy zawierające:',
    'meal/menus-contain': 'zawiera:',
    'meal/search': 'Szukaj',
    'meal/show-favourite-meals': 'Wyszukaj ulubiony posiłek:',
    'meal/name-of-dish-or-ingredient': 'Nazwa dania lub składnika',
    'meal/there-are-no-replacements': 'Brak odpowiednich zamienników.',
    'meal/supports': 'Wspiera:',
    'next-day-notification': 'Na jutro przygotuj:',
    'meal/copy-day-button': 'Kopiuj na jutro',
    'meal/copy-day-button-done': 'Skopiowane',
    'meal/replacements': 'Zamienniki',
    'holiday-notification': 'Pokaż jadłospis specjalny dla tego dnia',
    'alert/holiday-notification/button': 'Pokaż jadłospis',
    'meal/add-to-dayplan': 'Wstaw do diety',
    'meal/favourite-meals': 'Ulubione posiłki',
    'meal/meal-details': 'Szczegóły posiłku',
    'meal/favourite-meals/no-meals':
        'Dodawaj posiłki do ulubionych, aby mieć je zawsze pod ręką i wygodnie wstawiać do jadłospisu.',
    'meal/favourite-meals/no-search-results':
        'Brak wyników wśród szukanych posiłków',

    'dish/portion/you-should-have-this-dish-last':
        '[Powinnaś|Powinieneś] mieć przygotowaną tą potrawę już wcześniej. Jeśli coś Ci zostało, nie będzie to już uwzględnione w Twoim planie diety.',
    'dish/portion/you-should-have-this-dish':
        '[Powinnaś|Powinieneś] mieć przygotowaną tą potrawę już wcześniej.',

    'dish/portion/rest': 'Reszta nie zostanie użyta w Twoim planie diety.',

    'dish/portion/eat-x-of': 'Zjedz jedną porcję z {{total}}.',
    'dish/portion/eat-x-of_0': 'Zjedz {{fraction}}&nbsp;porcję z {{total}}.',
    'dish/portion/eat-x-of_1': 'Zjedz {{fraction}}&nbsp;porcje z {{total}}.',
    'dish/portion/eat-x-of_2': 'Zjedz {{fraction}}&nbsp;porcji z {{total}}.',
    'dish/portion/eat-x-of_3': 'Zjedz {{fraction}}&nbsp;porcji z {{total}}.',

    'dish/portion/eat-x-portion': 'Zjedz jedną porcję.',
    'dish/portion/eat-x-portion_0': 'Zjedz {{fraction}}&nbsp;porcję.',
    'dish/portion/eat-x-portion_1': 'Zjedz {{fraction}}&nbsp;porcje.',
    'dish/portion/eat-x-portion_2': 'Zjedz {{fraction}}&nbsp;porcji.',
    'dish/portion/eat-x-portion_3': 'Zjedz {{fraction}}&nbsp;porcji.',

    'dish/portion/x-portion-of': ' - jedna porcja z {{total}}',
    'dish/portion/x-portion-of_0': ' - {{fraction}}&nbsp;porcja z {{total}}',
    'dish/portion/x-portion-of_1': ' - {{fraction}}&nbsp;porcje z {{total}}',
    'dish/portion/x-portion-of_2': ' - {{fraction}}&nbsp;porcji z {{total}}',
    'dish/portion/x-portion-of_3': ' - {{fraction}}&nbsp;porcji z {{total}}',

    'recipe-for-x-portion': 'Przepis na jedną porcję',
    'recipe-for-x-portion_0': 'Przepis na {{count}} porcję',
    'recipe-for-x-portion_1': 'Przepis na {{count}} porcje',
    'recipe-for-x-portion_2': 'Przepis na {{count}} porcji',
    'recipe-for-x-portion_3': 'Przepis na {{count}} porcji',

    'dish/portion/dish-used-in-following-days':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na jedną porcję.',
    'dish/portion/dish-used-in-following-days_0':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcję.',
    'dish/portion/dish-used-in-following-days_1':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcje.',
    'dish/portion/dish-used-in-following-days_2':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcji.',
    'dish/portion/dish-used-in-following-days_3':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcji.',

    'shopping-list/title': 'Lista zakupów',
    'shopping-list/export-to-frisco': 'Zapisz jako listę zakupów Frisco.pl',
    'shopping-list/date-range': 'Zakres dat',
    'shopping-list/show-list-button': 'Pokaż listę',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'Nie masz ułożonej diety na cały zakres dat. Lista zakupów zawiera produkty tylko z dni w których dieta została ułożona.',
    'shopping-list/alert/no-diet-in-period':
        'Brak ułożonej diety w podanym zakresie dat.',
    'shopping-list/bought': 'Kupione',
    'shopping-list/no-products': 'Brak produktów',
    'shopping-list/order': 'Zamów zakupy',
    'shopping-list/print': 'Wydrukuj listę zakupów',
    'shopping-list/frisco-export-too-long-period':
        'Dostępne dla okresu krótszego niż tydzień.',

    'measurments/title': 'Pomiary',
    'measurments/current-measurments-title': 'Twoje aktualne pomiary',
    'measurments/history-measurments-title': 'Historia pomiarów',
    'measurments/edit-measurments-title': 'Edytuj pomiary',
    'measurments/add-measurments-button': 'Dodaj pomiary',
    'measurments/delete-measurment-button': 'Usuń aktualny pomiar',
    'measurments/date': 'Data',
    'measurments/alert-no-measurments-in-period':
        'Brak pomiarów danego typu w danym okresie czasu',
    'measurments/param/body-weight': 'Masa ciała',
    'measurments/param/hips': 'Biodra',
    'measurments/param/hip': 'Biodro',
    'measurments/param/waist': 'Talia',
    'measurments/param/thighs': 'Uda',
    'measurments/param/thigh': 'Udo',
    'measurments/param/chest': 'Klatka',
    'measurments/param/arm': 'Ramię',
    'measurments/state/no-measurement': 'brak pomiaru',
    'measurments/state/change': 'zmiana',
    'measurments/state/no-change': 'bez zmian',

    'no-data': 'brak danych',
    'no-change': 'bez zmian',

    'redirect/diet-settings/title': 'Plan diety',
    'redirect/diet-settings/message':
        'Nie masz jeszcze rozpoczętego planu diety.',
    'redirect/shopping-list/message':
        'Rozpocznij dietę, aby zobaczyć listę zakupów.',
    'redirect/diet-settings/button': 'Rozpocznij dietę',

    'diet-settings/title': 'Ustawienia diety',
    'diet-settings/sex': 'Płeć',
    'diet-settings/sex/male': 'Mężczyzna',
    'diet-settings/sex/female': 'Kobieta',
    'diet-settings/name': 'Imię',
    'diet-settings/current-body-weight': 'Aktualna masa ciała',
    'diet-settings/height': 'Wzrost',
    'diet-settings/birth-date': 'Data urodzenia',
    'diet-settings/your-goal': 'Twój cel',
    'diet-settings/goal/slimming': 'Zmniejszenie masy ciała',
    'diet-settings/goal/stabilization': 'Utrzymanie masy ciała',
    'diet-settings/goal/put-on-weight': 'Zwiększenie masy ciała',
    'diet-settings/goal/gain-muscle-mass': 'Budowanie masy mięśniowej',
    'diet-settings/goal-weight': 'Docelowa masa ciała',
    'diet-settings/triangle/intro':
        'Wybierz obszary, które będzie wspierać Twoja dieta',
    'diet-settings/triangle/body': 'Ciało',
    'diet-settings/triangle/body/desc':
        'Zdrowe ciało jest sprawne fizyczne i\u00a0odporne na szkodliwe czynniki zewnętrzne. Jeżeli potrzebujesz dietetycznego wsparcia w tym zakresie wybierz tę opcję.',
    'diet-settings/triangle/mind': 'Umysł',
    'diet-settings/triangle/mind/desc':
        'Jeżeli na co dzień twój umysł musi być w\u00a0najwyższej formie i nie możesz sobie pozwolić na problemy z koncentracją to jest to odpowiednia dla ciebie opcja.',
    'diet-settings/triangle/libido': 'Libido',
    'diet-settings/triangle/libido/desc':
        'Wybierając tę sferę zapewnię ci w diecie produkty i przyprawy, które nie tylko od wieków są uważane za afrodyzjaki, ale także posiadają składniki, które pośrednio wypływają na organizm, poprawiając całe jego ukrwienie.',
    'diet-settings/diet-type/question': 'Tryb diety',
    'diet-settings/diet-type/classic': 'Diet by Ann Classic - 5 posiłków',
    'diet-settings/diet-type/classic/description':
        'Wyklucza pszenicę i jej pochodne oraz mleko krowie. Źródłem białka w diecie jest głównie mięso, ryby, nabiał i jaja.',
    'diet-settings/diet-type/low-ig': 'Diet by Ann Low IG - 5 posiłków',
    'diet-settings/diet-type/low-ig/description':
        'Dieta bazuje na produktach posiadających niski indeks glikemiczny (IG). Jednocześnie wyklucza pszenicę i jej pochodne oraz mleko krowie. Wybierz ten wariant, jeśli chcesz ograniczyć spożycie cukrów prostych i zadbać o\u00a0uregulowanie poziomu cukru we krwi.',
    'diet-settings/diet-type/smart': 'Diet by Ann Smart - 3 posiłki',
    'diet-settings/diet-type/smart/description':
        'To najszybszy i najłatwiejszy wariant diety. Wspiera działanie Trójkąta Mocy, a\u00a0przy tym wyklucza pszenicę i jej pochodne oraz mleko krowie. Wybierz go, jeśli chcesz spędzać w kuchni minimum czasu.',
    'diet-settings/diet-type/vege': 'Diet by Ann Vege - 5 posiłków',
    'diet-settings/diet-type/vege/description':
        'Wyklucza mięso, ryby i owoce morza, pszenicę i jej pochodne oraz mleko krowie. Uwzględnia natomiast sery, produkty mleczne fermentowane, jaja i\u00a0nasiona roślin strączkowych.',
    'diet-settings/activity/question': 'Ile średnio ćwiczysz w tygodniu?',
    'diet-settings/activity/none': 'Ćwiczę sporadycznie lub w ogóle',
    'diet-settings/activity/none/description': 'Praca biurowa',
    'diet-settings/activity/low': 'Ćwiczę 1-3 godziny tygodniowo',
    'diet-settings/activity/medium': 'Ćwiczę 3-5 godzin w tygodniu',
    'diet-settings/activity/medium/description': 'Ćwiczenia siłowe',
    'diet-settings/activity/high': 'Ćwiczę 3-5 godzin tygodniowo',
    'diet-settings/activity/high/description':
        'Ćwiczenia aerobowe, biegi, spining, itp.',
    'diet-settings/final/text':
        'Czym kierować się przy rozpoczęciu lub zmianie planu diety?',
    'diet-settings/final/list/1':
        'zaplanuj czas na dokonanie zakupów potrzebnych do diety',
    'diet-settings/final/list/2':
        'jeżeli planujesz wyjazd, który uniemożliwi Tobie zastosowanie się do jadłospisu, przełóż start diety na dogodniejszy termin.',
    'diet-settings/final/list/3':
        'nie odwlekaj terminu rozpoczęcia diety — skoro zrobiłeś już pierwszy krok, idź śmiało dalej',
    'diet-settings/final/list/4': 'masz 15 dni na rozpoczęcie diety',
    'diet-settings/final/start-date-question':
        'Od kiedy chcesz rozpocząć dietę lub dokonać w niej zmian?',
    'diet-settings/diet-level/question': 'Poziom zaawansowania Twojej diety',
    'diet-settings/diet-level/level1': 'Dieta HPBA Poziom 1: Dobry Start',
    'diet-settings/diet-level/level1/description':
        'Ta dieta będzie dla Ciebie odpowiednia jeśli nie jadasz pszenicy i nie pijesz mleka krowiego, ale nie masz alergii na białka mleka krowiego. Wybierz tę dietę jeżeli masz potrzebę wykluczenia wyżej wymienionych składników także ze względów zdrowotnych.',
    'diet-settings/diet-level/level2':
        'Dieta HPBA Poziom 2: Droga do Mistrzostwa',
    'diet-settings/diet-level/level2/description':
        'Ta dieta będzie dla Ciebie odpowiednia jeśli wykluczasz gluten i nabiał krowi ze swojego codziennego jadłospisu. Wybierz tę dietę jeżeli masz potrzebę wykluczenia wyżej wymienionych składników także ze względów zdrowotnych.',
    'diet-settings/diet-level/level3':
        'Dieta HPBA Poziom 3: Wyzwanie Wojowniczki',
    'diet-settings/diet-level/level3/description':
        'Ta dieta będzie dla Ciebie odpowiednia jeśli wykluczasz z diety gluten, cały nabiał a także strączki ze względu na alergie czy inne czynniki zdrowotne lub chcesz się zdrowo odżywiać.',
    'diet-settings/activity-type/question': 'Tryb diety',
    'diet-settings/activity-type/classic': 'Diet by Ann — wersja klasyczna',
    'diet-settings/activity-type/classic/description':
        'Dieta dla osób o niskiej aktywności fizycznej oraz dla osób trenujących nieregularnie lub nietrenujących w ogóle.',
    'diet-settings/activity-type/trainings': 'Diet by Ann SPORT',
    'diet-settings/activity-type/trainings/description':
        'Dieta dla osób o wysokiej aktywności fizycznej oraz regularnie trenujących. Uwzględnia rodzaj, czas i porę treningu, a także przekąski okołotreningowe.',

    'diet-settings/psychotest1/question':
        'Wybierz opis, który najbardziej do Ciebie pasuje:',
    'diet-settings/psychotest1/answer1':
        'Prowadzę intensywny tryb życia, nie zwracam uwagi na jedzenie, często korzystam z gotowych dań i przetworzonej żywności, nie mam czasu zawracać sobie głowy tym co spożywam.',
    'diet-settings/psychotest1/answer2':
        'Staram się prowadzić zdrowy tryb życia, ale zazwyczaj nie umiem w tym wytrwać. Myślenie cały czas o tym co mogę zjeść, a czego mam unikać jest męczące. Potrzebuję nowych pomysłów.',
    'diet-settings/psychotest1/answer3':
        'Uważam, że odżywiam się bardzo zdrowo, mimo to nie zawsze czuję się najlepiej.',

    'diet-settings/psychotest2/question':
        'Jakie jest twoje ogólne samopoczucie (weź pod uwagę ostatni miesiąc)?',
    'diet-settings/psychotest2/answer1':
        'Czuję się dobrze, nie mam żadnych dolegliwości.',
    'diet-settings/psychotest2/answer2':
        'Raz lepiej raz gorzej, bywam zmęczona i ociężała.',
    'diet-settings/psychotest2/answer3': 'Tryskam energią, czuję się świetnie!',

    'diet-settings/psychotest3/question':
        'Czy do tej pory [wykluczałaś|wykluczałeś] z diety gluten?',
    'diet-settings/psychotest3/answer1': 'Tak',
    'diet-settings/psychotest3/answer2': 'Nie',
    'diet-settings/psychotest3/answer3':
        '[Starałam|Starałem] się ograniczać, ale nie [wykluczałam|wykluczałem] całkowicie.',

    'diet-settings/psychotest4/question':
        'Czy do tej pory [wykluczałaś|wykluczałeś] z diety mleko krowie?',
    'diet-settings/psychotest4/answer1': 'Tak',
    'diet-settings/psychotest4/answer2': 'Nie',
    'diet-settings/psychotest4/answer3':
        '[Starałam|Starałem] się ograniczać, ale nie [wykluczałam|wykluczałem] całkowicie.',

    'diet-settings/psychotest5/question':
        'Wybierz aktualnie najważniejsze oczekiwanie twojego organizmu:',
    'diet-settings/psychotest5/answer1': 'Wzrost potencjału fizycznego.',
    'diet-settings/psychotest5/answer2': 'Zwalczenie zmęczenia umysłowego.',
    'diet-settings/psychotest5/answer3': 'Zwiększenie zasobów sił witalnych.',

    'diet-settings/psychotest6/question': 'Które zdanie jest Ci najbliższe?',
    'diet-settings/psychotest6/answer1': 'Mam problemy z koncentracją.',
    'diet-settings/psychotest6/answer2':
        'Mój umysł bez przeszkód pracuje na wysokich obrotach.',
    'diet-settings/psychotest6/answer3': 'Żadne z proponowanych.',

    'diet-settings/psychotest7/question':
        'Wybierz najbardziej dla Ciebie odpowiednie:',
    'diet-settings/psychotest7/answer1': 'Prowadzę intensywny tryb życia.',
    'diet-settings/psychotest7/answer2':
        'Mój dzień jest spokojny i uregulowany.',
    'diet-settings/psychotest7/answer3':
        'Gdy kładę się spać to od razu zasypiam.',

    'recommended-on-psychotest': 'Rekomendowane na podstawie testu.',

    'settings/title': 'Ustawienia',
    'settings/profile': 'Profil użytkownika',
    'settings/email': 'Adres e-mail',
    'settings/metric-system': 'System metryczny',
    'settings/si': 'System SI',
    'settings/imperial': 'System imperialny',
    'settings/notifications': 'Powiadomienia',
    'settings/agreements/diet-course': 'Dotyczące przebiegu diety',
    'settings/agreements/articles': 'Motywacja i artykuły',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Wypisanie się z newslettera może zająć do 2 godzin.',
    'settings/change-password': 'Zmień hasło',
    'settings/change-password/current-password': 'Obecne hasło',
    'settings/change-password/new-password': 'Nowe hasło',
    'settings/change-password/success': 'Hasło zostało zmienione',
    'settings/products/access/end-date {date}': 'Dostęp opłacony do {date}.',
    'settings/products/access/past-end-date {date}': 'Dostęp wygasł {date}.',
    'settings/products/access/from-subscription':
        'Dostęp na podstawie subskrypcji.',
    'settings/products/access/lifetime': 'Dostęp dożywotni.',
    'settings/products/access/none': 'Brak.',
    'settings/products/diet/heading': 'Dieta',
    'settings/products/heading': 'Aktywne produkty',
    'settings/products/video-workouts/heading': 'Treningi',
    'settings/subscriptions/cancellation-instructions': 'Instrukcja anulowania',
    'settings/subscriptions/heading': 'Aktywne subskrypcje',
    'settings/subscriptions/diet/heading': 'Dieta',
    'settings/subscriptions/video-workouts/heading': 'Treningi',
    'settings/subscriptions/diet+video-workouts/heading': 'Dieta i treningi',
    'settings/subscriptions/none': 'Brak.',
    'settings/subscriptions/renewal-date {date}':
        'Następne odnowienie: {date}.',
    'settings/subscriptions/renewal-date/in-past':
        'Odnowienie w trakcie przetwarzania.',
    'settings/subscriptions/type/diet+video-workouts':
        'Dostęp do diety i treningów.',
    'settings/subscriptions/type/diets': 'Dostęp do diety.',
    'settings/subscriptions/type/video-workouts': 'Dostęp do treningów.',
    'settings/hydration': 'Nawodnienie',
    'settings/hydration/daily-goal': 'Dzienny cel',
    'settings/hydration/success': 'Cel zmieniony',
    'settings/hydration/notifications': 'Przypomnienia o piciu wody',
    'settings/hydration/notifications/hour-interval': 'Co ile godzin',
    'settings/hydration/notifications/from-hour': 'Od godziny',
    'settings/hydration/notifications/to-hour': 'Do godziny',
    'settings/hydration/notifications/use-sound': 'Z dźwiękiem',
    'settings/remove-account': 'Usuwanie konta',

    'remove-account/title': 'Usuwanie konta',
    'remove-account/app-store/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach konta Apple ID na swoim iPhonie.',
    'remove-account/google-play/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach aplikacji Google Play.',
    'remove-account/huawei/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach swojego konta Huawei.',
    'remove-account/email-confirm-info':
        'Proces usuwania konta wymaga weryfikacji Twojego konta. W tym celu wyślemy do Ciebie e-mail na adres:  {{userEmail}}',
    'remove-account/message-sent': 'Wiadomość wysłana',
    'remove-account/link-expire-info': 'Link wygaśnie po 24 godzinach.',
    'remove-account/check-your-mailbox':
        'Sprawdź swoją skrzynkę e-mail. Kliknij link w wiadomości, którą do Ciebie wysłaliśmy. <br /> <b>Twoje konto nie zostanie usunięte, dopóki tego nie zrobisz.</b>',
    'remove-account/confirmation':
        'Potwierdzam, chcę usunąć swoje konto z aplikacji.',
    'remove-account/info-title': 'Po usunięciu konta:',
    'remove-account/info_1':
        'nie zalogujesz się na swoje konto, przez co stracisz dostęp do aplikacji',
    'remove-account/info_2':
        'utracisz wszystkie swoje postepy, historię, nagrody itd. dotyczączące treningów, diety i pozostałych aktywności w aplikacji',
    'remove-account/info_3':
        'dane dotyczące twojego konta zostaną bezpowrotnie usunięte',
    'remove-account/error': 'Wystąpił błąd',
    'remove-account/try-in-moment': 'Spróbuj ponownie za chwilę.',
    'remove-account/link-expired': 'Ważność linka wygasła.',
    'remove-account/link-expired-info':
        'Ponów w aplikacji proces usuwania konta, aby otrzymać nową wiadomość e-mail.',
    'remove-account/success': 'Twoje konto zostało usunięte',
    'remove-account/success-info':
        'Za moment zostaniesz automatycznie wylogowany z aplikacji.',

    'footer/service-access': 'Dostęp do usługi i płatności',
    'footer/tos': 'Regulamin',
    'footer/privacy-policy': 'Polityka prywatności',
    'footer/contact': 'Kontakt',
    'footer/secure-payments': 'Bezpieczne płatności zapewnia',
    'button/buy-diet': 'Kup dostęp do diety',
    'button/buy-subscribtion': 'Kup teraz',
    'button/buy': 'Kup',
    'button/remove-account': 'Usuń konto',
    'button/retry': 'Ponów',

    'error/unauthorized/subscription/expired/title':
        'Twoja subskrypcja wygasła\n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'Kontynuuj swoją dietę korzystając z oferty dla stałych klientów.',
    'error/unauthorized/subscription/never-had/title': 'Zacznij dietę już dziś',
    'error/unauthorized/subscription/never-had/content':
        'Już tylko krok dzieli Cię od rozpoczęcia diety. Nie bój się nowych wyzwań. Wyznacz sobie cel i walcz o jego realizację. Pomogę Ci w diecie i wierzę, że wspólnymi siłami się nam uda! Nie traćmy czasu! Zaczynamy?',
    'error/unauthorized/subscription/refresh': 'Odśwież',

    'info-pages/goal-reached/title': 'Brawo! [Osiągnęłaś|Osiągnąłeś] swój cel',
    'info-pages/goal-reached/content':
        'Teraz możesz rozpocząć dietę stabilizującą, która pomoże Tobie utrzymać dotychczasowy efekt. Zawsze możesz też kontynuować utratę kilogramów obniżając docelową masę ciała.',
    'info-pages/goal-reached/button': 'Dostosuj plan diety',

    'info-pages/goal-lost/title': 'Nieładnie! [Utraciłaś|Utraciłeś] swój cel',
    'info-pages/goal-lost/content':
        'Aby powrócić do swojej wymarzonej masy ciała proponuję zmianę diety na odchudzającą.',
    'info-pages/goal-lost/button': 'Zmień plan diety',

    'info-pages/current-weight-needed/title': 'Dodaj aktualny pomiar',
    'info-pages/current-weight-needed/content':
        'W celu przygotowania dalszego planu diety musisz dodać aktualny pomiar masy ciała.',
    'info-pages/current-weight-needed/button': 'Dodaj pomiar',

    'info-pages/diet-available-in-future/title':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_0':
        'Twoja dieta będzie dostępna za {{count}} dzień',
    'info-pages/diet-available-in-future/title_1':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_2':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_3':
        'Twoja dieta będzie dostępna za {{count}} dnia',
    'info-pages/diet-available-in-future/content':
        'Nowy plan diety może być przygotowany najwcześniej na 7 dni przed rozpoczęciem. Pamiętaj, aby w tym czasie, dokonać aktualnego pomiaru masy ciała.',

    'info-pages/diet-outside-access/title':
        'Twoja subskrypcja kończy się za jeden dzień, {{date}}',
    'info-pages/diet-outside-access/title_0':
        'Twoja subskrypcja kończy się za {{count}} dzień, {{date}}',
    'info-pages/diet-outside-access/title_1':
        'Twoja subskrypcja kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/title_2':
        'Twoja subskrypcja kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/title_3':
        'Twoja subskrypcja kończy się za {{count}} dnia, {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'nie wiadomo ile',

    'info-pages/diet-outside-access/web-title':
        'Twój abonament kończy się za jeden dzień, {{date}}',
    'info-pages/diet-outside-access/web-title_0':
        'Twój abonament kończy się za {{count}} dzień, {{date}}',
    'info-pages/diet-outside-access/web-title_1':
        'Twój abonament kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/web-title_2':
        'Twój abonament kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/web-title_3':
        'Twój abonament kończy się za {{count}} dnia, {{date}}',

    'contact/title': 'Kontakt',
    'contact/faq': 'Najczęściej zadawane pytania',
    'contact/contact-form': 'Formularz kontaktowy',
    'contact/name-surname': 'Imię i nazwisko',
    'contact/email-address': 'Twój adres e-mail',
    'contact/topic': 'Temat wiadomości',
    'contact/topic/topic1': 'Problem z zakupem planu diety',
    'contact/topic/topic2': 'Pytanie do dietetyka',
    'contact/topic/topic3': 'Pytanie o treningi',
    'contact/topic/topic4': 'Problem z dostępem do serwisu',
    'contact/topic/topic5': 'Plan diety - reklamacje i zwroty',
    'contact/topic/topic6': 'Sklep internetowy - reklamacje i zwroty',
    'contact/topic/topic7': 'Współpraca',
    'contact/message': 'Wiadomość',
    'contact/send-message': 'Wyślij wiadomość',
    'contact/send-success': 'Wiadomość została wysłana. Dziękujemy.',

    'knowledge/title': 'Wiedza',
    'knowledge/show': 'Pokaż:',
    'knowledge/all': 'Wszystko',
    'knowledge/diet': 'Dieta',
    'knowledge/motivation': 'Motywacja',
    'knowledge/tips': 'Porady',
    'knowledge/excercise': 'Ćwiczenia',
    'knowledge/back-to-article-list': 'Powrót do listy artykułów',
    'knowledge/other-articles': 'Pozostałe artykuły',
    'knowledge/no-results': 'Brak wyników',
    'knowledge/read-more': 'Czytaj więcej',
    'knowledge/back': 'Wróć',
    'knowledge/article-details': 'Szczegóły artykułu',
    'knowledge/load-more': 'Więcej artykułów',

    'product/buy': 'Kup',

    'me/account/activate/message': 'Twoje konto zostało aktywowane.',

    'make-decision/title': 'Podejmij decyzję',
    'make-decision/start': 'Start',
    'make-decision/now': 'Obecnie',
    'make-decision/goal': 'Cel',
    'make-decision/left': 'Zostało',
    'make-decision/put-on-weight': 'Przytycie',
    'make-decision/gain-muscle-mass': 'Budowanie mięśni',
    'make-decision/weight-loose': 'Odchudzanie',
    'make-decision/stabilization': 'Stabilizacja',
    'make-decision/continue-slimming':
        'Jeśli chcesz kontynuować swoje odchudzanie wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        'Osiągnąłeś już swoją docelową masę ciała. Wybierz dzień rozpoczęcia diety stabilizującej, który zapewni Tobie utrzymanie efektów odchudzania.',
    'make-decision/continue-stabilization':
        'Jeżeli pomimo zmiany masy ciała nadal chcesz stosować dietę stabilizującą, kliknij w poniższy przycisk. Pamiętaj że wybranie tej opcji spowoduje, że ostatnia wartość dodanego pomiaru masy ciała zostanie ustawiona, jako Twoja obecna masa docelowa.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Ponieważ Twoja masa ciała wzrosła, rekomendujemy przejście do diety odchudzającej. Wpisz nową masę docelową oraz wybierz termin rozpoczęcia diety.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Ponieważ Twoja masa ciała zmalała, rekomendujemy przejście do diety na przytycie. Wpisz nową masę docelową oraz wybierz termin rozpoczęcia diety.',
    'make-decision/continue-put-on-weight':
        'Jeśli chcesz kontynuować dietę na przytycie wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'Osiągnąłeś już swoją docelową masę ciała. Wybierz dzień rozpoczęcia diety stabilizującej, który zapewni Tobie utrzymanie efektów.',
    'make-decision/continue-gain-muscle-mass':
        'Jeśli chcesz kontynuować budowanie masy mięśniowej wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'Osiągnąłeś już swoją docelową masę ciała. Wybierz dzień rozpoczęcia diety stabilizującej, który zapewni Tobie utrzymanie efektów.',

    'route-title/default': 'Diet and Training by Ann',
    'route-title/day-plan': 'Dieta',
    'route-title/diet-intro': 'Wstęp',
    'route-title/day-plan-with-date': 'Dieta',
    'route-title/diet-will-be-available-in-future':
        'Dieta będzie dostępna w przyszłości',
    'route-title/current-weight-needed': 'Dodaj aktualny pomiar',
    'route-title/goal-reached': 'Cel osiągnięty',
    'route-title/goal-lost': 'Cel utracony',
    'route-title/make-decision': 'Podejmij decyzję',
    'route-title/shopping-list': 'Lista zakupów',
    'route-title/measurements': 'Pomiary',
    'route-title/measurements-add': 'Dodaj pomiary',
    'route-title/measurements-edit': 'Edytuj pomiary',
    'route-title/diet-settings': 'Ustawienia diety',
    'route-title/settings': 'Ustawienia',
    'route-title/login-by-token': 'Login by Token',
    'route-title/logout': 'Logout',
    'route-title/error-404': 'Błąd 404',
    'route-title/unauthorized-exception': 'Brak dostępu',
    'route-title/content': '',
    'route-title/contact': 'Kontakt',
    'route-title/knowledge-category': 'Wiedza',
    'route-title/knowledge': 'Wiedza',
    'route-title/knowledge-article': '',
    'route-title/quiz': 'Quiz',
    'route-title/diet-outside-access': 'Brak dostępu',
    'route-title/block-cookies': '',
    'route-title/error-network': 'Wystąpił błąd sieci!',
    'route-title/activities': 'Moje aktywności',
    'route-title/activities-index': 'Aktywności',
    'route-title/activities-add': 'Dodaj aktywność',
    'route-title/activities-edit': 'Edytuj aktywność',
    'route-title/promo-code': 'Kupon rabatowy',
    'route-title/unsubscribe': 'Przykro nam, że odchodzisz',
    'route-title/error-application': 'Błąd aplikacji',
    'mobile-title/knowledge': 'Wiedza',
    'route-title/mindfulness': 'Balans',
    'route-title/fav-meals': 'Ulubione posiłki',

    'alert/periodic-measurements':
        '<b>Pamiętaj dodać swoje aktualne pomiary</b>, aby śledzić postępy.',
    'alert/periodic-measurements-1':
        'Okresowe pomiary masy ciała są konieczne, aby śledzić postępy w realizacji celu.',
    'alert/periodic-measurements-2':
        'Przypominamy o konieczności zważenia się, najlepiej jeszcze dzisiaj!',

    'auth/confirm/header': 'Dziękujemy',
    'auth/confirm/content':
        'Twoje konto zostało aktywowane. Możesz zacząć korzystać z aplikacji mobilnej Diet & Training by Ann.',
    'auth/confirm/mobileApps':
        'Nie masz aplikacji mobilnej? Ściągnij ją teraz!',

    'auth/login/title': 'Zaloguj się',
    'auth/login/with-google': 'Kontynuuj przez Google',
    'auth/login/with-apple': 'Kontynuuj przez Apple',
    'auth/login/with-facebook': 'Kontynuuj przez Facebooka',
    'auth/login/user-exists': 'Konto już istnieje. Użyj funkcji logowania.',
    'auth/login/user-not-exists':
        'Konto nie istnieje. Użyj funkcji rejestracji.',
    'auth/login/agreement':
        'Logowanie oznacza akceptację aktualnego regulaminu serwisu. Ostatnich zmian w regulaminie dokonano 3 kwietnia 2019.',
    'auth/login/back-link-text': 'Powrót do logowania',
    'auth/login/menu-link-text': 'Zaloguj się',
    'auth/login/error-message': 'Logowanie nie powiodło się.',
    'auth/login/social-media-login-header':
        'Logowanie przez serwisy społecznościowe',
    'auth/login/social-media-set-up':
        'Twoje konto jest powiązane z serwisem społecznościowym.',

    'auth/remind-password/title': 'Odzyskaj hasło',
    'auth/remind-password/description':
        'Wpisz e-mail, aby otrzymać wiadomość z instrukcją do zmiany hasła.',
    'auth/remind-password/link-text': 'Nie pamiętam swojego hasła',
    'auth/remind-password/error-message':
        'Nie znaleziono użytkownika o podanym adresie e-mail.',
    'auth/remind-password/success-message':
        'E-mail z instrukcją do zmiany hasła został wysłany.',

    'auth/reset-password/title': 'Ustaw nowe hasło',
    'auth/reset-password/token-is-not-valid-error-message':
        'Twój link do ustawiania hasła wygasł, jeśli nadal chcesz go użyć, skorzystaj ponownie z opcji "Nie pamiętam swojego hasła"',
    'auth/reset-password/error-message':
        'Hasło powinno mieć co najmniej 4 znaki.',
    'auth/reset-password/success-message':
        'Hasło do Twojego konta zostało ustawione.',

    'auth/password-is-not-set':
        'Nie masz jeszcze ustawionego hasła. Kliknij w przycisk poniżej aby ustawić hasło.',
    'auth/set-password': 'Ustaw hasło',
    'auth/set-password/title': 'Ustaw hasło',

    email: 'Adres email',
    password: 'Hasło',
    'password-to-login': 'Hasło do logowania',
    'new-password': 'Nowe hasło',
    name: 'Imię',

    'form/send': 'Wyślij',
    'validation/required': 'Pole jest wymagane.',
    'validation/date-format': 'Format daty jest niepoprawny.',
    'validation/email-format': 'Nieprawidłowy adres email.',
    'validation/url-format': 'Nieprawidłowy adres url.',

    'activities/intro/title': 'Plan aktywności',
    'activities/intro/heading1':
        'Dla kogo przeznaczone są treningi w planie diety?',
    'activities/intro/paragraph1':
        'Treningi w planie diety pozwalają na jeszcze lepsze dopasowanie posiłków do Twojej aktywności fizycznej. Przeznaczone są dla osób regularnie ćwiczących, nastawionych na realizację konkretnego celu treningowego.',
    'activities/intro/heading2':
        'Co zmieni się w planie diety po aktywacji treningów?',
    'activities/intro/paragraph2':
        'Twój plan diety zostanie stworzony od nowa i będzie uwzględniał ułożony przez Ciebie Plan Treningów. W jadłospisie pojawią się dodatkowe posiłki przed i po treningowe, a cały plan diety zostanie odpowiednio zbilansowany pod względem kalorycznym oraz wartości odżywczych.',
    'activities/intro/button': 'Aktywuj plan treningowy',
    'activities/my-activities': 'Moje aktywności',
    'activities/edit-activities': 'Edytuj aktywności',
    'activities/disclaimer':
        'Pamiętaj, że każdorazowa zmiana (dodanie lub usunięcie treningu), powoduje przygotowanie nowego planu diety, różniącego się pod względem wykorzystanych produktów od obecnego.',
    'activities/total': 'Łącznie: ',
    'activities/diet-plan': 'Plan diety',
    before_breakfast: 'Przed śniadaniem',
    after_breakfast: 'Po śniadaniu',
    before_dinner: 'Przed obiadem',
    after_dinner: 'Po obiedzie',
    before_supper: 'Przed kolacją',
    after_supper: 'Po kolacji',
    'activities/start-diet': 'Rozpocznij',
    'activities/diet-settings': 'Ustawienia diety',
    'activities/add-activity': 'Dodaj aktywność',
    'activities/new-activity': 'Nowa aktywność',
    'activities/add-activity-single': 'Dodaj aktywność',
    'activities/add-activity-periodic': 'Dodaj aktywność cykliczną',
    'activities/edit-activity': 'Edytuj aktywność',
    'activities/edit-single-activity': 'Edytuj aktywność',
    'activities/edit-periodic-activity': 'Edytuj wszystkie aktywności w cyklu',
    'activities/remove-activity': 'Usuń aktywność',
    'activities/remove-single-activity': 'Usuń aktywność',
    'activities/remove-periodic-activity': 'Usuń wszystkie aktywności w cyklu',
    'activities/time-of-day': 'Pora dnia',
    'activities/type-of-activity': 'Rodzaj aktywności',
    'activities/more': 'Więcej',
    'activities/activity-time': 'Czas trwania aktywności',
    'activities/burned-calories': 'Wydatek energetyczny',
    'activities/repeat-every': 'Powtarzaj w każdy',
    'activities/activity1': 'Bieganie/szybkie chodzenie',
    'activities/activity2': 'Ćwiczenia',
    'activities/activity3': 'Jazda na rowerze',
    'activities/activity4': 'Pływanie',
    'activities/duration': 'Czas trwania',
    'activities/training': 'Trening',
    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',

    'promo-code/page-title': 'Wpisz kupon rabatowy',
    'promo-code/input-label': 'Kupon rabatowy',
    'promo-code/missing-value': 'Wpisz kupon rabatowy',
    'promo-code/invalid-value': 'Niepoprawny kupon',
    'promo-code/submit': 'Weryfikuj kupon',
    'promo-code/not-exists': 'Kod promocyjny jest nie prawidłowy',
    'promo-code/has-been-activated': 'Kod promocyjny został aktywowany',

    'quiz/intro/title': 'Jak dobrze znasz moją dietę?',
    'quiz/intro/copy':
        'Odpowiedz na 10 pytań, sprawdź swój wynik i otrzymaj przepisy na dania z mocą!',
    'quiz/intro/quote':
        'Czy sądzisz, że wszyscy, którzy coś osiągnęli mieli wyjątkowe talenty? Większość sukcesów bierze się z wiary, że można, Ty możesz. Nie kończ na rozmyślaniach - działaj! Jestem z Tobą.',
    'quiz/intro/sign': 'Anna Lewandowska',
    'quiz/intro/numbers1': 'Liczba pytań w quizie',
    'quiz/intro/numbers2': 'Średni czas rozwiązania',
    'quiz/intro/numbers3': 'Średni wynik quizu',
    'quiz/start-link': 'Rozpocznij quiz',

    'quiz/title': 'Quiz',

    'quiz/question/previous': 'Poprzednie pytanie',
    'quiz/receive-prize': 'Zobacz wynik quizu',
    'quiz/receive-prize/short': 'Zobacz wynik',
    'quiz/receive-prize/button': 'Poznaj wynik quizu',

    'quiz/receive-prize/title': 'Podaj e-mail, zobacz wyniki i odbierz nagrodę',
    'quiz/receive-prize/leave-email': 'Zostaw swój e-mail aby otrzymać:',
    'quiz/receive-prize/argument1':
        'Dostęp do poradnika z przepisami na dania z mocą autorstwa Ani Lewandowskiej',
    'quiz/receive-prize/argument2':
        'Wyniki quizu z zaznaczonymi Twoimi oraz poprawnymi odpowiedziami',

    'quiz/result/title': 'Wyniki quizu',
    'quiz/result/your-result': 'Twój wynik quizu to:',
    'quiz/result/your-answer': 'Twoje odpowiedzi do quizu',
    'quiz/result/button': 'Odbierz poradnik za darmo',
    'quiz/result/score0-3':
        'Nie jest źle, ale zawsze może być lepiej. Najlepsze, co możesz teraz zrobić, to nie poddawać się! Początki bywają trudne, ale jestem z Tobą. Wiem, że osiągniesz sukces!',
    'quiz/result/score4-6':
        'O zdrowym odżywianiu wiesz naprawdę sporo 🙂 Jednak czy swoją wiedzę przekładasz też na praktykę? Jeśli nie, to nic straconego. Przygotowałam dietę dla właśnie takich osób, jak Ty!',
    'quiz/result/score7-10':
        'Świetnie! Jesteś prawdziwym ekspertem! Czy razem ze mną chcesz wejść na najwyższy poziom zdrowego odżywiania?',
    'quiz/result/quote':
        'Nie jest źle, ale zawsze może być lepiej. Najlepsze, co możesz teraz zrobić, to nie poddawać się! Początki bywają trudne, ale jestem z Tobą. Wiem, że osiągniesz sukces!',
    'quiz/result/sign': 'Anna Lewandowska',
    'quiz/result/get-your-guide': 'Odbierz poradnik za darmo',
    'quiz/result/open-guide': 'Otwórz poradnik',

    'exitup/title':
        'Sprawdź, ile wiesz na temat zdrowego odżywiania - wypełnij QUIZ!',
    'exitup/content':
        'W nagrodę otrzymasz dania z mocą autorstwa Ani Lewandowskiej.',
    'exitup/button': 'Rozpocznij quiz',

    'Something went wrong': 'Coś poszło nie tak',
    'Incident has been reported and we will work to solve it':
        'Incydent został zgłoszony, będziemy pracować nad jego rozwiązaniem.',
    'Go back': 'Wróć',
    'Try again': 'Spróbuj ponownie',

    'diet-faq/title': 'Pytania',

    'account/activate/title': 'Aktywacja konta',
    'account/activate/error': 'Aktywacja konta nie powiodła się.',

    'coupon/title': 'Aktywacja',
    'coupon/continue': 'Kontynuuj z kontem:',
    'coupon/register-user/title': 'Stwórz konto',
    'coupon/access-code': 'Kod dostępu',
    'coupon/login/title': 'Zaloguj się',
    'coupon/activate/title': 'Podaj swój kod dostępu do serwisu',
    'coupon/activated-successfully': 'Kupon został aktywowany',
    'coupon/activate-card/title': 'Aktywuj kod dostępu',
    'coupon/card-instruction': 'Aby skorzystać z karty dostępu:',
    'coupon/card-instruction/1': 'wypełnij wszystkie pola formularza',
    'coupon/card-instruction/2': 'przepisz kod ze swojej karty',
    'coupon/card-instruction/3':
        'jeśli wszystkie dane są poprawne, kliknij w przycisk.',
    'coupon/send': 'Aktywuj dostęp',

    'agreements/terms-and-conditions/text': 'Akceptuję ',
    'agreements/terms-and-conditions/text2': 'Regulamin',
    'agreements/terms-and-conditions/text3': ' oraz ',
    'agreements/terms-and-conditions/text4': 'Politykę prywatności',
    'agreements/terms-and-conditions/text5':
        ' i wyrażam zgodę na przetwarzanie moich danych osobowych.',
    'agreements/processing-personal-data/text':
        'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    'agreements/processing-personal-data/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',

    'newsletter/unsubscribe/title': 'Przykro nam, że odchodzisz',
    'newsletter/unsubscribe/success':
        'Adres email został wypisany z newslettera.',
    'newsletter/unsubscribe/send': 'Wypisz mnie',
    'read-more': 'więcej',

    'cart/title': 'Koszyk',
    'cart/products': 'Produkty',
    'cart/payment-method': 'Forma płatności',
    'cart/free-delivery-info':
        'Dodaj produkty za {{ price }}, aby mieć darmową wysyłkę.',
    'cart/delivery/heading': 'Adres dostawy',
    'cart/delivery/name': 'Imię i nazwisko lub nazwa firmy',
    'cart/delivery/street': 'Ulica, nr domu i lokalu',
    'cart/delivery/postal-code': 'Kod pocztowy',
    'cart/delivery/city': 'Miasto',
    'cart/delivery/phone': 'Numer telefonu',
    'cart/delivery/country': 'Kraj',
    'cart/summary': 'Podsumowanie zamówienia',
    'cart/summary/products': 'Produkty łącznie',
    'cart/summary/delivery': 'Dostawa',
    'cart/summary/total': 'Do zapłaty',
    'cart/pay': 'Zapłać',
    'cart/accept-condition/text':
        'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    'cart/accept-condition/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',
    'cart/accept-condition/validation/required': 'Zgoda jest wymagana',
    'cart/upsell/you-save': 'Oszczędzasz',
    'cart/upsell/add-product': 'Dodaj do koszyka',
    'cart/upsell/added': 'Dodany',
    'cart/upsell/about': 'O produkcie',
    'cart/next': 'Dalej',
    'cart/go-to-payment': 'Przejdź do płatności >',
    'cart/safe-shopping': 'Bezpieczne zakupy',
    'cart/back-to-cart': 'Wróć do koszyka',
    'cart/confirm-and-order': 'Potwierdzam i zamawiam',
    'cart/change': 'zmień',
    'cart/order-summary': 'Podsumowanie zamówienia',
    'cart/address-of-delivery': 'Adres dostawy',
    'cart/payment': 'Płatność',
    'cart/payment/error-occured':
        'Przepraszamy, ale nie mogliśmy rozpocząć Twojej płatności. Spróbuj ponownie i daj nam znać jeśli problem będzie się powtarzał.',
    'cart/payment-cod/error-occured':
        'Przepraszamy, ale nie mogliśmy zrealizować Twojego zamówienia. Spróbuj ponownie i daj nam znać jeśli problem będzie się powtarzał.',
    'cart/order-is-pending': 'Zamówienie zostało przyjęte.',
    'cart/add/invalid-product-id':
        'Wybrany produkt nie jest dostępny w sprzedaży.',
    'cart/login-or': 'lub',
    'cart/order-confirm': 'Podsumowanie zamówienia',
    'cart/register/login': 'Rejestracja i logowanie',

    'thank-you/title1': 'Dziękujemy za zamówienie',
    'thank-you/title2': 'Co dalej?',
    'thank-you/how-to-activate-the-service': 'Jak aktywować usługę?',
    'thank-you/step1':
        'Odbierz maila, w którym znajdziesz potwierdzenie wraz z podsumowaniem swojego zamówienia.',
    'thank-you/step2':
        'Przystępujemy do przygotowania Twojego zamówienia do realizacji. Powiadomimy Cię mailem jak tylko paczka będzie gotowa do wysyłki.',
    'thank-you/step3':
        'W kolejnej wiadomości otrzymasz kod śledzenia wraz z instrukcją jak monitorować swoje zamówienie. Aż 91% przesyłek jest dostarczanych w ciągu 2 dni roboczych.',
    'thank-you/activation-code-and-instruction':
        'Kod aktywacyjny wraz z instrukcją, jak go użyć znajdziesz w przesyłce.',
    'thank-you/flyer-header': 'Diet by Ann - kod dostępu',
    'thank-you/flyer-content1':
        'Nie nastawiaj się na odchudzanie jak na męczarnię, którą trzeba z mozołem i bólem jakoś przetrwać.',
    'thank-you/flyer-content2':
        'Potraktuj zrzucanie zbędnych kilogramów, jako wyzwanie, któremu radośnie sprostasz.',
    'thank-you/flyer-content3': '- Anna Lewandowska',
    'thank-you/flyer-footer1': 'Wejdź na stronę ',
    'thank-you/flyer-footer2': ', wpisz poniższy kod i aktywuj usługę.',
    'thank-you/go-to-diet-plan': 'Przejdź do planu diety',
    'thank-you/go-to-diet-plan/content':
        'Twoja dieta jest już gotowa. Poznawaj nowe smaki, jedz zdrowo, a jednocześnie z każdym dniem przybliżaj się do wymarzonej sylwetki.',
    'thank-you/start-configuring-your-diet':
        'Rozpocznij konfigurowanie Twojej diety',
    'thank-you/start-configuring-your-diet/content':
        'Zanim zaczniesz smaczną i zdrową drogę ku wymarzonej sylwetce, potrzebujemy od Ciebie kilku dodatkowych informacji.',
    'thank-you/start-configuring-your-diet/button': 'Konfiguruj dietę',
    'thank-you/warning':
        'UWAGA! Aby w pełni korzystać z zasobów serwisu aktywuj swoje konto. Link aktywacyjny wysłaliśmy Tobie na adres: {{email}}. Jeżeli nie [otrzymałaś|otrzymałeś] wiadomości sprawdź folder spam.',
    'thank-you/start-training': 'Rozpocznij trening',
    'thank-you/start-training/content':
        'Treningi dostępne są wyłącznie w aplikacji Diet & Training by Ann dla telefonów z systemem iOS oraz Android. Kliknij odpowiednią ikonę, pobierz aplikację i zacznij ćwiczyć z Anią Lewandowską',
    'thank-you/order-is-processed': 'Twoje zamówienie jest przetwarzane',
    'thank-you/go-to-home': 'Powróć na stronę główną',

    'loading/message': 'Ładowanie…',
    'et-al/abbrev': 'itp',
    'et-al/full': 'i tym podobne',

    'payment/pending/title': '[Zamówiłaś|Zamówiłeś] pakiet: {{ products }}',
    'payment/pending/description':
        'Dostęp do Twojej usługi otrzymasz, gdy zaksięgujemy Twoją wpłatę. Czas oczekiwania zależy od wybranego przez Ciebie sposobu płatności.',
    'payment/pending/make-payment-description':
        'Jeżeli nie [dokonałaś|dokonałeś] jeszcze płatności, możesz to zrobić teraz.',
    'payment/pending/make-payment': 'Zapłać teraz',

    'message/showing-cached-data': 'Dane mogą nie być aktualne.',
    'message/showing-cached-data/network-error':
        'Nie udało się pobrać danych — pokazane dane mogą być nieaktualne.',
    'message/showing-cached-data/network-error-offline':
        'Brak połączenia z Internetem — pokazane dane mogą być nieaktualne.',

    'workouts/title': 'Ćwiczenia i plany treningowe',

    'units/mililiter/full': 'mililitr(ów)',
    'units/mililiter/full_0': 'mililitr',
    'units/mililiter/full_1': 'mililitry',
    'units/mililiter/full_2': 'mililitrów',
    'units/mililiter/full_3': 'mililitra',
    'units/mililiter/abbr': 'ml',

    'units/ounce/full': 'uncji',
    'units/ounce/full_0': 'uncja',
    'units/ounce/full_1': 'uncje',
    'units/ounce/full_2': 'uncji',
    'units/ounce/full_3': 'uncji',
    'units/ounce/abbr': 'oz',

    'route-title/hydration-day': 'Twoje nawodnienie',
    'menu/hydration': 'Nawodnienie',
    'menu/your-hydration': 'Twoje nawodnienie',
    'hydration/add-custom': 'Dodaj własną porcję wody',
    'hydration/goal-label': 'Dzienny cel:',
    'hydration/goal-changer-label': 'Dzienny cel nawodnienia',
    'hydration/goal-header/reached':
        'Gratulacje! [Osiągnęłaś|Osiągnąłeś] swój dzienny cel.',
    'hydration/goal-reached-label': 'Gratulacje!',
    'hydration/goal-reached1': 'Udało Ci się osiągnąć zamierzony cel.',
    'hydration/goal-reached2':
        'To ważne, ponieważ optymalne nawodnienie reguluje pracę i funkcjonowanie całego organizmu!',
    'hydration/goal-left/past/pre-value':
        'Dzisiaj [powinnaś|powinieneś] wypić jeszcze',
    'hydration/goal-left/past/post-value': 'wody',
    'hydration/goal-left/present/pre-value':
        'Dzisiaj [powinnaś|powinieneś] wypić jeszcze',
    'hydration/goal-left/present/post-value': 'wody',
    'hydration/goal-left/future/pre-value':
        '{{date}} [powinnaś|powinieneś] wypić',
    'hydration/goal-left/future/post-value': 'wody',
    'hydration/progress/label': 'wypite',
    'hydration/remove-last-portion': 'Usuń ostatnią porcję wody',
    'route-title/hydration-add': 'Dodaj porcję wody',
    'hydration/add-portion': 'Dodaj porcję',
    'hydration/add/heading': 'Ile wody [wypiłaś|wypiłeś]?',
    'hydration/add/submit': 'Dodaj',
    'hydration/add/cancel': 'Anuluj',
    'hydration/add/custom': 'własne',
    'hydration/full-glass': 'Pełna szklanka',
    'hydration/full-bottle': 'Pełna butelka',
    'hydration/add-global-action': 'Dodaj wypitą dziś porcję wody',
    'route-title/hydration-history': 'Historia nawadniania',
    'menu/hydration-history': 'Historia',
    'hydration/go-to-history': 'Historia',
    'hydration/back-to-hydration': 'Wróć',
    'hydration/recommended-value': '{{value}} (rekomendowane)',
    'hydration/glass-value': '{{value}} (szklanka)',
    'hydration/glass': 'szklanka',
    'hydration/half-bottle-value': '{{value}} (pół butelki)',
    'hydration/bottle-value': '{{value}} (butelka)',
    'hydration/bottle': 'butelka',
    'default-value': 'Wartość domyślna',
    'individual-goal': 'Indywidualny cel',

    'mindfulness/title': 'Osiągnij spokój ducha',

    'fav-meals/added': 'Dodano do ulubionych.',
    'fav-meals/removed': 'Usunięto z ulubionych.',
    'fav-meals/back': 'Cofnij',
    'fav-meals/added-to-dayplan': 'Posiłek dodany do jadłospisu.',
    'fav-meals/added-to-dayplan-today': 'Posiłek dodany do jadłospisu na dziś.',
    'fav-meals/added-to-dayplan-tomorrow':
        'Posiłek dodany do jadłospisu na jutro.',
    'fav-meals/insert-into-dayplan': 'Wstaw posiłek do jadłospisu',
    'fav-meals/insert-today': 'Dzisiaj',
    'fav-meals/insert-tomorrow': 'Jutro',
    'fav-meals/insert-date': 'Wybierz dzień',

    'calendar/january': 'Styczeń',
    'calendar/february': 'Luty',
    'calendar/march': 'Marzec',
    'calendar/april': 'Kwiecień',
    'calendar/may': 'Maj',
    'calendar/june': 'Czerwiec',
    'calendar/july': 'Lipiec',
    'calendar/august': 'Sierpień',
    'calendar/september': 'Wrzesień',
    'calendar/october': 'Październik',
    'calendar/november': 'Listopad',
    'calendar/december': 'Grudzień',

    'calendar/monday': 'P',
    'calendar/tuesday': 'W',
    'calendar/wednesday': 'Ś',
    'calendar/thursday': 'C',
    'calendar/friday': 'P',
    'calendar/saturday': 'S',
    'calendar/sunday': 'N',

    'calendar/reset': 'Wyzeruj',
    'calendar/ok': 'OK',

    'terms-of-service/title': 'Regulaminy serwisu i aplikacji',
    'terms-of-service/web': 'Zakup za pośrednictwem strony internetowej',
    'terms-of-service/android': 'Zakup w Google Play',
    'terms-of-service/huawei': 'Zakup w Huawei AppGallery',
    'terms-of-service/ios': 'Zakup w App Store',

    'intetmittent-fasting/end-of-post':
        'Cel postu osiągnięty. Zakończ trwający post.',
    'intetmittent-fasting/next-eat-window-today':
        'Planowany koniec postu: dziś',
    'intetmittent-fasting/next-eat-window-tomorrow':
        'Planowany koniec postu: jutro',
    'intetmittent-fasting/eat-window-to-today': 'Okno żywieniowe do dzisiaj',
    'intetmittent-fasting/eat-window-to-tomorrow': 'Okno żywieniowe do jutra',

    'developer-options/api-gateway': 'API Gateway',
    'developer-options/set-api-gateway': 'Ustaw API Gateway',
    'developer-options/api-gateway-changed': 'API Gateway zostało zmienione',
    'developer-options/clear-data': 'Wyczyść dane',
    'developer-options/clear-apollo-cache': 'Wyczyść Apollo Cache',
    'developer-options/apollo-cache-cleared':
        'Apollo Cache został wyczyszczony',
    'developer-options/clear-local-storage': 'Wyczyść Local Storage',
    'developer-options/local-storage-cleared':
        'Local Storage został wyczyszczony',
    'developer-options/remove-service-worker': 'Usuń Service Worker',
    'developer-options/service-worker-removed':
        'Service Worker został wyrejestrowany',
    'developer-options/create-new-user': 'Utwórz nowego użytkownika',
    'developer-options/new-user-created': 'Nowy użytkownik został utworzony',
    'developer-options/add-access': 'Dodaj dostęp',
};
