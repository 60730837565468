import React from 'react';
import Workouts from './Workouts';
import WorkoutsConnectorApollo from './Connector/Apollo/WorkoutsConnectorApollo';

class WorkoutsPage extends React.PureComponent {
    render() {
        return (
            <WorkoutsConnectorApollo>
                {result => {
                    if (result.loading) {
                        return '';
                    }
                    return (
                        <Workouts
                            videoWorkoutAccessTo={result.videoWorkoutAccessTo}
                        />
                    );
                }}
            </WorkoutsConnectorApollo>
        );
    }
}

export default WorkoutsPage;
