import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { quizAnswersVar } from 'Apollo/ApolloClient';
import withLoading from 'Layout/withLoading';
import Layout from 'Layout/Layout';
import QuizResultPlaceholder from './Placeholders/QuizResultPlaceholder';
import { PATH_QUIZ_INTRO } from '../paths';
import QuizResultAnswer from './Components/QuizResultAnswer';
import { withLocale } from '../../../TranslatorContext';

const QuizResultContainer = ({ questions, t, questionsCount }) => {
    const [redirectToIntro, setRedirectToIntro] = useState();
    const answers = quizAnswersVar();

    useEffect(() => {
        const answersLength = Object.keys(answers).length;
        if (answersLength !== questionsCount) {
            setRedirectToIntro(true);
        }
    }, []);

    const calculateResult = () => {
        let totalCount = 0;
        let quote = '';

        questions.forEach(question => {
            question.answers.forEach(answer => {
                if (answer.isCorrect && answers[question.id] === answer.id) {
                    totalCount += 1;
                }
            });
        });

        if (totalCount <= 3) {
            quote = t('quiz/result/score0-3');
        } else if (totalCount > 3 && totalCount <= 6) {
            quote = t('quiz/result/score4-6');
        } else {
            quote = t('quiz/result/score7-10');
        }

        return {
            score: `${totalCount}/${questionsCount}`,
            quote,
        };
    };

    const renderQuestion = question => {
        return (
            <React.Fragment key={question.id}>
                <h2 data-test="quiz-result-question">{question.text}</h2>
                {question.answers.map(answer => (
                    <QuizResultAnswer
                        key={answer.id}
                        label={answer.text}
                        checked={answer.isCorrect}
                        userChoose={answers[question.id] === answer.id}
                    />
                ))}
                <hr />
            </React.Fragment>
        );
    };

    return (
        <Layout page="quiz">
            {redirectToIntro && <Redirect to={PATH_QUIZ_INTRO} />}

            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <header className="text-center">
                            <h1 className="d-none d-md-block">
                                {t('quiz/result/title')}
                            </h1>
                        </header>
                        <section className="pt-0">
                            <div className="text-center mb-5">
                                <h2>{`${t('quiz/result/your-result')} ${
                                    calculateResult().score
                                }`}</h2>

                                <Button
                                    color="primary"
                                    tag={Link}
                                    to="/doc/dania-z-moca-poradnik.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('quiz/result/button')}
                                </Button>
                            </div>

                            <blockquote className="blockquote">
                                <p className="navy">
                                    {calculateResult().quote}
                                </p>
                            </blockquote>

                            <p className="blue text-right mb-0">
                                — {t('quiz/result/sign')}
                            </p>

                            <hr />

                            <h2 className="text-center mb-5">
                                {t('quiz/result/your-answer')}
                            </h2>
                            {questions &&
                                questions.map(question =>
                                    renderQuestion(question)
                                )}

                            <div className="text-center">
                                <h2>{t('quiz/result/get-your-guide')}</h2>
                                <Button
                                    color="primary"
                                    tag={Link}
                                    to="/doc/dania-z-moca-poradnik.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('quiz/result/open-guide')}
                                </Button>
                            </div>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

QuizResultContainer.propTypes = {
    t: PropTypes.func.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    questionsCount: PropTypes.number.isRequired,
};

export default withLoading(
    withLocale(withRouter(QuizResultContainer)),
    QuizResultPlaceholder
);
