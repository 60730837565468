import React from 'react';
import { sha256 } from 'js-sha256';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

export class ShoppingCartThankYouComponent extends React.Component {
    render() {
        return (
            <Layout page="thnk-you">
                {/* div for marketing analitycs */}
                <div id="conversion_hash" className="d-none">
                    {sha256(this.props.userEmail)}
                </div>
                <section className="hero" />
                {this.props.children}
            </Layout>
        );
    }
}

ShoppingCartThankYouComponent.propTypes = {
    userEmail: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default withLocale(ShoppingCartThankYouComponent);
