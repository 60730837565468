import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { quizAnswersVar } from 'Apollo/ApolloClient';
import { useReactiveVar } from '@apollo/client';
import reducer, { ACTIONS } from './Reducer/Reducer';

export const QuizContext = createContext();

const QuestionsProvider = ({ children, numberOfQuestions }) => {
    const answers = useReactiveVar(quizAnswersVar);
    const [state, dispatch] = useReducer(reducer, {
        currentQuestionIndex: 0,
        isNextBtnActive: false,
    });

    const checkIfThereIsAnAnswer = questionId => {
        return answers[questionId];
    };

    const handleDisableNextBtn = disable => {
        if (disable) {
            dispatch({
                type: ACTIONS.DISABLE_NEXT_BTN,
            });
        } else {
            dispatch({
                type: ACTIONS.ENABLE_NEXT_BTN,
            });
        }
    };

    const nextQuestion = () => {
        dispatch({ type: ACTIONS.NEXT_QUESTION });
    };

    const prevQuestion = () => {
        dispatch({ type: ACTIONS.PREV_QUESTION });
    };

    const selectAnswer = (questionId, answerId) => {
        const currentAnswers = quizAnswersVar();

        quizAnswersVar({
            ...currentAnswers,
            [questionId]: answerId,
        });
    };

    const checkIfThereIsSelected = (questionId, answerId) => {
        return answers[questionId] === answerId;
    };

    const value = {
        actions: {
            nextQuestion,
            prevQuestion,
            selectAnswer,
            handleDisableNextBtn,
        },
        utils: {
            checkIfThereIsSelected,
            checkIfThereIsAnAnswer,
        },
        state: {
            ...state,
            numberOfQuestions,
        },
    };

    return (
        <QuizContext.Provider value={value}>{children}</QuizContext.Provider>
    );
};

QuestionsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    numberOfQuestions: PropTypes.number,
};

QuestionsProvider.defaultProps = {
    numberOfQuestions: 0,
};

export default QuestionsProvider;
