const config = {
    apiGateway: {
        url: process.env.REACT_APP_GRAPHQL_URL,
    },
    availableDiets: {
        DIET_ID_VEGE: 10,
        DIET_ID_CLASSIC: 1,
        DIET_ID_CLASSIC_LVL2: 2,
        DIET_ID_CLASSIC_LVL3: 3,
        DIET_ID_CLASSIC_SPORT: 5,
        DIET_ID_CLASSIC_LVL2_SPORT: 6,
        DIET_ID_CLASSIC_LVL3_SPORT: 7,
        DIET_ID_LOW_LG: 11,
        DIET_ID_SMART: 12,
    },
    flags: {
        activities: true,
        cart: true,
        shoppingListActions: true,
        termsOfService: {
            web: true,
            android: true,
            ios: true,
            huawei: true,
        },
    },
};

export default config;
export const { flags, availableDiets } = config;
