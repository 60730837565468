import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_CONVERSION } from 'view/GoogleTagManager/EventCategories';
import ShoppingCartThankYouServiceActivationComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouServiceActivationComponent';
// import ShoppingCartThankYouStartUsingComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouStartUsingComponent';
import ShoppingCartThankYouContentComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouContentComponent';
import ShoppingCartThankYouComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouComponent';
import ShoppingCartThankYouPlaceholderComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouPlaceholderComponent';
import { PATHS } from 'config/paths';
import thic from 'assets/images/thankyou/thic.svg';

class ShoppingCartThankYouContainer extends Component {
    static propTypes = {
        reportPayment: PropTypes.func.isRequired,
        authLoginByToken: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
        GTM: PropTypes.shape({
            api: PropTypes.shape({
                trigger: PropTypes.func.isRequired,
                gtm: PropTypes.shape({
                    push: PropTypes.func.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    };

    state = {
        paymentData: null,
        redirectTo: null,
    };

    report = () => {
        const paymentOperatorData =
            global && global.location && global.location.search
                ? global.location.search
                : '';

        this.props
            .reportPayment(this.props.token, paymentOperatorData)
            .then(paymentData => {
                if (paymentData === null) {
                    window.location = PATHS.EXTERNAL.HOME;
                    this.setState({ paymentData });
                } else if (paymentData.redirectToPaymentCancelPage) {
                    const redirectTo =
                        PATHS.CART.CONFIRM.split(':')[0] + this.props.token;
                    this.setState({ redirectTo });
                } else if (paymentData.user.authToken) {
                    this.props.authLoginByToken(paymentData.user.authToken);
                    this.setState({ paymentData });
                } else {
                    this.setState({ paymentData });
                }
            });
    };

    componentDidMount() {
        this.report();
    }

    componentDidUpdate(prevProps, prevState) {
        const { reportData: prevReportData } = prevState.paymentData || {};
        const { reportData } = this.state.paymentData || {};
        if (
            reportData &&
            reportData.length &&
            JSON.stringify(reportData) !== JSON.stringify(prevReportData)
        ) {
            reportData.forEach(data => this.props.GTM.api.gtm.push(data));
            this.props.GTM.api.trigger({
                event: EVENT_CATEGORY_CONVERSION,
            });
        }
    }

    render() {
        if (this.state.paymentData === null) {
            if (this.state.redirectTo) {
                return <Redirect to={this.state.redirectTo} />;
            }
            return <ShoppingCartThankYouPlaceholderComponent />;
        }

        const { containsPhysicalProduct, isCod } = this.state.paymentData;

        return (
            <ShoppingCartThankYouComponent
                userEmail={this.state.paymentData.user.email}
            >
                <h5 className="mt-4">
                    <img src={thic} className="ok" alt="" />
                    <span className="green">Dziękujemy za zamówienie</span>
                </h5>
                {isCod || containsPhysicalProduct ? (
                    <ShoppingCartThankYouServiceActivationComponent />
                ) : (
                    ''
                )}
                <ShoppingCartThankYouContentComponent
                    extraOffset={!isCod && !containsPhysicalProduct}
                />
            </ShoppingCartThankYouComponent>
        );
    }
}

export { ShoppingCartThankYouContainer };
export default withGTM(ShoppingCartThankYouContainer);
