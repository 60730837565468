import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { withLocale } from '@dietlabs/components';
import { reportError } from '@dietlabs/utils';

import CachedDataMessage from '@dietlabs/components/src/CachedDataMessage/CachedDataMessage';
import DayPlan from '../../components/DayPlan';
import DayPlanSummary from '../../components/DayPlanSummary';
import Meal from '../../components/DayPlanMeal';
import Training from '../../components/DayPlanTraining';
import Dish from '../../components/DayPlanDish';
import IngredientList from '../../components/DayPlanIngredientList';
import Ingredient from '../../components/DayPlanIngredient';
import IngredientReplacementList from '../../components/DayPlanIngredientReplacementList';
import IngredientReplacement from '../../components/DayPlanIngredientReplacement';
import PrintForm from '../../components/DayPlanPrintForm';

import MealReplacementList from '../../components/DayPlanMealReplacementList';
import MealReplacement from '../../components/DayPlanMealReplacement';

import DayPlanChangeDay from '../../components/DayPlanChangeDay';
import DayReplacement from '../../components/DayPlanDayReplacement';

class DayPlanContainer extends Component {
    static propTypes = {
        renderTimeline: PropTypes.func.isRequired,
        dayPlan: PropTypes.shape({
            canBeCopiedToDate: PropTypes.bool.isRequired,
            date: PropTypes.instanceOf(Date).isRequired,
            diet: PropTypes.shape({
                id: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                macro: PropTypes.shape({
                    animalProtein: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    carbohydrates: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    fat: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    protein: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                }).isRequired,
            }),
            events: PropTypes.arrayOf(PropTypes.shape()),
        }).isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object).isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
        copyDayPlan: PropTypes.func.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        print: PropTypes.bool,
        replaceProduct: PropTypes.func.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        replaceMeal: PropTypes.func.isRequired,
        loadDayPlanDayReplacements: PropTypes.func.isRequired,
        replaceDay: PropTypes.func.isRequired,
        prefetchSurroundingDays: PropTypes.func,
        loadDayPlanForTomorrow: PropTypes.func.isRequired,
    };

    static defaultProps = {
        print: false,
        prefetchSurroundingDays: undefined,
    };

    state = {
        dayPlanForTomorrow: undefined,
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        const dayPlanForTomorrow = await this.props.loadDayPlanForTomorrow();
        this.setState({ dayPlanForTomorrow });

        if (!this.props.print) {
            this.props.prefetchSurroundingDays();
        }
    };

    componentDidUpdate = async () => {
        const dayPlanForTomorrow = await this.props.loadDayPlanForTomorrow();
        if (
            JSON.stringify(this.state.dayPlanForTomorrow) !==
            JSON.stringify(dayPlanForTomorrow)
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ dayPlanForTomorrow });
        }

        if (!this.props.print) {
            this.props.prefetchSurroundingDays();
        }
    };

    triggerLoadDayReplacements() {
        this.changeDayRef.loadDayReplacements();
    }

    renderIngredientReplacements = (
        replacements,
        productAmountId,
        productId,
        productKey,
        clickAction
    ) =>
        replacements.map(replacement => (
            <IngredientReplacement
                key={replacement.productId}
                replacementId={replacement.productId}
                name={replacement.name}
                measurements={replacement.measurements}
                replaceProduct={this.props.replaceProduct}
                productId={productId}
                productKey={productKey}
                productAmountId={productAmountId}
                closeAndScroll={clickAction.closeAndScroll}
                replaceProductSuccess={() => ''}
            />
        ));

    renderIngredientReplacementsList = (
        replacements,
        originalProductId,
        productId,
        productKey,
        original,
        productAmountId,
        clickAction
    ) => (
        <IngredientReplacementList
            shouldRenderOryginalProduct={originalProductId !== productId}
            renderOriginalProduct={() =>
                this.renderIngredientReplacements(
                    [original],
                    productAmountId,
                    productId,
                    productKey,
                    clickAction
                )
            }
            hasReplacements={replacements.length > 0}
        >
            {() =>
                this.renderIngredientReplacements(
                    replacements,
                    productAmountId,
                    productId,
                    productKey,
                    clickAction
                )
            }
        </IngredientReplacementList>
    );

    renderIngredients = ingredients =>
        ingredients.map(ingredient => (
            <Ingredient
                key={ingredient.productId}
                name={ingredient.name}
                measurements={ingredient.measurements}
                saleProductVariant={ingredient.saleProductVariant}
                hasReplacement={ingredient.hasReplacement}
                originalProductId={ingredient.original.productId}
                productId={ingredient.productId}
                productKey={ingredient.key}
                print={this.props.print}
            >
                {({ closeAndScroll }) =>
                    this.renderIngredientReplacementsList(
                        ingredient.replacements,
                        ingredient.original.productId,
                        ingredient.productId,
                        ingredient.key,
                        ingredient.original,
                        ingredient.productAmountId,
                        { closeAndScroll }
                    )
                }
            </Ingredient>
        ));

    renderIngredientList = ingredients => {
        const filteredIngredients = ingredients.filter(
            ingredient =>
                !ingredient.category.isSeasoning &&
                !ingredient.category.isOptional
        );

        const onlySeasoningsIngredients = ingredients.filter(
            ingredient => ingredient.category.isSeasoning
        );

        const optionalIngredients = ingredients.filter(
            ingredient => ingredient.category.isOptional
        );

        return (
            <IngredientList
                renderIngredients={() =>
                    this.renderIngredients(filteredIngredients)
                }
                renderOnlySeasoningsIngredients={() =>
                    this.renderIngredients(onlySeasoningsIngredients)
                }
                renderOptionalIngredients={() =>
                    this.renderIngredients(optionalIngredients)
                }
            />
        );
    };

    renderDishes = dishes =>
        dishes.map(dish => (
            <Dish
                key={dish.key}
                name={dish.name}
                recipe={dish.recipe}
                recipeNote={dish.recipeNote}
                triangleOfPower={dish.triangleOfPower}
                isFirstOccurance={dish.isFirstOccurance}
                isLastOccurance={dish.isLastOccurance}
                isPortioned={dish.isPortioned}
                portions={dish.portions}
                portionsTotal={dish.portionsTotal}
                data-test="dish-component"
            >
                {() => this.renderIngredientList(dish.ingredients)}
            </Dish>
        ));

    renderMealReplacements = (
        originalMealId,
        mealKey,
        replacements,
        clickAction
    ) =>
        replacements.map(mealReplacement => (
            <MealReplacement
                key={mealReplacement.id}
                originalMealId={originalMealId}
                mealKey={mealKey}
                mealReplacement={mealReplacement}
                replaceMeal={this.props.replaceMeal}
                closeAndScroll={clickAction.closeAndScroll}
                replaceMealSuccess={clickAction.replaceMealSuccess}
            />
        ));

    renderMealReplacementsList = (
        replacements,
        originalMealId,
        mealId,
        mealKey,
        original,
        clickAction
    ) => (
        <MealReplacementList
            originalMealId={originalMealId}
            mealId={mealId}
            renderOriginalMeal={() =>
                this.renderMealReplacements(
                    originalMealId,
                    mealKey,
                    [original],
                    clickAction
                )
            }
            replacements={replacements}
        >
            {() =>
                this.renderMealReplacements(
                    originalMealId,
                    mealKey,
                    replacements,
                    clickAction
                )
            }
        </MealReplacementList>
    );

    renderEvents = events =>
        events.map(event => {
            if (event.__typename === 'Meal') {
                return (
                    <Meal
                        key={event.key}
                        mealId={event.id}
                        preparationTime={event.preparationTime}
                        name={event.name}
                        kcal={event.kcal}
                        macro={event.macro}
                        originalId={event.original.id}
                        data-test="meal-component"
                        loadDayPlanMealReplacements={
                            this.props.loadDayPlanMealReplacements
                        }
                        renderMealReplacementsList={(
                            replacements,
                            originalMealId,
                            { closeAndScroll, replaceMealSuccess }
                        ) =>
                            this.renderMealReplacementsList(
                                replacements,
                                originalMealId,
                                event.id,
                                event.key,
                                event.original,
                                { closeAndScroll, replaceMealSuccess }
                            )
                        }
                        print={this.props.print}
                        preparationVideoUrl={event.preparationVideoUrl}
                    >
                        {() => this.renderDishes(event.dishes)}
                    </Meal>
                );
            }
            if (event.__typename === 'Training') {
                return (
                    <Training
                        key={event.__typename + event.id}
                        activities={event.activities}
                        burnedCalories={event.burnedCalories}
                        duration={event.duration}
                        data-test="training-component"
                    />
                );
            }
            reportError(
                new Error(`Unknown event typename: ${event.__typename}`)
            );
            return '';
        });

    renderSummary = (macro, canBeCopiedToDate, alreadyCopied) => (
        <DayPlanSummary
            protein={macro.protein.percentage}
            fat={macro.fat.percentage}
            carbohydrates={macro.carbohydrates.percentage}
            canBeCopiedToDate={canBeCopiedToDate}
            copyDayPlan={this.props.copyDayPlan}
            alreadyCopied={alreadyCopied}
            print={this.props.print}
            data-test="summary-component"
        >
            {() => (
                <React.Fragment>
                    {this.renderChangeDay()}
                    {this.renderPrintForm()}
                </React.Fragment>
            )}
        </DayPlanSummary>
    );

    renderDayReplacements = (replacements, search, changeDaySuccess) =>
        replacements.map(replacement => (
            <DayReplacement
                key={replacement.dietSetId}
                dayReplacement={replacement}
                search={search}
                replaceDay={this.props.replaceDay}
                changeDaySuccess={changeDaySuccess.changeDaySuccess}
            />
        ));

    renderChangeDay = () => (
        <DayPlanChangeDay
            onRef={ref => {
                this.changeDayRef = ref;
            }}
            loadDayPlanDayReplacements={this.props.loadDayPlanDayReplacements}
            renderOriginalDay={(original, search, { changeDaySuccess }) =>
                this.renderDayReplacements([original], search, {
                    changeDaySuccess,
                })
            }
            date={this.props.dayPlan.date}
        >
            {(replacements, search, { changeDaySuccess }) =>
                this.renderDayReplacements(replacements, search, {
                    changeDaySuccess,
                })
            }
        </DayPlanChangeDay>
    );

    renderPrintForm = () => (
        <PrintForm
            date={this.props.dayPlan.date}
            availableDays={this.props.availableDays}
        />
    );

    render() {
        const {
            dayPlan,
            availableDays,
            goal,
            isHolidayMenu,
            isTimeToNagForCurrentMeasurement,
            cacheHit,
            hasNetworkError,
            alreadyCopied,
            print,
        } = this.props;

        return (
            <DayPlan
                kcal={dayPlan.diet.kcal}
                date={dayPlan.date}
                goal={goal}
                isHolidayMenu={isHolidayMenu}
                isTimeToNagForCurrentMeasurement={
                    isTimeToNagForCurrentMeasurement
                }
                availableDays={availableDays}
                renderTimeline={position => this.props.renderTimeline(position)}
                print={print}
                triggerLoadDayReplacements={() =>
                    this.triggerLoadDayReplacements()
                }
                dayPlanForTomorrow={this.state.dayPlanForTomorrow}
            >
                {() => (
                    <React.Fragment>
                        <CachedDataMessage {...{ cacheHit, hasNetworkError }} />
                        {this.renderEvents(dayPlan.events)}
                        {this.renderSummary(
                            dayPlan.diet.macro,
                            dayPlan.canBeCopiedToDate,
                            alreadyCopied
                        )}
                    </React.Fragment>
                )}
            </DayPlan>
        );
    }
}

export { DayPlanContainer };
export default withRouter(withLocale(DayPlanContainer));
