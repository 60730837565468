import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ActivitiesListConnectorApollo from './Connector/Apollo/FetchActivitiesList/ActivitiesListConnectorApollo';
import ActivityAddContainer from './ActivityAddEditContainer';

class DietSettingsIndexPage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.number,
                periodic: PropTypes.bool,
            }),
        }).isRequired,
        history: PropTypes.shape({
            location: PropTypes.shape({
                state: PropTypes.shape({
                    period: PropTypes.shape(),
                }),
            }).isRequired,
        }).isRequired,
    };

    render() {
        const isPeriodic = this.props.match.params.periodic === 'periodic';

        let period;
        if (this.props.history.location.state) {
            period = this.props.history.location.state.period;
        }

        const activities = JSON.parse(
            global.localStorage.getItem('activities')
        );

        const activity = activities.filter(
            el => el.id === parseInt(this.props.match.params.id, 10)
        )[0];

        return (
            <ActivitiesListConnectorApollo>
                {({ ...activitiestListData }) => (
                    <ActivityAddContainer
                        {...activitiestListData}
                        userWeightInKg={60}
                        activity={activity}
                        edit
                        isPeriodic={isPeriodic}
                        period={period}
                    />
                )}
            </ActivitiesListConnectorApollo>
        );
    }
}

export default withRouter(DietSettingsIndexPage);
