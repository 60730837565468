import React, { Component } from 'react';
import { Row, Col, Alert, Button } from 'reactstrap';

import PropTypes from 'prop-types';

import addIcon from 'assets/icons/add.svg';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { formatMediumDate, createDateObject } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { PATH_DAY_PLAN_INDEX } from '@dietlabs/components/src/Hpba/DailyDietPlan/DayPlanPaths';
import ActivitiesTimeOfDay from './ActivitiesTimeOfDay';
import { PATH_ACTIVITIES_INDEX } from '../paths';

class ActivitiesDay extends Component {
    static propTypes = {
        activities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        periodicActivities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        date: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        refreshActivities: PropTypes.func.isRequired,
        period: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        toggleAddActivity: PropTypes.func.isRequired,
        toggleEditActivity: PropTypes.func.isRequired,
        toggleRemoveActivity: PropTypes.func.isRequired,
    };

    state = {
        dayHasActivities: false,
    };

    componentDidMount() {
        this.caloriesCount = this.calculateCalories();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.activities !== prevProps.activities ||
            this.props.periodicActivities !== prevProps.periodicActivities
        ) {
            this.caloriesCount = this.calculateCalories();
        }
    }

    calculateCalories() {
        let caloriesCount = 0;
        this.props.activities.forEach(activity => {
            if (activity.date === this.props.date) {
                caloriesCount += activity.burnedCalories;
            }
        });

        if (caloriesCount > 0) {
            this.setState({ dayHasActivities: true });
        } else {
            this.setState({ dayHasActivities: false });
        }

        return caloriesCount;
    }

    renderDate() {
        return formatMediumDate(createDateObject(this.props.date));
    }

    render() {
        let errorMgs;

        // if (this.props.errors) {
        //     this.props.errors.details.forEach(error => {
        //         if (error.fieldName === `activities[${this.props.date}]`) {
        //             errorMgs = error.messages;
        //         }
        //     });
        // }

        return (
            <div className="white-box">
                <Row className="align-items-center">
                    <Col xs="9">
                        <h4>{this.renderDate()}</h4>

                        {this.state.dayHasActivities ? (
                            <h4 className="pink">
                                {this.props.t('activities/total')}
                                {this.caloriesCount}
                                &nbsp;kcal{' '}
                            </h4>
                        ) : (
                            <p className="mb-0">
                                {this.props.t('activities/add-activity')}
                            </p>
                        )}
                    </Col>

                    {this.props.location.pathname === PATH_ACTIVITIES_INDEX ? (
                        <Col xs="3" className="text-right">
                            <Button
                                color="link"
                                className="p-0"
                                onClick={() =>
                                    this.props.toggleAddActivity(
                                        this.props.date
                                    )
                                }
                            >
                                <img src={addIcon} alt="" />
                            </Button>
                        </Col>
                    ) : (
                        <Col xs="3" className="text-right">
                            <p className="mb-0">
                                <Link
                                    className="grey"
                                    to={`${PATH_DAY_PLAN_INDEX}/${this.props.date}`}
                                >
                                    {this.props.t('activities/diet-plan')}
                                </Link>
                            </p>
                        </Col>
                    )}
                </Row>

                {this.state.dayHasActivities ? <hr /> : ''}

                <ActivitiesTimeOfDay
                    activities={this.props.activities}
                    periodicActivities={this.props.periodicActivities}
                    date={this.props.date}
                    timeOfDay="before_breakfast"
                    refreshActivities={this.props.refreshActivities}
                    period={this.props.period}
                    displayActions={
                        this.props.location.pathname === PATH_ACTIVITIES_INDEX
                    }
                    toggleEditActivity={activityId =>
                        this.props.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.props.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />

                <ActivitiesTimeOfDay
                    activities={this.props.activities}
                    periodicActivities={this.props.periodicActivities}
                    date={this.props.date}
                    timeOfDay="after_breakfast"
                    refreshActivities={this.props.refreshActivities}
                    period={this.props.period}
                    displayActions={
                        this.props.location.pathname === PATH_ACTIVITIES_INDEX
                    }
                    toggleEditActivity={activityId =>
                        this.props.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.props.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />

                <ActivitiesTimeOfDay
                    activities={this.props.activities}
                    periodicActivities={this.props.periodicActivities}
                    date={this.props.date}
                    timeOfDay="before_dinner"
                    refreshActivities={this.props.refreshActivities}
                    period={this.props.period}
                    displayActions={
                        this.props.location.pathname === PATH_ACTIVITIES_INDEX
                    }
                    toggleEditActivity={activityId =>
                        this.props.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.props.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />

                <ActivitiesTimeOfDay
                    activities={this.props.activities}
                    periodicActivities={this.props.periodicActivities}
                    date={this.props.date}
                    timeOfDay="after_dinner"
                    refreshActivities={this.props.refreshActivities}
                    period={this.props.period}
                    displayActions={
                        this.props.location.pathname === PATH_ACTIVITIES_INDEX
                    }
                    toggleEditActivity={activityId =>
                        this.props.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.props.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />

                <ActivitiesTimeOfDay
                    activities={this.props.activities}
                    periodicActivities={this.props.periodicActivities}
                    date={this.props.date}
                    timeOfDay="before_supper"
                    refreshActivities={this.props.refreshActivities}
                    period={this.props.period}
                    displayActions={
                        this.props.location.pathname === PATH_ACTIVITIES_INDEX
                    }
                    toggleEditActivity={activityId =>
                        this.props.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.props.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />

                <ActivitiesTimeOfDay
                    activities={this.props.activities}
                    periodicActivities={this.props.periodicActivities}
                    date={this.props.date}
                    timeOfDay="after_supper"
                    refreshActivities={this.props.refreshActivities}
                    period={this.props.period}
                    displayActions={
                        this.props.location.pathname === PATH_ACTIVITIES_INDEX
                    }
                    toggleEditActivity={activityId =>
                        this.props.toggleEditActivity(activityId)
                    }
                    toggleRemoveActivity={(activityId, activityPeriodicId) =>
                        this.props.toggleRemoveActivity(
                            activityId,
                            activityPeriodicId
                        )
                    }
                />

                {errorMgs ? (
                    <Alert color="danger">
                        {errorMgs.map(message => (
                            <li>{message}</li>
                        ))}
                    </Alert>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default withRouter(withLocale(ActivitiesDay));
