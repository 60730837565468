import { useEffect } from 'react';
import queryString from 'query-string';
import { setLocalStorageItem } from './PromoCodeLocalStorage';

const PromoCodeHandlerComponent = () => {
    const getFromQueryString = () => {
        const queryStringObject = queryString.parse(global.location.search);
        const promoCode = queryStringObject.pc;
        if (promoCode) {
            return promoCode;
        }
        return null;
    };

    useEffect(() => {
        const promoCode = getFromQueryString();
        if (promoCode != null) {
            setLocalStorageItem(promoCode);
        }
    }, []);

    return '';
};

export default PromoCodeHandlerComponent;
