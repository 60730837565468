import React from 'react';
import { Container, Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import withOnCritialError from 'view/ErrorBoundary/withOnCriticalError';
import { PATH_QUIZ_INTRO } from '@dietlabs/components/src/Hpba/Quiz/paths';
import mind from 'assets/images/mind.png';

export class ExitupComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false };
        this.toggle = this.toggle.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    toggle() {
        if (global && global.localStorage) {
            localStorage.setItem('exitup', !this.state.modal);
        }

        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    showExitup() {
        this.setState({ modal: true });
    }

    handleMouseOut(event) {
        try {
            let exitup = true;

            // check local storage
            if (
                window &&
                window.localStorage &&
                window.localStorage.getItem('exitup')
            ) {
                exitup = window.localStorage.getItem('exitup') === 'true';
            }

            // check resolution
            const w =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            if (w < 576) {
                if (this.state.modal) {
                    this.setState({ modal: false });
                }
                exitup = false;
            }

            if (exitup) {
                const MIN_DELAY = 3000;
                const from = event.relatedTarget || event.toElement;

                if (!from || from.nodeName === 'HTML') {
                    if (
                        new Date() - this.props.firstRenderTimestamp >
                        MIN_DELAY
                    ) {
                        this.showExitup();
                    }
                }
            }
        } catch (error) {
            this.props.handleCriticalError(error);
        }
    }

    componentDidMount() {
        document.addEventListener('mouseout', this.handleMouseOut);
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                    className="d-none d-sm-flex"
                >
                    <ModalBody>
                        <Container fluid>
                            <Row className="align-items-center">
                                <Col sm="6" className="pl-0">
                                    <img src={mind} className="img-fluid" />
                                </Col>
                                <Col sm="6" className="py-3">
                                    <h3>{this.props.t('exitup/title')}</h3>
                                    <br />
                                    <p>{this.props.t('exitup/content')}</p>

                                    <Button
                                        className="mt-3"
                                        color="primary"
                                        onClick={this.toggle}
                                        tag={Link}
                                        to={PATH_QUIZ_INTRO}
                                        data-test="exitup-button"
                                    >
                                        {this.props.t('exitup/button')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <button
                            type="button"
                            className="close"
                            onClick={this.toggle}
                        >
                            &times;
                        </button>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export { ExitupComponent as unwrapped };

export default withOnCritialError(withLocale(ExitupComponent));
