import React from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import QuizQuestionAnswerComponent from './QuizQuestionAnswerComponent';

const QuizQuestionComponent = props => {
    return (
        <FormGroup tag="fieldset">
            <h3 className="text-center">{props.text}</h3>
            {props.answers.map(answer => (
                <QuizQuestionAnswerComponent
                    key={answer.id}
                    answer={answer}
                    questionId={parseInt(props.id, 10)}
                />
            ))}
        </FormGroup>
    );
};

QuizQuestionComponent.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};

export default QuizQuestionComponent;
