import React from 'react';
import FetchQuestionsConnectorApolo from '../Index/Connector/Apollo/FetchQuestionsConnectorApolo';
import QuizResultContainer from './QuizResultContainer';

const QuizResultPage = () => {
    return (
        <FetchQuestionsConnectorApolo>
            {result => <QuizResultContainer {...result} />}
        </FetchQuestionsConnectorApolo>
    );
};

export default QuizResultPage;
