import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import phoneIcon from 'assets/icons/phone_icon.svg';
import deliveryIcon from 'assets/icons/delivery_icon.svg';
import emailIcon from 'assets/icons/email_icon.svg';

export class ShoppingCartThankYouServiceActivationComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section data-test="thank-you/how-to-activate">
                    <Container>
                        <React.Fragment>
                            <Row>
                                <Col md="5">
                                    <h2 className="text-center text-md-left mb-5">
                                        {this.props.t(
                                            'thank-you/how-to-activate-the-service'
                                        )}
                                    </h2>
                                    <div className="d-flex mb-5">
                                        <img
                                            src={emailIcon}
                                            className="mr-4"
                                            alt=""
                                        />
                                        <p className="mb-0">
                                            {this.props.t('thank-you/step1')}
                                        </p>
                                    </div>
                                    <div className="d-flex mb-5">
                                        <img
                                            src={deliveryIcon}
                                            className="mr-4"
                                            alt=""
                                        />
                                        <p className="mb-0">
                                            {this.props.t('thank-you/step2')}
                                        </p>
                                    </div>
                                    <div className="d-flex mb-5">
                                        <img
                                            src={phoneIcon}
                                            className="mr-4"
                                            alt=""
                                        />
                                        <p className="mb-0">
                                            {this.props.t('thank-you/step3')}
                                        </p>
                                    </div>
                                </Col>
                                <Col
                                    md={{ size: 5, offset: 2 }}
                                    data-test="thank-you/flyer"
                                >
                                    <p className="text-center navy mb-3">
                                        {this.props.t(
                                            'thank-you/activation-code-and-instruction'
                                        )}
                                    </p>

                                    <div className="flyer">
                                        <h2 className="text-center">
                                            {this.props.t(
                                                'thank-you/flyer-header'
                                            )}
                                        </h2>
                                        <p className="navy text-center text-md-left">
                                            {this.props.t(
                                                'thank-you/flyer-content1'
                                            )}
                                        </p>
                                        <p className="navy text-center text-md-left">
                                            {this.props.t(
                                                'thank-you/flyer-content2'
                                            )}
                                        </p>
                                        <p className="blue text-right mb-0">
                                            {this.props.t(
                                                'thank-you/flyer-content3'
                                            )}
                                        </p>
                                    </div>

                                    <p className="text-center navy mb-2">
                                        {this.props.t(
                                            'thank-you/flyer-footer1'
                                        )}
                                        <a href="/aktywacja">
                                            dieta.hpba.pl/aktywacja
                                        </a>
                                        {this.props.t(
                                            'thank-you/flyer-footer2'
                                        )}
                                    </p>

                                    <p className="text-center navy mb-0">
                                        XXX-XXX-XXX
                                    </p>
                                </Col>
                            </Row>
                        </React.Fragment>
                    </Container>
                </section>
                <Container>
                    <hr className="m-0" />
                </Container>
            </React.Fragment>
        );
    }
}

ShoppingCartThankYouServiceActivationComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartThankYouServiceActivationComponent);
