import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    FormGroup,
    Alert,
} from 'reactstrap';

import InputEmail from 'components/FormElements/InputEmailControlled';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router';
import Layout from 'Layout/Layout';
import { quizAnswersVar } from 'Apollo/ApolloClient';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import Loader from 'Layout/Loader';

import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { validateField } from 'view/Validation/validateField';
import FetchQuestionsQuery from '../Index/Connector/Apollo/FetchQuestionsQuery';
import { withLocale } from '../../../TranslatorContext';
import { PATH_QUIZ_INTRO, PATH_QUIZ_RESULT } from '../paths';

const QuizReceivePrizeContainer = ({ t, sendQuiz, loading }) => {
    const answers = useReactiveVar(quizAnswersVar);
    const client = useApolloClient();

    const [redirectToIntro, setRedirectToIntro] = useState();
    const [email, setEmail] = useState();
    const [errors, setErrors] = useState(new ValidationErrors());
    const [sendResultError, setSendResultError] = useState(false);
    const [redirectToResult, setRedirectToResult] = useState(false);

    useEffect(() => {
        const questions = client.readQuery({
            query: FetchQuestionsQuery,
        });

        if (!questions) {
            setRedirectToIntro(true);
        }
    }, []);

    const validationRules = {
        email: [new RequiredRule({ translator: t })],
    };

    const handleInputChange = event => {
        setEmail(event.target.value);

        if (validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            setErrors({
                ...errors,
                details: validateField(field, value, validationRules[field], {
                    errors,
                }),
            });
        }
    };

    const handleSendQuiz = async event => {
        event.preventDefault();

        const response = await sendQuiz(email, answers);

        if (response.data.quiz.send.code === 200) {
            setSendResultError(false);
            setRedirectToResult(true);
        } else {
            setSendResultError(true);
        }
    };

    return (
        <Layout page="quiz">
            {redirectToIntro && <Redirect to={PATH_QUIZ_INTRO} />}
            {redirectToResult && <Redirect to={PATH_QUIZ_RESULT} />}

            {loading && <Loader fixed="no" />}

            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <header className="text-center">
                            <h1 className="d-none d-md-block">
                                {t('quiz/receive-prize/title')}
                            </h1>
                        </header>
                        <section className="pt-0">
                            <Row>
                                <Col sm="6">
                                    <h2>
                                        {t('quiz/receive-prize/leave-email')}
                                    </h2>
                                    <ul className="list">
                                        <li>
                                            {t('quiz/receive-prize/argument1')}
                                        </li>
                                        <li>
                                            {t('quiz/receive-prize/argument2')}
                                        </li>
                                    </ul>
                                </Col>
                                <Col sm="6">
                                    {sendResultError ? (
                                        <Alert color="danger">
                                            {t('quiz/send-quiz/error-message')}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                    <Form
                                        onSubmit={event =>
                                            handleSendQuiz(event)
                                        }
                                        data-test="quiz-receive-prize-form"
                                    >
                                        <FormGroup>
                                            <InputEmail
                                                label={t('email')}
                                                name="email"
                                                value={email}
                                                errors={errors}
                                                handleChange={handleInputChange}
                                                validationRules={
                                                    validationRules.email
                                                }
                                            />
                                        </FormGroup>
                                        <Button
                                            color="primary"
                                            className="w-100"
                                        >
                                            {t('quiz/receive-prize/button')}
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};
QuizReceivePrizeContainer.propTypes = {
    t: PropTypes.func.isRequired,
    sendQuiz: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withLocale(withRouter(QuizReceivePrizeContainer));
