import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Loader from 'Layout/Loader';
import { PATHS } from 'config/paths';
import { SHOPPING_CART_PAYMENT_MAKE_SUCCESS } from 'view/ShoppingCart/Confirm/Payment/Make/ShoppingCartPaymentMakeActionType';
import { reportError } from '@dietlabs/utils';
import scrollToElement from 'components/Helpers/scrollToElement';

export class ShoppingCartConfirmPaymentCodComponent extends React.Component {
    constructor(props) {
        super(props);

        this.scrollAnchorRef = React.createRef();
    }

    state = {
        preload: false,
        errorOccured: false,
        redirectTo: null,
    };

    confirm = async () => {
        this.setState({ preload: true });
        try {
            const response = await this.props.make(this.props.token);
            this.setState({
                preload: false,
            });
            if (response.type === SHOPPING_CART_PAYMENT_MAKE_SUCCESS) {
                this.setState({
                    redirectTo: PATHS.CART.THANK_YOU.replace(
                        ':token',
                        this.props.token
                    ),
                });
            } else {
                this.setState({ preload: false, errorOccured: true });
                scrollToElement(this.scrollAnchorRef.current);
            }
        } catch (error) {
            reportError(new Error(`Payment error`));
            this.setState({ preload: false, errorOccured: true });
            scrollToElement(this.scrollAnchorRef.current);
        }
    };

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />;
        }

        return (
            <section className="pb-0" ref={this.scrollAnchorRef}>
                <Container>
                    {this.state.errorOccured ? (
                        <Alert color="danger" className="mt-3 mb-0">
                            {this.props.t('cart/payment-cod/error-occured')}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <Row>
                        <Col
                            md={{ size: 4, offset: 4 }}
                            className="text-center"
                        >
                            {this.props.isAbleToProcess ? (
                                <Button
                                    color="primary"
                                    data-test="confirm-payment-button"
                                    className="w-100"
                                    onClick={this.confirm}
                                >
                                    {this.props.t('cart/confirm-and-order')}
                                </Button>
                            ) : (
                                <Alert color="success">
                                    {this.props.t('cart/order-is-pending')}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Container>
                {this.state.preload ? <Loader /> : ''}
            </section>
        );
    }
}

ShoppingCartConfirmPaymentCodComponent.propTypes = {
    isAbleToProcess: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    make: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartConfirmPaymentCodComponent);
