import { connect } from 'react-redux';
import withGTM from 'view/GoogleTagManager/withGTM';
import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import ShoppingCartIndexComponent from 'components/ShoppingCart/Index/ShoppingCartIndexComponent';
import { shoppingCartIndexGet } from 'view/ShoppingCart/Index/Load/ShoppingCartIndexGet';
import { shoppingCartIndexSaveAction } from 'view/ShoppingCart/Index/Save/ShoppingCartIndexSaveAction';
import { shoppingCartProductSaveAction } from 'view/ShoppingCart/Product/Save/ShoppingCartProductSaveAction';
import ShoppingCartIndexSaveOrderFactory from 'view/ShoppingCart/Index/Save/Order/ShoppingCartIndexSaveOrderFactory';
import { getLocalStorageItem } from 'PromoCode/PromoCodeLocalStorage';

const mapStateToProps = state => {
    const areThereSomeProductsInCart = ShoppingCartProductsFactory.createFromState(
        state
    ).areThereSomeProducts;
    const order = ShoppingCartIndexSaveOrderFactory.createFromState(state);

    return {
        order,
        areThereSomeProductsInCart,
        token: state.shoppingCart.token,
        acceptConditions: state.shoppingCart.userData.acceptConditions,
        email: state.shoppingCart.userData.email,
    };
};

const mapDispatchToProps = dispatch => ({
    load: () => {
        const promoCode = getLocalStorageItem();
        return dispatch(shoppingCartIndexGet(promoCode));
    },
    saveCart: () => dispatch(shoppingCartIndexSaveAction()),
    saveCartProducts: () => dispatch(shoppingCartProductSaveAction()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { acceptConditions, email, ...otherStateProps } = stateProps;
    const { saveCart, ...otherDispatchProps } = dispatchProps;
    return {
        ...otherStateProps,
        ...otherDispatchProps,
        saveCart: () => {
            if (acceptConditions && email) {
                ownProps.GTM.api.setDataLayer({
                    event: 'new e-mail',
                    'e-mail': email,
                });
            }

            return saveCart();
        },
    };
};

const ShoppingCartIndexContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ShoppingCartIndexComponent);

export { ShoppingCartIndexContainer };
export default withGTM(ShoppingCartIndexContainer);
