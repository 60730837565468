import React from 'react';
import FetchQuestionsConnectorApolo from './Connector/Apollo/FetchQuestionsConnectorApolo';
import QuizIndexContainer from './QuizIndexContainer';
import QuestionsProvider from './QuestionsProvider';

const QuizIndexPage = () => {
    return (
        <FetchQuestionsConnectorApolo>
            {result => (
                <QuestionsProvider numberOfQuestions={result.questionsCount}>
                    <QuizIndexContainer {...result} />
                </QuestionsProvider>
            )}
        </FetchQuestionsConnectorApolo>
    );
};

export default QuizIndexPage;
