/* eslint-disable no-case-declarations */
export const ACTIONS = {
    NEXT_QUESTION: 'next_question',
    PREV_QUESTION: 'prev_question',
    SELECT_ANSWER: 'select_answer',
    DISABLE_NEXT_BTN: 'disable_next_btn',
    ENABLE_NEXT_BTN: 'enable_next_btn',
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.NEXT_QUESTION:
            return {
                ...state,
                currentQuestionIndex: state.currentQuestionIndex + 1,
            };
        case ACTIONS.PREV_QUESTION:
            return {
                ...state,
                currentQuestionIndex: state.currentQuestionIndex - 1,
            };
        case ACTIONS.SELECT_ANSWER:
            const { questionId, answerId } = action.payload;

            return {
                ...state,
                answers: {
                    ...state.answers,
                    [questionId]: answerId,
                },
            };
        case ACTIONS.DISABLE_NEXT_BTN:
            return {
                ...state,
                isNextBtnActive: false,
            };
        case ACTIONS.ENABLE_NEXT_BTN:
            return {
                ...state,
                isNextBtnActive: true,
            };
        default:
            return state;
    }
};

export default reducer;
