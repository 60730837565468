import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import { QuizContext } from '../QuestionsProvider';

const QuizQuestionAnswerComponent = props => {
    const [isSelected, setIsSelected] = useState();
    const { actions, utils } = useContext(QuizContext);

    const handleInputChange = () => {
        actions.selectAnswer(props.questionId, props.answer.id);
    };

    useEffect(() => {
        setIsSelected(
            utils.checkIfThereIsSelected(props.questionId, props.answer.id)
        );
    }, [handleInputChange]);

    useEffect(() => {
        const isAnswer = utils.checkIfThereIsAnAnswer(props.questionId);

        actions.handleDisableNextBtn(!isAnswer);
    }, [isSelected]);

    return (
        <React.Fragment>
            <InputRadio
                label={props.answer.text}
                value={props.answer.id}
                name={`question[${props.questionId}]`}
                handleChange={handleInputChange}
                checked={isSelected}
                data-test="diet-id-classic-input"
                rounded
                className="panel"
            />
        </React.Fragment>
    );
};

QuizQuestionAnswerComponent.propTypes = {
    // selectAnswer: PropTypes.func.isRequired,
    questionId: PropTypes.number.isRequired,
    answer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    // isSelected: PropTypes.bool.isRequired,
};

export default QuizQuestionAnswerComponent;
