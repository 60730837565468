import Balance from './Balance';

const PATH_BALANCE = '/balance';

const routes = [
    {
        path: PATH_BALANCE,
        component: Balance,
        private: true,
        exact: true,
        title: 'workouts/title',
    },
];

export { PATH_BALANCE, routes };
