import React, { Component } from 'react';
import { CardBody, Card } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

class DayPlanMealReplacementList extends Component {
    static propTypes = {
        originalMealId: PropTypes.number.isRequired,
        mealId: PropTypes.number.isRequired,
        children: PropTypes.func.isRequired,
        renderOriginalMeal: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        replacements: PropTypes.arrayOf(PropTypes.shape()),
    };

    static defaultProps = {
        replacements: null,
    };

    render() {
        const { originalMealId, mealId, replacements } = this.props;

        return (
            <Card>
                <CardBody>
                    {originalMealId !== mealId ? (
                        <React.Fragment>
                            {this.props.t('meal/original-meal')}:
                            <ul className="ingredients">
                                {this.props.renderOriginalMeal()}
                            </ul>
                            <hr />
                        </React.Fragment>
                    ) : (
                        ''
                    )}

                    {replacements && replacements.length > 0 ? (
                        <React.Fragment>
                            {this.props.t('meal/exchange-for')}:
                            <ul className="ingredients">
                                {this.props.children()}
                            </ul>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {this.props.t('meal/there-are-no-replacements')}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        );
    }
}

export { DayPlanMealReplacementList };
export default withLocale(DayPlanMealReplacementList);
