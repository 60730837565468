import gql from 'graphql-tag';
import * as types from 'DayPlan/DayPlanTypes';

const query = gql`
    query DayPlan($date: Date!, $canBeCopiedToDate: Date!) {
        me {
            id
            goal {
                reachedBecauseOfLoseWeight
                reachedBecauseOfPutOnWeight
                lostBecauseOfLoseWeight
                lostBecauseOfPutOnWeight
            }
            dayPlan(date: $date) {
                __typename
                ... on ${types.UNAUTHORIZED} {
                    code
                }
                ... on ${types.DAY_REDIRECT} {
                    date
                }
                ... on ${types.OUTSIDE_ACCESS} {
                    __typename
                }
                ... on ${types.PAYMENT_PENDING} {
                    token
                    description
                    originatesFromWeb
                }
                ... on ${types.DIET_IN_FUTURE} {
                    date
                }
                ... on ${types.DAY_PLAN} {
                    id
                    date
                    canBeCopiedToDate(targetDate: $canBeCopiedToDate)
                    events {
                        __typename
                        ... on Meal {
                            name
                            id
                            key
                            original {
                                id
                                dishes {
                                    id
                                    name
                                }
                            }
                            kcal
                            preparationVideoUrl
                            macro {
                                protein {
                                    grams
                                    kcal
                                    percentage
                                }
                                animalProtein {
                                    grams
                                    kcal
                                    percentage
                                }
                                fat {
                                    grams
                                    kcal
                                    percentage
                                }
                                carbohydrates {
                                    grams
                                    kcal
                                    percentage
                                }
                            }
                            preparationTime {
                                years
                                months
                                days
                                hours
                                minutes
                                seconds
                            }
                            dishes {
                                id
                                key
                                name
                                recipe
                                recipeNote
                                hasReplacement
                                isPortioned
                                portions
                                portionsTotal
                                isFirstOccurance
                                isLastOccurance
                                triangleOfPower {
                                    body
                                    mind
                                    libido
                                }
                                ingredients {
                                    key
                                    productAmountId
                                    productId
                                    name
                                    hasReplacement
                                    category {
                                        id
                                        isSeasoning
                                        isOptional
                                        name
                                    }
                                    measurements {
                                        amount
                                        unit
                                        text
                                    }
                                    original {
                                        productId
                                        name
                                        measurements {
                                            amount
                                            unit
                                            text
                                        }
                                    }
                                    replacements {
                                        productId
                                        name
                                        measurements {
                                            amount
                                            unit
                                            text
                                        }
                                    }
                                    saleProductVariant {
                                        link
                                    }
                                }
                            }
                        }
                        ... on Training {
                            duration
                            burnedCalories
                            activities {
                                duration
                                timeOfDay
                                burnedCalories
                                type {
                                    fullName
                                }
                            }
                        }
                    }
                    diet {
                        id
                        name
                        kcal
                        macro {
                            protein {
                                grams
                                kcal
                                percentage
                            }
                            animalProtein {
                                grams
                                kcal
                                percentage
                            }
                            fat {
                                grams
                                kcal
                                percentage
                            }
                            carbohydrates {
                                grams
                                kcal
                                percentage
                            }
                        }
                    }
                }
            }
            diet {
                availableDays {
                    date
                    holiday {
                        identifier
                        name
                        description
                        icon
                    }
                }
                accessTo
                nextDaySet: set(date: $canBeCopiedToDate) {
                    ... on UserDietSet {
                        dietSetId
                    }
                }
                set(date: $date) {
                    ... on UserDietSet {
                        dietSetId
                        holiday {
                            identifier
                            name
                            description
                            icon
                        }
                    }
                }
            }
            activeSubscriptions {
                givesAccessToDiet
            }
            isTimeToNagForCurrentMeasurement
        }
    }
`;

export default query;
