import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import { getLocalStorageItem } from 'PromoCode/PromoCodeLocalStorage';
import ShoppingCartProductOrder from './ShoppingCartProductOrder';

export default class ShoppingCartProductOrderFactory {
    static createFromState(state) {
        const products = ShoppingCartProductsFactory.createFromState(state)
            .products;

        const promoCode = getLocalStorageItem();

        return new ShoppingCartProductOrder(
            state.shoppingCart.token,
            state.shoppingCart.firstSource,
            products,
            promoCode || ''
        );
    }
}
