import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ActivitiesListConnectorApollo from './Connector/Apollo/FetchActivitiesList/ActivitiesListConnectorApollo';
import ActivityAddContainer from './ActivityAddEditContainer';

class DietSettingsIndexPage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                date: PropTypes.string,
                periodic: PropTypes.bool,
            }),
        }).isRequired,
        history: PropTypes.shape({
            location: PropTypes.shape({
                state: PropTypes.shape({
                    period: PropTypes.shape(),
                }),
            }).isRequired,
        }).isRequired,
    };

    render() {
        const isPeriodic = this.props.match.params.periodic === 'periodic';

        let period;
        if (this.props.history.location.state) {
            period = this.props.history.location.state.period;
        }

        return (
            <ActivitiesListConnectorApollo>
                {({ ...activitiestListData }) => (
                    <ActivityAddContainer
                        {...activitiestListData}
                        userWeightInKg={60}
                        date={this.props.match.params.date}
                        isPeriodic={isPeriodic}
                        period={period}
                    />
                )}
            </ActivitiesListConnectorApollo>
        );
    }
}

export default withRouter(DietSettingsIndexPage);
