import { PATHS } from '../paths';
// import { PATH_KNOWLEDGE } from '../../Knowledge/paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATHS.EXTERNAL.TRAININGS,
        externalUrl: PATHS.EXTERNAL.TRAININGS,
        translationKey: 'menu/trainings',
    },
    {
        path: '/blog/all',
        translationKey: 'menu/knowledge',
    },
    {
        path: 'blog',
        externalUrl: 'https://hpba.pl',
        translationKey: 'menu/blog',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
    {
        path: PATHS.AUTH.LOGIN,
        translationKey: 'menu/login',
    },
];
