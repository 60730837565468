import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Alert, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ShoppingCartPaymentMethod from 'view/ShoppingCart/Payment/Method/ShoppingCartPaymentMethod';
import { SHOPPING_CART_PAYMENT_MAKE_SUCCESS } from 'view/ShoppingCart/Confirm/Payment/Make/ShoppingCartPaymentMakeActionType';
import { PATHS } from 'config/paths';
import Loader from 'Layout/Loader';
import { reportError } from '@dietlabs/utils';
import scrollToElement from 'components/Helpers/scrollToElement';

export class ShoppingCartConfirmPaymentOnlineComponent extends React.Component {
    constructor(props) {
        super(props);

        this.scrollAnchorRef = React.createRef();

        this.state = {
            preload: false,
            paymentMethodError: false,
        };
    }

    render() {
        return (
            <section ref={this.scrollAnchorRef}>
                <Container>
                    {this.state.paymentMethodError ? (
                        <Alert color="danger" className="mb-3">
                            {this.props.t('cart/payment/error-occured')}
                        </Alert>
                    ) : (
                        ''
                    )}

                    {this.props.paymentMethods.map(method => {
                        // disable apple pay for non apple devices
                        const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(
                            navigator.platform
                        );
                        if (method.channelId === 'jp' && !isMacLike) {
                            return null;
                        }
                        return (
                            <React.Fragment
                                key={`${method.methodId}_${method.channelId}`}
                            >
                                <div
                                    className="white-box p-md-4 payment-method"
                                    data-test="payment-method-button-pay"
                                    onClick={() =>
                                        this.choosePaymentMethod(method)
                                    }
                                    key={`${method.methodId}_${method.channelId}_new`}
                                >
                                    <Row
                                        data-test="payment-method-row"
                                        className="align-items-center"
                                    >
                                        <Col
                                            xs="4"
                                            md="2"
                                            className="text-center"
                                        >
                                            {method.wideViewportLogoUrl ? (
                                                <React.Fragment>
                                                    <img
                                                        src={
                                                            method.wideViewportLogoUrl
                                                        }
                                                        className="payment-logo img-fluid d-none d-md-block"
                                                    />
                                                    <img
                                                        src={method.logoUrl}
                                                        className="payment-logo img-fluid d-md-none"
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <img
                                                    src={method.logoUrl}
                                                    className="payment-logo img-fluid"
                                                />
                                            )}
                                        </Col>
                                        <Col xs="8" md="7" xl="8">
                                            <h3 className="mb-2">
                                                {method.name}
                                            </h3>
                                            <p className="mb-0">
                                                {method.description}
                                            </p>
                                        </Col>
                                        <Col
                                            xs={{ size: 6, offset: 3 }}
                                            md={{ size: 3, offset: 0 }}
                                            xl="2"
                                        >
                                            <Button
                                                color="primary"
                                                className="w-100 mt-4 mt-md-0"
                                            >
                                                {this.props.t('cart/pay')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </Container>
                {this.state.preload ? <Loader /> : ''}
            </section>
        );
    }

    choosePaymentMethod(paymentMethod) {
        this.setState({
            preload: true,
            paymentMethodError: false,
        });
        this.props
            .make(
                this.props.token,
                paymentMethod.methodId,
                paymentMethod.channelId,
                `${document.location.protocol}//${
                    document.location.host
                }${PATHS.CART.THANK_YOU.replace(':token', this.props.token)}`,
                `${document.location.protocol}//${
                    document.location.host
                }${PATHS.CART.CONFIRM.replace(':token', this.props.token)}`
            )
            .then(response => {
                if (response.type === SHOPPING_CART_PAYMENT_MAKE_SUCCESS) {
                    global.location.href = response.url;
                } else {
                    this.setState({ preload: false, paymentMethodError: true });
                    scrollToElement(this.scrollAnchorRef.current);
                    reportError(new Error(`Payment error`));
                }
            });
    }
}

ShoppingCartConfirmPaymentOnlineComponent.propTypes = {
    t: PropTypes.func.isRequired,
    paymentMethods: PropTypes.arrayOf(
        PropTypes.instanceOf(ShoppingCartPaymentMethod).isRequired
    ).isRequired,
    token: PropTypes.string.isRequired,
    make: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartConfirmPaymentOnlineComponent);
