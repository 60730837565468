import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import PageContainer from 'Layout/Container/PageContainer';

import { Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router';
import { PATHS } from 'config/paths';

import appStore from 'assets/icons/workouts/apple_badge.svg';
import googlePlay from 'assets/icons/workouts/google_badge.svg';
import workouts from 'assets/images/workouts/workouts_img.jpg';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from 'consts';

class Workouts extends React.PureComponent {
    static propTypes = {
        videoWorkoutAccessTo: PropTypes.instanceOf(Date).isRequired,
    };

    render() {
        const { videoWorkoutAccessTo } = this.props;

        if (videoWorkoutAccessTo <= new Date()) {
            window.location = PATHS.EXTERNAL.TRAININGS;
            return null;
        }

        return (
            <Layout page="workouts-balance" data-test="workouts">
                <PageContainer>
                    <Alert color="danger" role="alert" className="mt-4 mb-0">
                        Funkcja Treningi dostępna jest wyłącznie w aplikacji
                        Diet &amp; Training by Ann dla telefonów z systemem iOS
                        oraz Android
                    </Alert>
                    <Row>
                        <Col>
                            <h1 className="text-center my-4 mt-md-5 mb-md-3">
                                Podejmij wyzwanie!
                            </h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md="6">
                            <p className="navy">
                                Ściągnij aplikację na swój telefon – jest
                                dostępna zarówno dla użytkowników iOS jak i
                                Androida – a już na start otrzymasz{' '}
                                <strong>90+ programów treningowych</strong> –
                                każdy z nich ma opcje wyboru intensywności,
                                która związana jest z długością i trudnością
                                kolejnych ćwiczeń. To jednak nie wszystko!
                            </p>
                            <p className="navy">
                                Diet &amp; Training by Ann to wszystkie elementy
                                składowe Twojej drogi do szczęścia, zebrane
                                jednym miejscu i dostępne dla Ciebie zawsze i
                                wszędzie – bo w Twoim telefonie! Zdrowy styl
                                życia jeszcze nigdy nie był tak prosty i
                                wygodny.
                            </p>

                            <Row className="justify-content-center mt-4 d-md-none buttons-wrapper">
                                <Col
                                    xs="12"
                                    className="d-flex justify-content-center"
                                >
                                    <div>
                                        <a
                                            href={APP_STORE_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-inline-block my-3 mt-md-0 mr-3"
                                        >
                                            <img src={appStore} alt="" />
                                        </a>
                                        <a
                                            href={GOOGLE_PLAY_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-inline-block my-3 mt-md-0 ml-3"
                                        >
                                            <img src={googlePlay} alt="" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <img
                                src={workouts}
                                className="img-fluid mt-3 mt-md-0"
                                alt=""
                            />
                            <Row className="justify-content-center mt-4 d-none d-md-flex buttons-wrapper">
                                <Col
                                    xs="6"
                                    md="6"
                                    className="d-flex justify-content-end "
                                >
                                    <a
                                        href={APP_STORE_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="d-inline-block my-3 mt-md-0"
                                    >
                                        <img src={appStore} alt="" />
                                    </a>
                                </Col>
                                <Col xs="6" md="6">
                                    <a
                                        href={GOOGLE_PLAY_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="d-inline-block my-3 mt-md-0"
                                    >
                                        <img src={googlePlay} alt="" />
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PageContainer>
            </Layout>
        );
    }
}
export { Workouts };
export default withRouter(Workouts);
