import gql from 'graphql-tag';

const query = gql`
    query Quiz {
        quiz {
            questions {
                id
                text
                answers {
                    id
                    text
                    isCorrect
                }
            }
        }
    }
`;

export default query;
