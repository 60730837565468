import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Odometer from 'react-odometerjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Layout from 'Layout/Layout';
import { PATH_QUIZ_INDEX } from '../paths';
import { withLocale } from '../../../TranslatorContext';

const QuizIntroPage = ({ t }) => {
    const [odometers, setOdometers] = useState({
        odometer1: 0,
        odometer2: 0,
        odometer3: 0,
    });

    useEffect(() => {
        setOdometers({
            odometer1: 10,
            odometer2: 67,
            odometer3: 68,
        });
    }, []);

    return (
        <Layout page="quiz">
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <header className="text-center">
                            <h1>{t('quiz/intro/title')}</h1>
                        </header>
                        <section className="py-0">
                            <p className="text-center navy">
                                {t('quiz/intro/copy')}
                            </p>

                            <blockquote className="blockquote">
                                <p className="navy">{t('quiz/intro/quote')}</p>
                            </blockquote>

                            <p className="blue text-right mb-0">
                                — {t('quiz/intro/sign')}
                            </p>
                        </section>

                        <hr />

                        <section className="quiz-intro__numbers">
                            <Row>
                                <Col xs="4" className="text-center">
                                    <p className="mb-2">
                                        {t('quiz/intro/numbers1')}
                                    </p>
                                    <h1 className="mb-0">
                                        <Odometer
                                            value={odometers.odometer1}
                                            format="d"
                                        />
                                    </h1>
                                </Col>
                                <Col xs="4" className="text-center">
                                    <p className="mb-2">
                                        {t('quiz/intro/numbers2')}
                                    </p>
                                    <h1 className="mb-0">
                                        <Odometer
                                            value={odometers.odometer2}
                                            format="d"
                                        />{' '}
                                        s
                                    </h1>
                                </Col>
                                <Col xs="4" className="text-center">
                                    <p className="mb-2">
                                        {t('quiz/intro/numbers3')}
                                    </p>
                                    <h1 className="mb-0">
                                        <Odometer
                                            value={odometers.odometer3}
                                            format="d"
                                        />
                                        %
                                    </h1>
                                </Col>
                            </Row>
                        </section>

                        <section className="text-center pt-0">
                            <Button
                                tag={Link}
                                to={PATH_QUIZ_INDEX}
                                color="primary"
                                className="mt-0 px-5"
                            >
                                {t('quiz/start-link')}
                            </Button>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

QuizIntroPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(QuizIntroPage);
