import gql from 'graphql-tag';

const query = gql`
    query Workouts {
        me {
            id
            videoWorkout {
                accessTo
            }
        }
    }
`;

export default query;
