import { availableDiets as dietTypes } from './config';

export default [
    { id: 'bmr' },
    { id: 'goal' },
    {
        id: 'diet-id',
        fork: {
            chooseChild: state =>
                state.dietId === dietTypes.DIET_ID_VEGE ||
                state.dietId === dietTypes.DIET_ID_SMART ||
                state.dietId === dietTypes.DIET_ID_LOW_LG
                    ? 'vege'
                    : 'normal',
            children: {
                vege: [
                    { id: 'triangle' },
                    { id: 'activity-simple' },
                    { id: 'start-date' },
                ],
                normal: [
                    {
                        id: 'test',
                        isVisible: state => !state.diet.hadEver,
                    },
                    { id: 'diet-level' },
                    { id: 'triangle' },
                    { id: 'start-date' },
                    {
                        id: 'activity-type',
                        fork: {
                            chooseChild: state =>
                                state.activityType === 'simple'
                                    ? 'simple'
                                    : 'training',
                            children: {
                                simple: [{ id: 'activity-simple' }],
                                training: [{ id: 'activity-trainings' }],
                            },
                        },
                    },
                ],
            },
        },
    },
];
